import { getHotelIdFromLocalStorage } from '../utils/localStorageUtils';
import api from './api';

// Get all employees for a specific hotel with pagination and filtering
export const getEmployees = async (page: number = 1, count: number = 10, filterData: any = {}) => {
  const hotelId = getHotelIdFromLocalStorage();
  if (!hotelId) throw new Error('No hotel selected');

  try {
    const response = await api.get('/employees', {
      params: {
        hotelId,
        page,
        count,
        ...filterData, // Include any additional filter data
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching employees', error);
    throw error;
  }
};

// Get a specific employee by ID for a specific hotel
export const getEmployeeById = async (id: string) => {
  const hotelId = getHotelIdFromLocalStorage();
  if (!hotelId) throw new Error('No hotel selected');

  try {
    const response = await api.get(`/employees/${id}`, {
      params: { hotelId },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching employee details', error);
    throw error;
  }
};

// Create a new employee for a specific hotel
export const createEmployee = async (data: any) => {
  const hotelId = getHotelIdFromLocalStorage();
  if (!hotelId) throw new Error('No hotel selected');

  try {
    const response = await api.post('/employees', { ...data, hotelId });
    return response.data;
  } catch (error) {
    console.error('Error creating employee', error);
    throw error;
  }
};

// Update an existing employee for a specific hotel
export const updateEmployee = async (id: string, data: any) => {
  const hotelId = getHotelIdFromLocalStorage();
  if (!hotelId) throw new Error('No hotel selected');

  try {
    const response = await api.put(`/employees/${id}`, { ...data, hotelId });
    return response.data;
  } catch (error) {
    console.error('Error updating employee', error);
    throw error;
  }
};

// Delete an employee for a specific hotel
export const deleteEmployee = async (id: string) => {
  const hotelId = getHotelIdFromLocalStorage();
  if (!hotelId) throw new Error('No hotel selected');

  try {
    const response = await api.delete(`/employees/${id}`, {
      params: { hotelId },
    });
    return response.data;
  } catch (error) {
    console.error('Error deleting employee', error);
    throw error;
  }
};

export const searchEmployees = async (query = '', filterData = {}) => {
    const hotelId = getHotelIdFromLocalStorage();
    if (!hotelId) throw new Error('No hotel selected');
  
    try {
      const response = await api.get('/employees/search', {
        params: {
          q: query,
          hotelId,
          ...filterData, // Include any additional filter data
        },
      });
      return response.data;
    } catch (error) {
      console.error('Error searching employees', error);
      throw error;
    }
  };
  
