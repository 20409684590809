import axios from 'axios';
import api from './api';

const BASE_URL = process.env.REACT_APP_BASE_URL || 'http://localhost:3000';

// Login service
export const loginService = async (mobileNumber: string, password: string) => {
  try {
    const response = await axios.post(`${BASE_URL}/api/v1/admin/login`, { mobileNumber, password });
    return response.data;
  } catch (error) {
    throw new Error('Login failed');
  }
};

// Signup service
export const signupService = async (mobileNumber: string, name: string, password: string) => {
  try {
    const response = await axios.post(`${BASE_URL}/api/v1/admin/signup`, {
      mobileNumber,
      name,
      password,
    });
    return response.data;
  } catch (error) {
    throw new Error('Signup failed');
  }
};


export const changePasswordService = async (oldPassword: string, newPassword: string) => {
  try {
    const response = await api.post(
      `${BASE_URL}/api/v1/admin/changePassword`,
      { oldPassword, newPassword },
    );
    return response.data;
  } catch (error) {
    throw new Error('Change password failed');
  }
};