// components/TableManagement.tsx
import React, { useState, useEffect } from 'react';
import {
  Container,
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Box,
  MenuItem,
} from '@mui/material';
import { Add, Edit, Delete } from '@mui/icons-material';
import { getTables, createTable, updateTable, deleteTable } from '../../services/tableService';

const TableManagement: React.FC = () => {
  const [tables, setTables] = useState<any[]>([]);
  const [showModal, setShowModal] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [currentTable, setCurrentTable] = useState<any>(null);
  const [tableNumber, setTableNumber] = useState('');
  const [capacity, setCapacity] = useState<number>(0);
  const [status, setStatus] = useState<string>('AVAILABLE'); // Default status

  // Fetch tables on component load
  useEffect(() => {
    const fetchTables = async () => {
      try {
        const response = await getTables();
        setTables(response);
      } catch (error) {
        console.error('Error fetching tables', error);
      }
    };
    fetchTables();
  }, []);

  const handleOpenModal = (table?: any) => {
    if (table) {
      setEditMode(true);
      setCurrentTable(table);
      setTableNumber(table.tableNumber);
      setCapacity(table.capacity);
      setStatus(table.status);
    } else {
      setEditMode(false);
      setCurrentTable(null);
      setTableNumber('');
      setCapacity(0);
      setStatus('AVAILABLE');
    }
    setShowModal(true);
  };

  const handleCloseModal = () => setShowModal(false);

  const handleSave = async (event: React.FormEvent) => {
    event.preventDefault();

    const tableData = {
      tableNumber,
      capacity,
      status,
    };

    try {
      if (editMode && currentTable) {
        await updateTable(currentTable._id, tableData);
      } else {
        await createTable(tableData);
      }
      setShowModal(false);
      const response = await getTables();
      setTables(response);
    } catch (error) {
      console.error('Error saving table', error);
    }
  };

  const handleDelete = async (id: string) => {
    if (window.confirm('Are you sure you want to delete this table?')) {
      try {
        await deleteTable(id);
        const response = await getTables();
        setTables(response);
      } catch (error) {
        console.error('Error deleting table', error);
      }
    }
  };

  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        Manage Tables
      </Typography>
      <Button
        variant="contained"
        color="primary"
        startIcon={<Add />}
        onClick={() => handleOpenModal()}
      >
        Add Table
      </Button>

      <TableContainer component={Paper} sx={{ mt: 3 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Table Number</TableCell>
              <TableCell>Capacity</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tables.map((table) => (
              <TableRow key={table._id}>
                <TableCell>{table.tableNumber}</TableCell>
                <TableCell>{table.capacity}</TableCell>
                <TableCell>{table.status}</TableCell>
                <TableCell>
                  <IconButton color="primary" onClick={() => handleOpenModal(table)}>
                    <Edit />
                  </IconButton>
                  <IconButton color="secondary" onClick={() => handleDelete(table._id)}>
                    <Delete />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Modal for Adding/Editing Table */}
      <Dialog open={showModal} onClose={handleCloseModal} fullWidth maxWidth="sm">
        <DialogTitle>{editMode ? 'Edit Table' : 'Add Table'}</DialogTitle>
        <DialogContent>
          <Box component="form" onSubmit={handleSave} noValidate>
            <TextField
              margin="normal"
              fullWidth
              label="Table Number"
              value={tableNumber}
              onChange={(e) => setTableNumber(e.target.value)}
              required
            />
            <TextField
              margin="normal"
              fullWidth
              label="Capacity"
              type="number"
              value={capacity}
              onChange={(e) => setCapacity(parseInt(e.target.value))}
              required
            />
            <TextField
              margin="normal"
              fullWidth
              label="Status"
              select
              value={status}
              onChange={(e) => setStatus(e.target.value)}
              required
            >
              <MenuItem value="AVAILABLE">Available</MenuItem>
              <MenuItem value="RESERVED">Reserved</MenuItem>
              <MenuItem value="OCCUPIED">Occupied</MenuItem>
            </TextField>
            <DialogActions>
              <Button onClick={handleCloseModal} color="secondary">
                Cancel
              </Button>
              <Button type="submit" color="primary">
                {editMode ? 'Save Changes' : 'Add Table'}
              </Button>
            </DialogActions>
          </Box>
        </DialogContent>
      </Dialog>
    </Container>
  );
};

export default TableManagement;
