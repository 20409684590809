import { getHotelIdFromLocalStorage } from '../utils/localStorageUtils';
import api from './api';


// Get availability for all rooms in a hotel between specific dates
export const getAllRoomAvailabilityBetweenDates = async (startDate: string, endDate: string) => {
    const hotelId = getHotelIdFromLocalStorage();
    if (!hotelId) throw new Error('No hotel selected');

    try {
        const response = await api.post('/room-availability/all-room-availability-between-dates', {
            hotelId,
            startDate,
            endDate,
        });
        return response.data; // Return the availability data for all rooms
    } catch (error) {
        console.error('Error fetching all room availability between dates', error);
        throw error;
    }
};


// Check room availability for a specific hotel and date range
export const getRoomStatusForDateRange = async (roomIds: string[], startDate: string, endDate: string) => {
    const hotelId = getHotelIdFromLocalStorage();
    if (!hotelId) throw new Error('No hotel selected');

    try {
        const response = await api.post('/room-availability/check-availability', {
            roomIds, // Array of room IDs to check
            hotelId,
            startDate,
            endDate,
        });
        return response.data; // Return the response data (available or unavailable rooms)
    } catch (error) {
        console.error('Error fetching room status for date range', error);
        throw error;
    }
};

// Get room availability for a specific room within a date range
export const getRoomAvailabilityForRange = async (roomId: string, startDate: string, endDate: string) => {
    const hotelId = getHotelIdFromLocalStorage();
    if (!hotelId) throw new Error('No hotel selected');

    try {
        const response = await api.post('/room-availability/room-availability', {
            roomId,
            hotelId,
            startDate,
            endDate,
        });
        return response.data; // Return the availability data for the specific room
    } catch (error) {
        console.error('Error fetching room availability for range', error);
        throw error;
    }
};

// Update room availability (e.g., mark room as unavailable or available)
export const updateRoomAvailability = async (roomId: string, isAvailable: boolean, date: string) => {
    const hotelId = getHotelIdFromLocalStorage();
    if (!hotelId) throw new Error('No hotel selected');

    try {
        const response = await api.put('/room-availability/update-availability', {
            roomId,
            hotelId,
            date,
            isAvailable,
        });
        return response.data; // Return success message or updated record
    } catch (error) {
        console.error('Error updating room availability', error);
        throw error;
    }
};

// Get availability for all rooms in a hotel for a specific date
export const getAllRoomAvailabilityForDate = async (date: string) => {
    const hotelId = getHotelIdFromLocalStorage();
    if (!hotelId) throw new Error('No hotel selected');

    try {
        const response = await api.post('/room-availability/all-room-availability', {
            hotelId,
            date,
        });
        return response.data; // Return availability data for all rooms
    } catch (error) {
        console.error('Error fetching all room availability for date', error);
        throw error;
    }
};
