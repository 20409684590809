import React, { useState, useEffect } from 'react';
import { Box, TextField, Button, Typography, Grid, Select, MenuItem } from '@mui/material';
import { createRoomBilling, generateRoomBillingPdfFromHtml } from '../../services/roomBillingService';
import { getCustomerByMobileNumber } from '../../services/customerService';
import ConfirmationDialog from '../Shared/ConfirmationDialog';
import SnackbarComponent from '../Shared/SnackbarComponent'; // Add snackbar import
import { getRooms } from '../../services/roomService'; // Assuming this service fetches room data
import { getTaxesForProductCategory } from '../../services/taxService';

const RoomBillingForm: React.FC = () => {
  const [roomId, setRoomId] = useState('');
  const [selectedRoom, setSelectedRoom] = useState<string>(''); // Store selected room ID
  const [guestName, setGuestName] = useState('');
  const [mobileNumber, setMobileNumber] = useState('');
  const [mobileNumberError, setMobileNumberError] = useState('');
  const [rooms, setRooms] = useState<any[]>([]);
  const [taxes, setTaxes] = useState<any[]>([]);
  const [numAdults, setNumAdults] = useState('');
  const [numChildren, setNumChildren] = useState('');
  const [checkinDate, setCheckinDate] = useState('');
  const [checkinTime, setCheckinTime] = useState('12:30');
  const [checkoutDate, setCheckoutDate] = useState('');
  const [checkoutTime, setCheckoutTime] = useState('11:00');
  const [numDays, setNumDays] = useState('');
  const [customerAddress, setCustomerAddress] = useState('');
  const [nationality, setNationality] = useState('INDIAN');
  const [pricePerDay, setPricePerDay] = useState('');
  const [serviceChargePercent, setServiceChargePercent] = useState('0');
  const [discountPercent, setDiscountPercent] = useState('0');
  const [amount, setAmount] = useState(0);
  const [discountAmount, setDiscountAmount] = useState(0);
  const [serviceChargeAmount, setServiceChargeAmount] = useState(0);
  const [amountAfterDiscount, setAmountAfterDiscount] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [pdfData, setPdfData] = useState<Blob | null>(null);
  const [billId, setBillId] = useState<string | null>(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [confirmAction, setConfirmAction] = useState<(() => void) | null>(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false); // Snackbar state
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error'>('success');

  useEffect(() => {
    if (mobileNumber.length === 10) {
      fetchCustomerData(mobileNumber);
    }
  }, [mobileNumber]);

  useEffect(() => {
    fetchRooms();
    fetchTaxes();
  }, []);

  const fetchRooms = async () => {
    try {
      const roomsData = await getRooms();
      setRooms(roomsData);
    } catch (error) {
      console.error('Error fetching rooms:', error);
    }
  };

  const fetchTaxes = async () => {
    try {
      const taxes = await getTaxesForProductCategory("ACCOMMODATION");
      console.log('Fetched taxes:', taxes);
      setTaxes(taxes);
    } catch (error) {
      console.error('Error fetching taxes:', error);
    }
  };

  const validateMobileNumber = (number: string) => {
    if (/^\d{0,10}$/.test(number)) {
      setMobileNumber(number);
      if (number.length === 10) {
        setMobileNumberError("");
      } else {
        setMobileNumberError("Mobile number must be 10 digits");
      }
    } else {
      setMobileNumberError("Mobile number must contain only digits");
    }
  };

  const fetchCustomerData = async (number: string) => {
    try {
      const response = await getCustomerByMobileNumber(number);
      if (response) {
        setGuestName(response.customerName || '');
        setCustomerAddress(response.address || '');
      }
    } catch (error) {
      console.error('Error fetching customer data:', error);
    }
  };

  useEffect(() => {
    // Step 1: Calculate the base amount (price per day * number of days)
    const baseAmount = (parseFloat(pricePerDay) || 0) * (parseInt(numDays, 10) || 0);

    // Step 2: Calculate the discount amount
    const discountAmt = (baseAmount * (parseFloat(discountPercent) || 0)) / 100;
    const amountAfterDiscount = baseAmount - discountAmt;

    // Step 3: Calculate the service charge based on the amount after discount
    const serviceChargeAmt = (amountAfterDiscount * (parseFloat(serviceChargePercent) || 0)) / 100;

    // Step 4: Calculate taxes on amountAfterDiscount (excluding service charge)
    let totalTaxAmount = 0;
    const appliedTaxes = [];

    taxes.forEach((tax) => {
      const rate = parseFloat(tax.rate) || 0;

      if (rate > 0) {
        // Apply tax on amountAfterDiscount
        const taxAmount = (amountAfterDiscount * rate) / 100;
        totalTaxAmount += taxAmount;

        // Collect each tax dynamically for the API submission
        appliedTaxes.push({
          name: tax.name,
          rate: rate,
          amount: taxAmount
        });
      }
    });

    // Step 5: Apply service tax (e.g., 18%) on the service charge only
    const serviceTaxRate = 18; // Assuming a fixed 18% service tax
    const serviceTaxAmount = (serviceChargeAmt * serviceTaxRate) / 100;

    // Add service tax to applied taxes for the API
    appliedTaxes.push({
      name: 'Service Tax',
      rate: serviceTaxRate,
      amount: serviceTaxAmount
    });

    // Step 6: Calculate the final total
    const finalTotal = amountAfterDiscount + serviceChargeAmt + totalTaxAmount + serviceTaxAmount;

    // Log values for debugging
    console.log('Base Amount:', baseAmount);
    console.log('Discount Amount:', discountAmt);
    console.log('Amount After Discount:', amountAfterDiscount);
    console.log('Service Charge Amount:', serviceChargeAmt);
    console.log('Total Tax Amount (excluding service charge):', totalTaxAmount);
    console.log('Service Tax Amount (on service charge):', serviceTaxAmount);
    console.log('Final Total with taxes:', finalTotal);

    // Step 7: Set calculated values to the state
    setAmount(baseAmount);
    setDiscountAmount(discountAmt);
    setAmountAfterDiscount(amountAfterDiscount);
    setServiceChargeAmount(serviceChargeAmt);
    setTotalAmount(finalTotal);
  }, [pricePerDay, numDays, discountPercent, serviceChargePercent, taxes]);

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    openConfirmationDialog(saveBill);
  };

  const saveBill = async () => {
    const apiPayload = {
      roomId,
      guestName,
      mobileNumber,
      numAdults: parseInt(numAdults, 10),
      numChildren: parseInt(numChildren, 10),
      checkinDate,
      checkinTime,
      checkoutDate,
      checkoutTime,
      numDays: parseInt(numDays, 10),
      customerAddress,
      nationality,
      pricePerDay: parseFloat(pricePerDay),
      serviceChargePercent: parseFloat(serviceChargePercent),
      discountPercent: parseFloat(discountPercent),
      amount,
      discountAmount,
      serviceChargeAmount,
      amountAfterDiscount,
      totalAmount,
      taxes: taxes.map(tax => ({
        name: tax.name,
        rate: parseFloat(tax.rate),
        amount: (amountAfterDiscount * (parseFloat(tax.rate) || 0)) / 100
      })).concat([{
        name: 'Service Tax',
        rate: 18,
        amount: (serviceChargeAmount * 18) / 100
      }]) // Include service tax
    };

    try {
      const response = await createRoomBilling(apiPayload);
      setBillId(response._id);
      setSnackbarMessage('Room billing created successfully');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
    } catch (error) {
      setSnackbarMessage('Failed to create room billing');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const handleRoomChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const roomId = event.target.value;
    setRoomId(roomId);
    setSelectedRoom(roomId);

    // Find the selected room and update the pricePerDay
    const selectedRoomData = rooms.find(room => room._id === roomId);
    if (selectedRoomData) {
      setPricePerDay(selectedRoomData.price);
    }
  };

  const openConfirmationDialog = (action: () => void) => {
    setConfirmAction(() => action);
    setIsDialogOpen(true);
  };

  const confirmDialogAction = () => {
    if (confirmAction) {
      confirmAction();
    }
    setIsDialogOpen(false);
  };

  const handleGeneratePdf = async () => {
    if (billId) {
      try {
        const response = await generateRoomBillingPdfFromHtml(billId);
        setPdfData(response);
        const url = window.URL.createObjectURL(new Blob([response.data]));
      } catch (error) {
        console.error("Error generating PDF", error);
      }
    } else {
      setSnackbarMessage("Please create the bill first.");
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const downloadPdf = () => {
    if (pdfData) {
      const url = window.URL.createObjectURL(pdfData);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "room_billing.pdf");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const printPdf = () => {
    if (pdfData) {
      const url = window.URL.createObjectURL(pdfData);
      window.open(url, "_blank");
      setTimeout(() => {
        window.URL.revokeObjectURL(url);
      }, 1000);
    }
  };

  return (
    <Box component="form" onSubmit={handleSubmit}>
      <Typography variant="h6" gutterBottom>
        Create Room Billing
      </Typography>
      <Grid container spacing={2}>

        <Grid item xs={12} sm={6}>
          <TextField
            select
            label="Select Room"
            value={selectedRoom}
            onChange={handleRoomChange}
            fullWidth
          >
            {rooms.map(room => (
              <MenuItem key={room._id} value={room._id}>
                {room.name}
              </MenuItem>
            ))}
          </TextField>
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            label="Mobile Number"
            value={mobileNumber}
            onChange={(e) => {
              validateMobileNumber(e.target.value);
            }}
            fullWidth
            margin="normal"
            required
            error={!!mobileNumberError}
            helperText={mobileNumberError}
            inputProps={{ maxLength: 10 }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Guest Name"
            value={guestName}
            onChange={(e) => setGuestName(e.target.value)}
            fullWidth
            margin="normal"
            required
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Customer Address"
            value={customerAddress}
            onChange={(e) => setCustomerAddress(e.target.value)}
            fullWidth
            margin="normal"
            required
          />
        </Grid>
        <Grid item xs={6} sm={3}>
          <TextField
            label="Number of Adults"
            value={numAdults}
            onChange={(e) => setNumAdults(e.target.value)}
            fullWidth
            margin="normal"
            type="number"
            required
          />
        </Grid>
        <Grid item xs={6} sm={3}>
          <TextField
            label="Number of Children"
            value={numChildren}
            onChange={(e) => setNumChildren(e.target.value)}
            fullWidth
            margin="normal"
            type="number"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Check-in Date"
            type="date"
            value={checkinDate}
            onChange={(e) => setCheckinDate(e.target.value)}
            fullWidth
            margin="normal"
            InputLabelProps={{ shrink: true }}
            required
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Check-in Time"
            type="time"
            value={checkinTime}
            onChange={(e) => setCheckinTime(e.target.value)}
            fullWidth
            margin="normal"
            InputLabelProps={{ shrink: true }}
            required
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Checkout Date"
            type="date"
            value={checkoutDate}
            onChange={(e) => setCheckoutDate(e.target.value)}
            fullWidth
            margin="normal"
            InputLabelProps={{ shrink: true }}
            required
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Checkout Time"
            type="time"
            value={checkoutTime}
            onChange={(e) => setCheckoutTime(e.target.value)}
            fullWidth
            margin="normal"
            InputLabelProps={{ shrink: true }}
            required
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Price Per Day"
            value={pricePerDay}
            onChange={(e) => setPricePerDay(e.target.value)}
            fullWidth
            margin="normal"
            type="number"
            required
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Number of Days"
            value={numDays}
            onChange={(e) => setNumDays(e.target.value)}
            fullWidth
            margin="normal"
            type="number"
            required
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Service Charge (%)"
            value={serviceChargePercent}
            onChange={(e) => setServiceChargePercent(e.target.value)}
            fullWidth
            margin="normal"
            type="number"
            required
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Discount (%)"
            value={discountPercent}
            onChange={(e) => setDiscountPercent(e.target.value)}
            fullWidth
            margin="normal"
            type="number"
            required
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6">Taxes</Typography>
          {taxes.map((tax) => {
            const taxAmount = (amountAfterDiscount * (tax.rate || 0)) / 100;
            return (
              <Typography key={tax._id} variant="body1">
                {tax.name} ({tax.rate}%): {taxAmount.toFixed(2)}
              </Typography>
            );
          })}
          <Typography variant="body1">
            Service Tax (18%): {(serviceChargeAmount * 18 / 100).toFixed(2)}
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h6">Billing Details</Typography>
          <Typography variant="body1">Amount: {amount.toFixed(2)}</Typography>
          <Typography variant="body1">Discount Amount: {discountAmount.toFixed(2)}</Typography>
          <Typography variant="body1">Amount After Discount: {amountAfterDiscount.toFixed(2)}</Typography>
          <Typography variant="body1">Service Charge Amount: {serviceChargeAmount.toFixed(2)}</Typography>
          <Typography variant="body1">Total Amount: {totalAmount.toFixed(2)}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Button type="submit" variant="contained" color="primary">
            Submit
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleGeneratePdf}
            style={{ marginLeft: '10px' }}
          >
            Generate PDF
          </Button>
          {pdfData && (
            <>
              <Button
                variant="contained"
                color="primary"
                onClick={downloadPdf}
                style={{ marginLeft: '10px' }}
              >
                Download PDF
              </Button>
              <Button
                variant="contained"
                color="secondary"
                onClick={printPdf}
                style={{ marginLeft: '10px' }}
              >
                Print PDF
              </Button>
            </>
          )}
        </Grid>
      </Grid>
      <ConfirmationDialog
        open={isDialogOpen}
        onCancel={() => setIsDialogOpen(false)}
        onConfirm={confirmDialogAction}
        title="Confirm Bill Creation"
        message="Are you sure you want to create this room billing?"
      />

      {/* Snackbar for feedback */}
      <SnackbarComponent
        open={snackbarOpen}
        message={snackbarMessage}
        severity={snackbarSeverity}
        onClose={() => setSnackbarOpen(false)}
      />
    </Box>
  );
};

export default RoomBillingForm;
