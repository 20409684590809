import React from 'react';
import { Pagination as MuiPagination, Stack } from '@mui/material';

interface PaginationProps {
  count: number;
  page: number;
  onPageChange: (event: React.ChangeEvent<unknown>, page: number) => void;
}

const Pagination: React.FC<PaginationProps> = ({ count, page, onPageChange }) => {
  return (
    <Stack spacing={2} sx={{ mt: 2, justifyContent: 'center' }}>
      <MuiPagination
        count={count}
        page={page}
        onChange={onPageChange}
        color="primary"
      />
    </Stack>
  );
};

export default Pagination;
