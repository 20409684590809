// utils/reconcileBills.ts

import { getAllBills } from '../services/billService';
import { getAllPayments } from '../services/paymentService';
import { getAllRoomBillings } from '../services/roomBillingService';

// types.d.ts
export interface Bill {
    _id: string;
    grandTotal: number;
    // Add other properties of the bill if necessary
}

export interface RoomBill {
    _id: string;
    totalAmount: number;
    // Add other properties of the room bill if necessary
}

export interface Payment {
    billId: string;
    amount: number;
    // Add other properties of the payment if necessary
}

export const reconcileBills = async () => {
    try {
        const bills: Bill[] = await getAllBills();
        const roomBills: RoomBill[] = await getAllRoomBillings();
        const payments: Payment[] = await getAllPayments();

        // Create maps to aggregate payments by billId for regular bills and room bills
        const paymentMap: { [key: string]: { bill: number; room: number } } = payments.reduce((acc, payment) => {
            if (!acc[payment.billId]) {
                acc[payment.billId] = { bill: 0, room: 0 };
            }

            // Determine if the payment belongs to a regular bill or a room bill
            const isRoomBill = roomBills.some(roomBill => roomBill._id === payment.billId);
            if (isRoomBill) {
                acc[payment.billId].room += payment.amount;
            } else {
                acc[payment.billId].bill += payment.amount;
            }
            
            return acc;
        }, {} as { [key: string]: { bill: number; room: number } });

        // Reconcile regular bills
        const reconciledBillsData = bills.map(bill => {
            const paymentAmount = (paymentMap[bill._id]?.bill || 0);
            
            return {
                ...bill,
                paymentAmount,
                isReconciled: paymentAmount >= bill.grandTotal
            };
        });

        // Reconcile room bills
        const reconciledRoomBillsData = roomBills.map(roomBill => {
            const paymentAmount = (paymentMap[roomBill._id]?.room || 0);
            
            return {
                ...roomBill,
                paymentAmount,
                isReconciled: paymentAmount >= roomBill.totalAmount
            };
        });

        const discrepanciesBills = reconciledBillsData.filter(data => !data.isReconciled);
        const discrepanciesRoomBills = reconciledRoomBillsData.filter(data => !data.isReconciled);

        // Calculate total amounts
        const totalBills = bills.reduce((sum, bill) => sum + bill.grandTotal, 0);
        const totalRoomBills = roomBills.reduce((sum, roomBill) => sum + roomBill.totalAmount, 0);
        const totalBillPayments = Object.values(paymentMap).reduce((sum, payment) => sum + payment.bill, 0);
        const totalRoomPayments = Object.values(paymentMap).reduce((sum, payment) => sum + payment.room, 0);

        return {
            totalBills,
            totalRoomBills,
            totalBillPayments,
            totalRoomPayments,
            discrepanciesBills,
            discrepanciesRoomBills,
        };
    } catch (error) {
        console.error('Error during reconciliation', error);
        throw error;
    }
};
