import React, { useState } from 'react';
import { fetchGstReport } from '../../services/gstReportService';
import { Container, Typography, TextField, Button, Box, CircularProgress, Paper } from '@mui/material';

const GstReport: React.FC = () => {
  const [startDate, setStartDate] = useState<string>('');
  const [endDate, setEndDate] = useState<string>('');
  const [reportData, setReportData] = useState<any>(null);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    try {
      const report = await fetchGstReport(startDate, endDate);
      setReportData(report);
    } catch (err) {
      console.error('Error fetching GST report:', err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container maxWidth="md" sx={{ mt: 4 }}>
      <Typography variant="h4" component="h1" gutterBottom sx={{ color: '#2C3E50', fontWeight: 'bold' }}>
        GST Report
      </Typography>

      <Paper elevation={3} sx={{ p: 4, borderRadius: 2 }}>
        <form onSubmit={handleSubmit}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 3 }}>
            <TextField
              label="Start Date"
              type="date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
              fullWidth
              sx={{ mr: 2 }}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <TextField
              label="End Date"
              type="date"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Box>

          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            sx={{ backgroundColor: '#3498db', height: '45px' }}
            disabled={loading}
          >
            {loading ? <CircularProgress size={24} color="inherit" /> : 'Generate Report'}
          </Button>
        </form>

        {reportData && (
          <Box mt={4} p={3} sx={{ backgroundColor: '#f2f2f2', borderRadius: 2 }}>
            <Typography variant="h5" sx={{ color: '#2C3E50', mb: 2 }}>Report Summary</Typography>
            <Typography variant="body1" sx={{ mb: 1 }}>
              <strong>Total Output GST: </strong>₹ {reportData.totalOutputGst}
            </Typography>
            <Typography variant="body1" sx={{ mb: 1 }}>
              <strong>Total Input GST: </strong>₹ {reportData.totalInputGst}
            </Typography>
            <Typography variant="body1" sx={{ mb: 1 }}>
              <strong>Net GST Payable: </strong>₹ {reportData.netGstPayable}
            </Typography>
          </Box>
        )}
      </Paper>
    </Container>
  );
};

export default GstReport;
