import { getHotelIdFromLocalStorage } from '../utils/localStorageUtils';
import api from './api';

// Get all admins for a specific hotel with pagination and filtering
export const getAdmins = async (page: number = 1, count: number = 10, filterData: any = {}) => {
  const hotelId = getHotelIdFromLocalStorage();
  if (!hotelId) throw new Error('No hotel selected');

  try {
    const response = await api.get('/admins', {
      params: {
        hotelId,
        page,
        count,
        ...filterData, // Include any additional filter data
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching admins', error);
    throw error; // Re-throw error to be handled by the calling code
  }
};

// Get a specific admin by ID for a specific hotel
export const getAdminById = async (id: string) => {
  const hotelId = getHotelIdFromLocalStorage();
  if (!hotelId) throw new Error('No hotel selected');

  try {
    const response = await api.get(`/admins/${id}`, {
      params: { hotelId },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching admin details', error);
    throw error;
  }
};

// Create a new admin for a specific hotel
export const createAdmin = async (data: any) => {
  const hotelId = getHotelIdFromLocalStorage();
  if (!hotelId) throw new Error('No hotel selected');

  try {
    const response = await api.post('/admin/addAdminToHotel', { ...data, hotelId });
    return response.data;
  } catch (error) {
    console.error('Error creating admin', error);
    throw error;
  }
};

// Update an existing admin for a specific hotel
export const updateAdmin = async (id: string, data: any) => {
  const hotelId = getHotelIdFromLocalStorage();
  if (!hotelId) throw new Error('No hotel selected');

  try {
    const response = await api.put(`/admins/${id}`, { ...data, hotelId });
    return response.data;
  } catch (error) {
    console.error('Error updating admin', error);
    throw error;
  }
};

// Delete an admin for a specific hotel
export const deleteAdmin = async (id: string) => {
  const hotelId = getHotelIdFromLocalStorage();
  if (!hotelId) throw new Error('No hotel selected');

  try {
    const response = await api.delete(`/admins/${id}`, {
      params: { hotelId },
    });
    return response.data;
  } catch (error) {
    console.error('Error deleting admin', error);
    throw error;
  }
};

// Search for admins by name or other criteria for a specific hotel
export const searchAdmins = async (query: string, filterData: any = {}) => {
  const hotelId = getHotelIdFromLocalStorage();
  if (!hotelId) throw new Error('No hotel selected');

  try {
    const response = await api.get('/admins/search', {
      params: {
        q: query,
        hotelId,
        ...filterData, // Include any additional filter data
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error searching admins', error);
    throw error;
  }
};
