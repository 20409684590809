import React, { useEffect, useState } from 'react';
import {
  Container,
  Typography,
  Button,
  Paper,
  CircularProgress,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Snackbar,
  Alert,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
} from '@mui/material';
import { createExpense, getExpenses } from '../../services/expensesService'; // Assume these services exist
import ConfirmationDialog from '../Shared/ConfirmationDialog';

const Expenses: React.FC = () => {
  const [description, setDescription] = useState<string>('');
  const [taxableAmount, setTaxableAmount] = useState<number>(0); // New taxable amount state
  const [gstRate, setGstRate] = useState<number>(18); // Default GST rate
  const [gstAmount, setGstAmount] = useState<number>(0);
  const [amount, setAmount] = useState<number>(0); // Total amount including GST
  const [date, setDate] = useState<string>(new Date().toISOString().slice(0, 10)); // Default to today
  const [expenseType, setExpenseType] = useState<string>(''); // New state for expense type
  const [expenseTypes, setExpenseTypes] = useState<string[]>([]); // List of expense types for the dropdown
  const [loading, setLoading] = useState<boolean>(true);
  const [open, setOpen] = useState<boolean>(false);
  const [expenses, setExpenses] = useState<any[]>([]);
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error'>('success');
  const [confirmAction, setConfirmAction] = useState<(() => void) | null>(null);

  useEffect(() => {
    const fetchExpenses = async () => {
      try {
        const data = await getExpenses(); // Fetch existing expenses
        setExpenses(data);
      } catch (err) {
        console.error('Error fetching expenses:', err);
      } finally {
        setLoading(false);
      }
    };

    const fetchExpenseTypes = async () => {
      // Simulate fetching expense types, or replace this with an API call
      const types = ['Maintenance', 'Kitchen Supplies', 'Utilities', 'Miscellaneous'];
      setExpenseTypes(types);
    };

    fetchExpenses();
    fetchExpenseTypes();
  }, []);

  const handleTaxableAmountChange = (value: number) => {
    setTaxableAmount(value);
    const gst = (value * gstRate) / 100; // Calculate GST based on taxable amount
    setGstAmount(gst);
    setAmount(value + gst); // Total amount is taxable amount + GST
  };

  const handleGstRateChange = (value: number) => {
    setGstRate(value);
    const gst = (taxableAmount * value) / 100; // Recalculate GST when GST rate changes
    setGstAmount(gst);
    setAmount(taxableAmount + gst); // Update total amount
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const data = {
        expenseType,
        description,
        taxableAmount,
        gstRate,
        gstAmount,
        amount,
        date,
      };
      await createExpense(data);
      setSnackbarSeverity('success');
      setSnackbarMessage('Expense Recorded Successfully');
      setDescription('');
      setTaxableAmount(0);
      setGstAmount(0);
      setAmount(0);
      setExpenseType('');
      setOpen(false);
      getExpenses(); // Refresh the list after submission
    } catch (err) {
      console.error('Error creating expense:', err);
      setSnackbarSeverity('error');
      setSnackbarMessage('Failed to record expense');
    } finally {
      setLoading(false);
      setSnackbarOpen(true);
    }
  };

  const handleOpenDialog = (action: () => void) => {
    setConfirmAction(() => action);
    setIsDialogOpen(true);
  };

  const confirmDialogAction = () => {
    if (confirmAction) {
      confirmAction();
    }
    setIsDialogOpen(false);
  };

  return (
    <Container maxWidth="lg" sx={{ mt: 4 }}>
      <Typography variant="h4" component="h1" gutterBottom sx={{ color: '#2C3E50', fontWeight: 'bold' }}>
        Expenses
      </Typography>

      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '300px' }}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          <Button variant="contained" color="primary" onClick={() => setOpen(true)} sx={{ mb: 2 }}>
            Add Expense
          </Button>

          <TableContainer component={Paper} sx={{ borderRadius: 2 }}>
            <Table>
              <TableHead>
                <TableRow sx={{ backgroundColor: '#34495e' }}>
                  <TableCell sx={{ color: '#fff', fontWeight: 'bold' }}>Expense Type</TableCell>
                  <TableCell sx={{ color: '#fff', fontWeight: 'bold' }}>Description</TableCell>
                  <TableCell sx={{ color: '#fff', fontWeight: 'bold' }}>Taxable Amount</TableCell>
                  <TableCell sx={{ color: '#fff', fontWeight: 'bold' }}>GST Rate</TableCell>
                  <TableCell sx={{ color: '#fff', fontWeight: 'bold' }}>GST Amount</TableCell>
                  <TableCell sx={{ color: '#fff', fontWeight: 'bold' }}>Total Amount</TableCell>
                  <TableCell sx={{ color: '#fff', fontWeight: 'bold' }}>Date</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {expenses.map((expense) => (
                  <TableRow key={expense._id}>
                    <TableCell>{expense.expenseType}</TableCell>
                    <TableCell>{expense.description}</TableCell>
                    <TableCell>₹ {expense.taxableAmount.toFixed(2)}</TableCell>
                    <TableCell>{expense.gstRate}%</TableCell>
                    <TableCell>₹ {expense.gstAmount.toFixed(2)}</TableCell>
                    <TableCell>₹ {expense.amount.toFixed(2)}</TableCell>
                    <TableCell>{new Date(expense.date).toLocaleDateString()}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}

      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>Record Expense</DialogTitle>
        <DialogContent>
          <FormControl fullWidth margin="dense" required>
            <InputLabel>Expense Type</InputLabel>
            <Select
              value={expenseType}
              onChange={(e) => setExpenseType(e.target.value as string)}
              label="Expense Type"
            >
              {expenseTypes.map((type) => (
                <MenuItem key={type} value={type}>
                  {type}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <TextField
            autoFocus
            margin="dense"
            label="Description"
            fullWidth
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            required
          />
          <TextField
            margin="dense"
            label="Taxable Amount"
            type="number"
            fullWidth
            value={taxableAmount}
            onChange={(e) => handleTaxableAmountChange(Number(e.target.value))}
            required
          />
          <TextField
            margin="dense"
            label="GST Rate (%)"
            type="number"
            fullWidth
            value={gstRate}
            onChange={(e) => handleGstRateChange(Number(e.target.value))}
            required
          />
          <TextField
            margin="dense"
            label="GST Amount"
            type="number"
            fullWidth
            value={gstAmount}
            disabled
          />
          <TextField
            margin="dense"
            label="Total Amount"
            type="number"
            fullWidth
            value={amount}
            disabled
          />
          <TextField
            margin="dense"
            label="Date"
            type="date"
            fullWidth
            value={date}
            onChange={(e) => setDate(e.target.value)}
            required
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} color="primary">
            Cancel
          </Button>
          <Button
            onClick={() => handleOpenDialog(handleSubmit)}
            color="primary"
            disabled={loading}
          >
            {loading ? <CircularProgress size={24} color="inherit" /> : 'Record Expense'}
          </Button>
        </DialogActions>
      </Dialog>

      <ConfirmationDialog
        open={isDialogOpen}
        onCancel={() => setIsDialogOpen(false)}
        onConfirm={confirmDialogAction}
        title="Confirm Expense Submission"
        message="Are you sure you want to record this expense?"
      />

      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={() => setSnackbarOpen(false)}>
        <Alert onClose={() => setSnackbarOpen(false)} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default Expenses;
