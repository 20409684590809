import React, { useState, useEffect } from 'react';
import {
  Container,
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Box,
  MenuItem,

} from '@mui/material';
import { Add, Edit, Delete, Visibility} from '@mui/icons-material';
import {
  getCustomers,
  createCustomer,
  updateCustomer,
  deleteCustomer,
} from '../../services/customerService';
import { IdTypes } from '../../enums/IdTypes';
import { Link } from 'react-router-dom';


const CustomerManagement: React.FC = () => {
  const [customers, setCustomers] = useState<any[]>([]);
  const [showModal, setShowModal] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [currentCustomer, setCurrentCustomer] = useState<any>(null);
  const [customerName, setCustomerName] = useState('');
  const [mobileNumber, setMobileNumber] = useState('');
  const [address, setAddress] = useState(''); // New state for address
  const [idType, setIdType] = useState<IdTypes>(IdTypes.AADHAAR); // Initialize with a default value
  const [idNumber, setIdNumber] = useState('');
  const hotelId = localStorage.getItem("selectedHotel");

  useEffect(() => {
    const fetchCustomers = async () => {
      try {
        const response = await getCustomers();
        setCustomers(response);
      } catch (error) {
        console.error('Error fetching customers', error);
      }
    };
    fetchCustomers();
  }, []);

  const handleOpenModal = (customer?: any) => {
    if (customer) {
      setEditMode(true);
      setCurrentCustomer(customer);
      setCustomerName(customer.customerName);
      setMobileNumber(customer.mobileNumber);
      setAddress(customer.address); // Set address from customer data
      setIdType(customer.idType); // Set ID Type from customer data
      setIdNumber(customer.idNumber);
    } else {
      setEditMode(false);
      setCurrentCustomer(null);
      setCustomerName('');
      setMobileNumber('');
      setAddress(''); // Reset address
      setIdType(IdTypes.AADHAAR); // Reset to default value
      setIdNumber('');
    }
    setShowModal(true);
  };

  const handleCloseModal = () => setShowModal(false);

  const handleSave = async (event: React.FormEvent) => {
    event.preventDefault();

    const customerData = {
      hotelId,
      customerName,
      mobileNumber,
      address, // Include address in the data
      idType,
      idNumber,
    };

    try {
      if (editMode && currentCustomer) {
        await updateCustomer(currentCustomer._id, customerData);
      } else {
        await createCustomer(customerData);
      }
      setShowModal(false);
      const response = await getCustomers();
      setCustomers(response);
    } catch (error) {
      console.error('Error saving customer', error);
    }
  };

  const handleDelete = async (id: string) => {
    if (window.confirm('Are you sure you want to delete this customer?')) {
      try {
        await deleteCustomer(id);
        const response = await getCustomers();
        setCustomers(response);
      } catch (error) {
        console.error('Error deleting customer', error);
      }
    }
  };

  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        Manage Customers
      </Typography>
      <Button
        variant="contained"
        color="primary"
        startIcon={<Add />}
        onClick={() => handleOpenModal()}
      >
        Add Customer
      </Button>

      <TableContainer component={Paper} sx={{ mt: 3 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Mobile Number</TableCell>
              <TableCell>Address</TableCell> {/* Add Address column */}
              <TableCell>ID Type</TableCell>
              <TableCell>ID Number</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {customers.map((customer) => (
              <TableRow key={customer._id}>
                <TableCell>{customer.customerName}</TableCell>
                <TableCell>{customer.mobileNumber}</TableCell>
                <TableCell>{customer.address}</TableCell> {/* Display Address */}
                <TableCell>{customer.idType}</TableCell>
                <TableCell>{customer.idNumber}</TableCell>
                <TableCell>
                <IconButton
                  component={Link}
                  to={`/customer-profile/${customer._id}`} // Use Link to navigate to the profile
                  color="primary"
                >
                  <Visibility /> {/* Use an icon for viewing */}
                </IconButton>
                  <IconButton
                    color="primary"
                    onClick={() => handleOpenModal(customer)}
                  >
                    <Edit />
                  </IconButton>
                  <IconButton
                    color="secondary"
                    onClick={() => handleDelete(customer._id)}
                  >
                    <Delete />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Modal for Adding/Editing Customer */}
      <Dialog open={showModal} onClose={handleCloseModal} fullWidth maxWidth="sm">
        <DialogTitle>{editMode ? 'Edit Customer' : 'Add Customer'}</DialogTitle>
        <DialogContent>
          <Box component="form" onSubmit={handleSave} noValidate>
            <TextField
              margin="normal"
              fullWidth
              label="Customer Name"
              value={customerName}
              onChange={(e) => setCustomerName(e.target.value)}
              required
            />
            <TextField
              margin="normal"
              fullWidth
              label="Mobile Number"
              value={mobileNumber}
              onChange={(e) => setMobileNumber(e.target.value)}
              required
            />
            <TextField
              margin="normal"
              fullWidth
              label="Address"
              value={address} // Handle address input
              onChange={(e) => setAddress(e.target.value)}
              required
            />
            <TextField
              margin="normal"
              fullWidth
              label="ID Type"
              select
              value={idType}
              onChange={(e) => setIdType(e.target.value as IdTypes)}
            >
              {Object.values(IdTypes).map((type) => (
                <MenuItem key={type} value={type}>
                  {type}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              margin="normal"
              fullWidth
              label="ID Number"
              value={idNumber}
              onChange={(e) => setIdNumber(e.target.value)}
            />
            <DialogActions>
              <Button onClick={handleCloseModal} color="secondary">
                Cancel
              </Button>
              <Button type="submit" color="primary">
                {editMode ? 'Save Changes' : 'Add Customer'}
              </Button>
            </DialogActions>
          </Box>
        </DialogContent>
      </Dialog>
    </Container>
  );
};

export default CustomerManagement;
