import React, { useEffect, useState } from 'react';
import {
  Container,
  Typography,
  Paper,
  List,
  ListItem,
  ListItemText,
  Divider,
  Box,
  CircularProgress,
  Chip,
} from '@mui/material';
import { getRooms } from '../../services/roomService';

interface Room {
  _id: string;
  name: string;
  type: string;
  price: number;
  available: boolean;
}

const RoomList: React.FC = () => {
  const [rooms, setRooms] = useState<Room[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchRooms = async () => {
      try {
        const response = await getRooms();
        setRooms(response);
      } catch (error) {
        console.error('Error fetching rooms:', error);
      } finally {
        setLoading(false);
      }
    };
    fetchRooms();
  }, []);

  if (loading) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Container maxWidth="sm">
      <Typography variant="h4" gutterBottom align="center">
        Available Rooms
      </Typography>
      <Paper elevation={3}>
        <List>
          {rooms.map((room) => (
            <React.Fragment key={room._id}>
              <ListItem>
                <ListItemText
                  primary={room.name}
                  secondary={
                    <>
                      <Typography variant="body2" color="textSecondary">
                        Type: {room.type}
                      </Typography>
                      <Typography variant="body2" color="textSecondary">
                        Price: Rs.{room.price}
                      </Typography>
                      <Chip
                        label={room.available ? 'Available' : 'Unavailable'}
                        color={room.available ? 'success' : 'error'}
                        size="small"
                        sx={{ marginTop: 1 }}
                      />
                    </>
                  }
                  primaryTypographyProps={{ variant: 'h6', color: 'primary' }}
                />
              </ListItem>
              <Divider />
            </React.Fragment>
          ))}
        </List>
      </Paper>
    </Container>
  );
};

export default RoomList;
