export enum TaxType {
    GST = 'GST',
    CGST = 'CGST',
    SGST = 'SGST',
    IGST = 'IGST',
    SERVICE_TAX = 'SERVICE_TAX',
    VAT = 'VAT',
    LUXURY_TAX = 'LUXURY_TAX',
    ENTERTAINMENT_TAX = 'ENTERTAINMENT_TAX',
  }
  
  export enum ProductCategory {
    FOOD = 'FOOD',
    BEVERAGES = 'BEVERAGES',
    ACCOMMODATION = 'ACCOMMODATION',
    SPA = 'SPA',
    LAUNDRY = 'LAUNDRY',
    PARKING = 'PARKING',
    ROOM_SERVICE = 'ROOM_SERVICE',
    CONFERENCE_HALL = 'CONFERENCE_HALL',
    BANQUET_HALL = 'BANQUET_HALL',
  }
  