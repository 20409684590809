import { getHotelIdFromLocalStorage } from '../utils/localStorageUtils';
import api from './api';

// Create an inventory item
export const createInventoryItem = async (data: any) => {
  const hotelId = getHotelIdFromLocalStorage();
  
  if (!hotelId) throw new Error('No hotel selected');

  try {
    const response = await api.post('/inventory', { ...data, hotelId });
    return response.data;
  } catch (error) {
    console.error('Error creating inventory item', error);
    throw error;
  }
};

// Fetch all inventory items for the selected hotel
export const getInventoryItems = async () => {
  const hotelId = getHotelIdFromLocalStorage();
  
  if (!hotelId) throw new Error('No hotel selected');

  try {
    const response = await api.get(`/inventory?hotelId=${hotelId}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching inventory items', error);
    throw error;
  }
};

// Fetch a specific inventory item by ID
export const getInventoryItemById = async (id: string) => {
  const hotelId = getHotelIdFromLocalStorage();

  if (!hotelId) throw new Error('No hotel selected');

  try {
    const response = await api.get(`/inventory/${id}?hotelId=${hotelId}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching inventory item', error);
    throw error;
  }
};

// Update an inventory item by ID
export const updateInventoryItem = async (id: string, data: any) => {
  const hotelId = getHotelIdFromLocalStorage();

  if (!hotelId) throw new Error('No hotel selected');

  try {
    const response = await api.put(`/inventory/${id}`, { ...data, hotelId });
    return response.data;
  } catch (error) {
    console.error('Error updating inventory item', error);
    throw error;
  }
};

// Delete an inventory item by ID
export const deleteInventoryItem = async (id: string) => {
  const hotelId = getHotelIdFromLocalStorage();

  if (!hotelId) throw new Error('No hotel selected');

  try {
    const response = await api.delete(`/inventory/${id}?hotelId=${hotelId}`);
    return response.data;
  } catch (error) {
    console.error('Error deleting inventory item', error);
    throw error;
  }
};

// Search for inventory items by name or other filters
export const searchInventoryItems = async (query: string) => {
  const hotelId = getHotelIdFromLocalStorage();

  if (!hotelId) throw new Error('No hotel selected');

  try {
    const response = await api.get(`/inventory/search`, {
      params: {
        hotelId,
        q: query,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error searching inventory items', error);
    throw error;
  }
};
