import React from 'react';
import { Box } from '@mui/material';

interface TabComponentProps {
  children?: React.ReactNode;
  value: string;
  index: string;
}

const TabComponent: React.FC<TabComponentProps> = ({ children, value, index }) => {
  return (
    <Box
      role="TabComponent"
      hidden={value !== index}
      id={`TabComponent-${index}`}
      aria-labelledby={`tab-${index}`}
      sx={{ p: 3 }}
    >
      {value === index && <>{children}</>}
    </Box>
  );
};

export default TabComponent;
