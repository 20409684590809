// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tooltip {
    position: absolute;
    background-color: #333;
    color: #fff;
    padding: 8px;
    border-radius: 4px;
    transition: opacity 0.3s;
    opacity: 0;
    pointer-events: none;
    z-index: 1000;
  }
  
  .tooltip.visible {
    opacity: 1;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/Shared/Tooltip.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,sBAAsB;IACtB,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,wBAAwB;IACxB,UAAU;IACV,oBAAoB;IACpB,aAAa;EACf;;EAEA;IACE,UAAU;EACZ","sourcesContent":[".tooltip {\n    position: absolute;\n    background-color: #333;\n    color: #fff;\n    padding: 8px;\n    border-radius: 4px;\n    transition: opacity 0.3s;\n    opacity: 0;\n    pointer-events: none;\n    z-index: 1000;\n  }\n  \n  .tooltip.visible {\n    opacity: 1;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
