import { getHotelIdFromLocalStorage } from '../utils/localStorageUtils';
import api from './api';

// Record Expense
export const createExpense = async (data:any) => {
    const hotelId = getHotelIdFromLocalStorage();
    
    if (!hotelId) throw new Error('No hotel selected');
  
    try {
      const response = await api.post('/expenses', { ...data, hotelId });
      return response.data;
    } catch (error) {
      console.error('Error creating expense', error);
      throw error;
    }
  };

// Fetch all Expenses
export const getExpenses = async () => {
    const hotelId = getHotelIdFromLocalStorage();
  
    if (!hotelId) throw new Error('No hotel selected');
  
    try {
      const response = await api.get(`/expenses?hotelId=${hotelId}`); // Pass the hotelId as a query parameter
      return response.data;
    } catch (error) {
      console.error('Error fetching expenses', error);
      throw error;
    }
  };

// Additional Expense related functions can be added here
