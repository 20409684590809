import React, { useEffect, useState } from 'react';
import {
  Container,
  Typography,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Snackbar,
  Alert,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
  Paper,
  TextField,
  IconButton,
} from '@mui/material';
import { AddCircle } from '@mui/icons-material';
import { getAllSuppliers } from '../../services/supplierService'; // Service for API calls
// import { uploadBill, parseBillData } from '../../services/invoiceService'; // Service to handle uploads and parsing

const UploadPurchases: React.FC = () => {
  const [suppliers, setSuppliers] = useState<any[]>([]);
  const [selectedSupplier, setSelectedSupplier] = useState<string>('');
  const [billNumber, setBillNumber] = useState<string>('');
  const [items, setItems] = useState<any[]>([]);
  const [newItem, setNewItem] = useState({
    name: '',
    quantity: 1,
    unitPrice: 0,
    taxRate: 18, // Default GST rate
  });
  const [loading, setLoading] = useState<boolean>(false);
  const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error'>('success');

  useEffect(() => {
    const fetchSuppliers = async () => {
      const supplierData = await getAllSuppliers();
      setSuppliers(supplierData);
    };
    fetchSuppliers();
  }, []);

//   const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
//     if (e.target.files && e.target.files.length > 0) {
//       setFile(e.target.files[0]);
//     }
//   };

  const handleAddItem = () => {
    const { name, quantity, unitPrice, taxRate } = newItem;
    if (!name || !quantity || !unitPrice) return;

    const taxableAmount = quantity * unitPrice;
    const gstAmount = (taxRate / 100) * taxableAmount;
    const totalCost = taxableAmount + gstAmount;

    setItems([...items, { name, quantity, unitPrice, taxRate, taxableAmount, gstAmount, totalCost }]);
    setNewItem({ name: '', quantity: 1, unitPrice: 0, taxRate: 18 });
  };

  const handleSubmitItems = async () => {
    // Implement logic to submit items to inventory along with bill number and supplier
  };

  return (
    <Container maxWidth="lg" sx={{ mt: 4 }}>
      <Typography variant="h4" component="h1" gutterBottom>
        Upload Purchase Bill
      </Typography>

      <FormControl fullWidth margin="normal">
        <InputLabel>Select Supplier</InputLabel>
        <Select
          value={selectedSupplier}
          onChange={(e) => setSelectedSupplier(e.target.value)}
        >
          {suppliers.map((supplier) => (
            <MenuItem key={supplier._id} value={supplier._id}>
              {supplier.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <TextField
        label="Bill Number"
        fullWidth
        margin="normal"
        value={billNumber}
        onChange={(e) => setBillNumber(e.target.value)}
        required
      />


      <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
        Add Items
      </Typography>
      <TableContainer component={Paper} sx={{ borderRadius: 2, mt: 2 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Item Name</TableCell>
              <TableCell>Quantity</TableCell>
              <TableCell>Unit Price</TableCell>
              <TableCell>Tax Rate (%)</TableCell>
              <TableCell>Taxable Amount</TableCell>
              <TableCell>GST Amount</TableCell>
              <TableCell>Total Cost</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items.map((item, index) => (
              <TableRow key={index}>
                <TableCell>{item.name}</TableCell>
                <TableCell>{item.quantity}</TableCell>
                <TableCell>₹ {item.unitPrice.toFixed(2)}</TableCell>
                <TableCell>{item.taxRate}%</TableCell>
                <TableCell>₹ {item.taxableAmount.toFixed(2)}</TableCell>
                <TableCell>₹ {item.gstAmount.toFixed(2)}</TableCell>
                <TableCell>₹ {item.totalCost.toFixed(2)}</TableCell>
              </TableRow>
            ))}

            <TableRow>
              <TableCell>
                <TextField
                  label="Item Name"
                  value={newItem.name}
                  onChange={(e) => setNewItem({ ...newItem, name: e.target.value })}
                  required
                />
              </TableCell>
              <TableCell>
                <TextField
                  label="Quantity"
                  type="number"
                  value={newItem.quantity}
                  onChange={(e) => setNewItem({ ...newItem, quantity: parseFloat(e.target.value) })}
                  required
                />
              </TableCell>
              <TableCell>
                <TextField
                  label="Unit Price"
                  type="number"
                  value={newItem.unitPrice}
                  onChange={(e) => setNewItem({ ...newItem, unitPrice: parseFloat(e.target.value) })}
                  required
                />
              </TableCell>
              <TableCell>
                <TextField
                  label="Tax Rate (%)"
                  type="number"
                  value={newItem.taxRate}
                  onChange={(e) => setNewItem({ ...newItem, taxRate: parseFloat(e.target.value) })}
                  required
                />
              </TableCell>
              <TableCell>₹ {(newItem.quantity * newItem.unitPrice).toFixed(2)}</TableCell>
              <TableCell>₹ {((newItem.taxRate / 100) * (newItem.quantity * newItem.unitPrice)).toFixed(2)}</TableCell>
              <TableCell>₹ {((newItem.quantity * newItem.unitPrice) + ((newItem.taxRate / 100) * (newItem.quantity * newItem.unitPrice))).toFixed(2)}</TableCell>
              <TableCell>
                <IconButton color="primary" onClick={handleAddItem}>
                  <AddCircle />
                </IconButton>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      <Button variant="contained" color="primary" onClick={handleSubmitItems} sx={{ mt: 2 }}>
        Submit Items to Inventory
      </Button>

      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={() => setSnackbarOpen(false)}>
        <Alert onClose={() => setSnackbarOpen(false)} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default UploadPurchases;
