import React, { useEffect, useState } from 'react';
import {
  Container,
  Typography,
  Button,
  Paper,
  CircularProgress,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Snackbar,
  Alert,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
} from '@mui/material';
import { createInventoryItem, getInventoryItems } from '../../services/inventoryService'; // Service for API calls

const ManageInventory: React.FC = () => {
  const [name, setName] = useState<string>('');
  const [quantity, setQuantity] = useState<number>(0);
  const [unit, setUnit] = useState<string>('kg'); // Default unit
  const [unitPrice, setUnitPrice] = useState<number>(0);
  const [totalCost, setTotalCost] = useState<number>(0);
  const [category, setCategory] = useState<string>(''); // Added category
  const [date, setDate] = useState<string>(new Date().toISOString().slice(0, 10));
  const [loading, setLoading] = useState<boolean>(true);
  const [open, setOpen] = useState<boolean>(false);
  const [inventoryItems, setInventoryItems] = useState<any[]>([]);
  const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error'>('success');

  useEffect(() => {
    const fetchInventoryItems = async () => {
      try {
        const data = await getInventoryItems(); // Fetch existing inventory items
        setInventoryItems(data);
      } catch (err) {
        console.error('Error fetching inventory items:', err);
      } finally {
        setLoading(false);
      }
    };

    fetchInventoryItems();
  }, []);

  const handleQuantityChange = (value: number) => {
    setQuantity(value);
    const total = value * unitPrice; // Calculate total cost
    setTotalCost(total);
  };

  const handleCostPerUnitChange = (value: number) => {
    setUnitPrice(value);
    const total = quantity * value; // Update total cost
    setTotalCost(total);
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const data = {
        name,
        quantity,
        unit,
        unitPrice,
        totalCost,
        date,
        category, // Added category to submission data
      };
      await createInventoryItem(data);
      setSnackbarSeverity('success');
      setSnackbarMessage('Inventory Item Recorded Successfully');
      setName('');
      setQuantity(0);
      setUnitPrice(0);
      setTotalCost(0);
      setCategory(''); // Reset category
      setOpen(false);
      
      // Refresh the inventory list after submission
      const updatedInventoryItems = await getInventoryItems();
      setInventoryItems(updatedInventoryItems);
    } catch (err) {
      console.error('Error creating inventory item:', err);
      setSnackbarSeverity('error');
      setSnackbarMessage('Failed to record inventory item');
    } finally {
      setLoading(false);
      setSnackbarOpen(true);
    }
  };

  return (
    <Container maxWidth="lg" sx={{ mt: 4 }}>
      <Typography variant="h4" component="h1" gutterBottom sx={{ color: '#2C3E50', fontWeight: 'bold' }}>
        Inventory Management
      </Typography>

      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '300px' }}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          <Button variant="contained" color="primary" onClick={() => setOpen(true)} sx={{ mb: 2 }}>
            Add Inventory Item
          </Button>

          <TableContainer component={Paper} sx={{ borderRadius: 2 }}>
            <Table>
              <TableHead>
                <TableRow sx={{ backgroundColor: '#34495e' }}>
                  <TableCell sx={{ color: '#fff', fontWeight: 'bold' }}>Item Name</TableCell>
                  <TableCell sx={{ color: '#fff', fontWeight: 'bold' }}>Quantity</TableCell>
                  <TableCell sx={{ color: '#fff', fontWeight: 'bold' }}>Unit</TableCell>
                  <TableCell sx={{ color: '#fff', fontWeight: 'bold' }}>Category</TableCell>
                  <TableCell sx={{ color: '#fff', fontWeight: 'bold' }}>Cost Per Unit</TableCell>
                  <TableCell sx={{ color: '#fff', fontWeight: 'bold' }}>Total Cost</TableCell>
                  <TableCell sx={{ color: '#fff', fontWeight: 'bold' }}>Date</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {inventoryItems.map((item) => (
                  <TableRow key={item._id}>
                    <TableCell>{item.name}</TableCell>
                    <TableCell>{item.quantity}</TableCell>
                    <TableCell>{item.unit}</TableCell>
                    <TableCell>{item.category}</TableCell> {/* Display category */}
                    <TableCell>₹ {item.unitPrice}</TableCell>
                    <TableCell>₹ {item.totalCost}</TableCell>
                    <TableCell>{new Date(item.date).toLocaleDateString()}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}

      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>Add Inventory Item</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Item Name"
            fullWidth
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
          <TextField
            margin="dense"
            label="Quantity"
            type="number"
            fullWidth
            value={quantity}
            onChange={(e) => handleQuantityChange(Number(e.target.value))}
            required
          />
          <FormControl fullWidth margin="dense" required>
            <InputLabel>Unit</InputLabel>
            <Select
              value={unit}
              onChange={(e) => setUnit(e.target.value as string)}
              label="Unit"
            >
              <MenuItem value="kg">Kg</MenuItem>
              <MenuItem value="litre">Litre</MenuItem>
              <MenuItem value="piece">Piece</MenuItem>
            </Select>
          </FormControl>
          <TextField
            margin="dense"
            label="Category"
            fullWidth
            value={category}
            onChange={(e) => setCategory(e.target.value)}
            required
          />
          <TextField
            margin="dense"
            label="Cost Per Unit"
            type="number"
            fullWidth
            value={unitPrice}
            onChange={(e) => handleCostPerUnitChange(Number(e.target.value))}
            required
          />
          <TextField
            margin="dense"
            label="Total Cost"
            type="number"
            fullWidth
            value={totalCost}
            disabled
          />
          <TextField
            margin="dense"
            label="Date"
            type="date"
            fullWidth
            value={date}
            onChange={(e) => setDate(e.target.value)}
            required
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} color="primary">
            Cancel
          </Button>
          <Button
            onClick={handleSubmit}
            color="primary"
            disabled={loading}
          >
            {loading ? <CircularProgress size={24} color="inherit" /> : 'Record Inventory'}
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={() => setSnackbarOpen(false)}>
        <Alert onClose={() => setSnackbarOpen(false)} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default ManageInventory;
