import React, { useState, useEffect } from 'react';
import { AppBar, Toolbar, Typography, FormControl, InputLabel, Select, MenuItem, SelectChangeEvent, IconButton, Avatar, Box, Button, Menu } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { getAllHotels } from '../../services/hotelService';
import { useNavigate } from 'react-router-dom';
import { setDataToLocalStorage } from '../../utils/localStorageUtils';

const Header: React.FC = () => {
  const [hotels, setHotels] = useState<any[]>([]);
  const [selectedHotel, setSelectedHotel] = useState<string | null>(null);
  const [isProduction, setIsProduction] = useState<boolean>(false); // New state to store isProduction flag
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null); // State for menu
  const navigate = useNavigate();

  useEffect(() => {
    const fetchHotels = async () => {
      try {
        const response = await getAllHotels();
        setHotels(response);
      } catch (error) {
        console.error('Error fetching hotels', error);
      }
    };

    fetchHotels();
  }, []);

  useEffect(() => {
    const savedHotel = localStorage.getItem('selectedHotel');
    if (savedHotel) {
      setSelectedHotel(savedHotel);
      const selectedHotelObj = hotels.find(hotel => hotel._id === savedHotel);
      if (selectedHotelObj) {
        setIsProduction(selectedHotelObj.isProduction || false); // Set isProduction from the selected hotel
      }
    }
  }, [hotels]);

  const handleHotelChange = (event: SelectChangeEvent<string>) => {
    const hotelId = event.target.value;
    setSelectedHotel(hotelId);
    const selectedHotelObj = hotels.find(hotel => hotel._id === hotelId);
    if (selectedHotelObj) {
      setIsProduction(selectedHotelObj.isProduction || false); // Update isProduction when hotel changes
      setDataToLocalStorage('isProduction', selectedHotelObj.isProduction ? selectedHotelObj.isProduction : true);
    }
    localStorage.setItem('selectedHotel', hotelId);
  };

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget); // Set the anchor element for the menu
  };

  const handleMenuClose = () => {
    setAnchorEl(null); // Close the menu
  };

  const handleLogout = () => {
    localStorage.clear(); // Clear localStorage
    navigate('/login'); // Navigate to login page
  };

  return (
    <AppBar
      position="fixed"
      sx={{
        zIndex: (theme) => theme.zIndex.drawer + 1,
        bgcolor: isProduction ? '#388E3C' : '#B71C1C', // Professional colors for production and non-production
        boxShadow: 'none',
      }}
    >
      <Toolbar>
        <IconButton edge="start" color="inherit" aria-label="menu" sx={{ mr: 2 }}>
          <MenuIcon />
        </IconButton>
        <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}>
          Personalised Billing
        </Typography>
        <FormControl variant="outlined" sx={{ minWidth: 150, mr: 2 }}>
          <InputLabel htmlFor="hotel-select">Select Hotel</InputLabel>
          <Select
            id="hotel-select"
            value={selectedHotel || ''}
            onChange={handleHotelChange}
            label="Select Hotel"
            sx={{ backgroundColor: 'white', borderRadius: '4px' }}
          >
            {hotels.map((hotel) => (
              <MenuItem key={hotel._id} value={hotel._id}>
                {hotel.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <IconButton color="inherit" onClick={handleMenuOpen}>
            <Avatar alt="User Avatar" src="/path/to/avatar.jpg" />
          </IconButton>
          <Typography variant="body1" sx={{ ml: 1, mr: 2 }} onClick={handleMenuOpen} style={{ cursor: 'pointer' }}>
            Admin
          </Typography>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
            PaperProps={{
              style: {
                width: '200px',
              },
            }}
          >
            <MenuItem onClick={handleLogout}>Logout</MenuItem>
          </Menu>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
