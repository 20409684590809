// services/tableService.ts
import { getHotelIdFromLocalStorage } from '../utils/localStorageUtils';
import api from './api';

// Get all tables for a specific hotel
export const getTables = async () => {
  const hotelId = getHotelIdFromLocalStorage();
  
  try {
    const response = await api.get('/tables', { params: { hotelId } });
    return response.data;
  } catch (error) {
    console.error('Error fetching tables', error);
    throw error;
  }
};

// Create a new table for a specific hotel
export const createTable = async (data: any) => {
  const hotelId = getHotelIdFromLocalStorage();
  
  try {
    const response = await api.post('/tables', { ...data, hotelId });
    return response.data;
  } catch (error) {
    console.error('Error creating table', error);
    throw error;
  }
};

// Update an existing table for a specific hotel
export const updateTable = async (id: string, data: any) => {
  const hotelId = getHotelIdFromLocalStorage();
  
  try {
    const response = await api.put(`/tables/${id}`, { ...data, hotelId });
    return response.data;
  } catch (error) {
    console.error('Error updating table', error);
    throw error;
  }
};

// Delete a table for a specific hotel
export const deleteTable = async (id: string) => {
  const hotelId = getHotelIdFromLocalStorage();
  
  try {
    const response = await api.delete(`/tables/${id}`, { params: { hotelId } });
    return response.data;
  } catch (error) {
    console.error('Error deleting table', error);
    throw error;
  }
};
