import React, { useState } from 'react';
import {
  TextField,
  Button,
  Grid,
  Paper,
  Typography,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  CircularProgress,
} from '@mui/material';
import { AdminRoles } from '../../enums/AdminRoles';
import { createAdmin } from '../../services/adminService'; 

const AdminManagement: React.FC = () => {
  const [mobileNumber, setMobileNumber] = useState('');
  const [role, setRole] = useState<AdminRoles>(AdminRoles.ADMIN);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState<string | null>(null);

  const handleSaveAdmin = async () => {
    setLoading(true);
    try {
      // Use the createAdmin function from adminService
      const response = await createAdmin({
        mobileNumber,
        role,
      });

      setMessage(response.message); // Use response.message since we're returning data.message in adminService
      setMobileNumber('');
      setRole(AdminRoles.ADMIN);
    } catch (error: any) {
      setMessage(error.response?.data?.message || 'Error adding admin. Please try again.');
      console.error('Error adding admin:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Paper sx={{ padding: 3 }}>
      <Typography variant="h6" gutterBottom>
        Add Admin for Selected Hotel
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <TextField
            label="Mobile Number"
            variant="outlined"
            fullWidth
            value={mobileNumber}
            onChange={e => setMobileNumber(e.target.value)}
            required
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl fullWidth variant="outlined">
            <InputLabel>Role</InputLabel>
            <Select
              value={role}
              onChange={e => setRole(e.target.value as AdminRoles)}
              label="Role"
            >
              {Object.values(AdminRoles).map((roleOption) => (
                <MenuItem key={roleOption} value={roleOption}>
                  {roleOption}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSaveAdmin}
            disabled={loading}
            fullWidth
          >
            {loading ? <CircularProgress size={24} /> : 'Add Admin'}
          </Button>
        </Grid>
        {message && (
          <Grid item xs={12}>
            <Typography color={message.includes('Error') ? 'error' : 'primary'}>
              {message}
            </Typography>
          </Grid>
        )}
      </Grid>
    </Paper>
  );
};

export default AdminManagement;
