import React from 'react';
import { Drawer, List, ListItem, ListItemIcon, ListItemText, Toolbar } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import RestaurantIcon from '@mui/icons-material/Restaurant';
import HomeIcon from '@mui/icons-material/Home';
import HotelIcon from '@mui/icons-material/Hotel';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import PeopleIcon from '@mui/icons-material/People';
import ReceiptIcon from '@mui/icons-material/Receipt';
import WorkIcon from '@mui/icons-material/Work'; // Added for Manage Employees
import BusinessIcon from '@mui/icons-material/Business'; // Added for Manage Rooms
import PersonIcon from '@mui/icons-material/Person'; // Added for Manage Customers

const drawerWidth = 240;

const SideMenu: React.FC = () => {
  const location = useLocation();

  return (
    <Drawer
      variant="permanent"
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: drawerWidth,
          boxSizing: 'border-box',
          backgroundColor: '#2C3E50',  // Updated sidebar background color
          color: '#fff',                // Sidebar text color for better contrast
          borderRight: '1px solid #e0e0e0',
        },
      }}
    >
      <Toolbar />
      <List>
        <ListItem
          button
          component={Link}
          to="/"
          selected={location.pathname === '/'}
          sx={{
            '&.Mui-selected': {
              backgroundColor: '#e3f2fd',
              '& .MuiListItemIcon-root': { color: '#1976d2' },
            },
            '& .MuiListItemText-root': { color: '#fff' },  // Adjusted text color
          }}
        >
          <ListItemIcon>
            <HomeIcon sx={{ color: '#fff' }} />  {/* Adjusted icon color */}
          </ListItemIcon>
          <ListItemText primary="Home" />
        </ListItem>

        <ListItem
          button
          component={Link}
          to="/bills"
          selected={location.pathname === '/bills'}
          sx={{
            '&.Mui-selected': {
              backgroundColor: '#e3f2fd',
              '& .MuiListItemIcon-root': { color: '#1976d2' },
            },
            '& .MuiListItemText-root': { color: '#fff' },  // Adjusted text color
          }}
        >
          <ListItemIcon>
            <RestaurantIcon sx={{ color: '#fff' }} />  {/* Adjusted icon color */}
          </ListItemIcon>
          <ListItemText primary="Restaurant" />
        </ListItem>

        <ListItem
          button
          component={Link}
          to="/guest-registration"
          selected={location.pathname === '/guest-registration'}
          sx={{
            '&.Mui-selected': {
              backgroundColor: '#e3f2fd',
              '& .MuiListItemIcon-root': { color: '#1976d2' },
            },
            '& .MuiListItemText-root': { color: '#fff' },  // Adjusted text color
          }}
        >
          <ListItemIcon>
            <HotelIcon sx={{ color: '#fff' }} />  {/* Adjusted icon color */}
          </ListItemIcon>
          <ListItemText primary="Guest Registration" />
        </ListItem>

        <ListItem
          button
          component={Link}
          to="/checkedin-customers"
          selected={location.pathname === '/checkedin-customers'}
          sx={{
            '&.Mui-selected': {
              backgroundColor: '#e3f2fd',
              '& .MuiListItemIcon-root': { color: '#1976d2' },
            },
            '& .MuiListItemText-root': { color: '#fff' },  // Adjusted text color
          }}
        >
          <ListItemIcon>
            <PeopleIcon sx={{ color: '#fff' }} />  {/* Adjusted icon color */}
          </ListItemIcon>
          <ListItemText primary="Checked-In Customers" />
        </ListItem>

        <ListItem
          button
          component={Link}
          to="/room-billings"
          selected={location.pathname === '/room-billings'}
          sx={{
            '&.Mui-selected': {
              backgroundColor: '#e3f2fd',
              '& .MuiListItemIcon-root': { color: '#1976d2' },
            },
            '& .MuiListItemText-root': { color: '#fff' },  // Adjusted text color
          }}
        >
          <ListItemIcon>
            <ReceiptIcon sx={{ color: '#fff' }} />  {/* Adjusted icon color */}
          </ListItemIcon>
          <ListItemText primary="Room Billings" />
        </ListItem>

        <ListItem
          button
          component={Link}
          to="/reconcile"
          selected={location.pathname === '/reconcile'}
          sx={{
            '&.Mui-selected': {
              backgroundColor: '#e3f2fd',
              '& .MuiListItemIcon-root': { color: '#1976d2' },
            },
            '& .MuiListItemText-root': { color: '#fff' },  // Adjusted text color
          }}
        >
          <ListItemIcon>
            <CheckCircleIcon sx={{ color: '#fff' }} />  {/* Adjusted icon color */}
          </ListItemIcon>
          <ListItemText primary="Reconcile Bills" />
        </ListItem>

        <ListItem
          button
          component={Link}
          to="/menu-management"
          selected={location.pathname === '/menu-management'}
          sx={{
            '&.Mui-selected': {
              backgroundColor: '#e3f2fd',
              '& .MuiListItemIcon-root': { color: '#1976d2' },
            },
            '& .MuiListItemText-root': { color: '#fff' },  // Adjusted text color
          }}
        >
          <ListItemIcon>
            <MenuBookIcon sx={{ color: '#fff' }} />  {/* Adjusted icon color */}
          </ListItemIcon>
          <ListItemText primary="Manage Menu" />
        </ListItem>

        <ListItem
          button
          component={Link}
          to="/manage-employees"
          selected={location.pathname === '/manage-employees'}
          sx={{
            '&.Mui-selected': {
              backgroundColor: '#e3f2fd',
              '& .MuiListItemIcon-root': { color: '#1976d2' },
            },
            '& .MuiListItemText-root': { color: '#fff' },  // Adjusted text color
          }}
        >
          <ListItemIcon>
            <WorkIcon sx={{ color: '#fff' }} />  {/* Adjusted icon color */}
          </ListItemIcon>
          <ListItemText primary="Manage Employees" />
        </ListItem>

        <ListItem
          button
          component={Link}
          to="/rooms"
          selected={location.pathname === '/rooms'}
          sx={{
            '&.Mui-selected': {
              backgroundColor: '#e3f2fd',
              '& .MuiListItemIcon-root': { color: '#1976d2' },
            },
            '& .MuiListItemText-root': { color: '#fff' },  // Adjusted text color
          }}
        >
          <ListItemIcon>
            <BusinessIcon sx={{ color: '#fff' }} />  {/* Adjusted icon color */}
          </ListItemIcon>
          <ListItemText primary="Manage Rooms" />
        </ListItem>

        <ListItem
          button
          component={Link}
          to="/manage-customers"
          selected={location.pathname === '/manage-customers'}
          sx={{
            '&.Mui-selected': {
              backgroundColor: '#e3f2fd',
              '& .MuiListItemIcon-root': { color: '#1976d2' },
            },
            '& .MuiListItemText-root': { color: '#fff' },  // Adjusted text color
          }}
        >
          <ListItemIcon>
            <PersonIcon sx={{ color: '#fff' }} />  {/* Adjusted icon color */}
          </ListItemIcon>
          <ListItemText primary="Manage Customers" />
        </ListItem>

        <ListItem
          button
          component={Link}
          to="/manage-admins"
          selected={location.pathname === '/manage-admins'}
          sx={{
            '&.Mui-selected': {
              backgroundColor: '#e3f2fd',
              '& .MuiListItemIcon-root': { color: '#1976d2' },
            },
            '& .MuiListItemText-root': { color: '#fff' },  // Adjusted text color
          }}
        >
          <ListItemIcon>
            <AdminPanelSettingsIcon sx={{ color: '#fff' }} />  {/* Adjusted icon color */}
          </ListItemIcon>
          <ListItemText primary="Manage Admins" />
        </ListItem>


        <ListItem
          button
          component={Link}
          to="/manage-taxes"
          selected={location.pathname === '/manage-taxes'}
          sx={{
            '&.Mui-selected': {
              backgroundColor: '#e3f2fd',
              '& .MuiListItemIcon-root': { color: '#1976d2' },
            },
            '& .MuiListItemText-root': { color: '#fff' },  // Adjusted text color
          }}
        >
          <ListItemIcon>
            <AdminPanelSettingsIcon sx={{ color: '#fff' }} />  {/* Adjusted icon color */}
          </ListItemIcon>
          <ListItemText primary="Manage Taxes" />
        </ListItem>


        <ListItem
          button
          component={Link}
          to="/manage-templates"
          selected={location.pathname === '/manage-templates'}
          sx={{
            '&.Mui-selected': {
              backgroundColor: '#e3f2fd',
              '& .MuiListItemIcon-root': { color: '#1976d2' },
            },
            '& .MuiListItemText-root': { color: '#fff' },  // Adjusted text color
          }}
        >
          <ListItemIcon>
            <AdminPanelSettingsIcon sx={{ color: '#fff' }} />  {/* Adjusted icon color */}
          </ListItemIcon>
          <ListItemText primary="Manage Templates" />
        </ListItem>

        <ListItem
          button
          component={Link}
          to="/manage-hotels"
          selected={location.pathname === '/manage-hotels'}
          sx={{
            '&.Mui-selected': {
              backgroundColor: '#e3f2fd',
              '& .MuiListItemIcon-root': { color: '#1976d2' },
            },
            '& .MuiListItemText-root': { color: '#fff' },  // Adjusted text color
          }}
        >
          <ListItemIcon>
            <HotelIcon sx={{ color: '#fff' }} />  {/* Adjusted icon color */}
          </ListItemIcon>
          <ListItemText primary="Manage Hotels" />
        </ListItem>
      </List>
    </Drawer>
  );
};

export default SideMenu;
