import React from 'react';
import { Link } from 'react-router-dom';
import { Typography, Button, Container, Box, Grid } from '@mui/material';

const Home: React.FC = () => {
  return (
    <Container maxWidth="lg" sx={{ bgcolor: '#f0f4f8', borderRadius: '10px', p: 4 }}>
      <Box textAlign="center" sx={{ mb: 4 }}>
        <Typography variant="h3" sx={{ fontWeight: 'bold', color: '#1976d2' }}>
          Welcome to Your Hospitality Management System
        </Typography>
        <Typography variant="h6" sx={{ color: '#555', mt: 2 }}>
          Streamline your hotel operations, manage billing, and provide exceptional service!
        </Typography>
      </Box>

      <Grid container spacing={4} justifyContent="center">
        <Grid item xs={12} sm={6} md={4}>
          <Button 
            component={Link} 
            to="/bills" 
            variant="contained" 
            color="primary" 
            fullWidth 
            sx={{ padding: '16px', fontSize: '1.2rem', borderRadius: '8px' }}
          >
            Manage Bills
          </Button>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Button 
            component={Link} 
            to="/manage-hotels" 
            variant="contained" 
            color="secondary" 
            fullWidth 
            sx={{ padding: '16px', fontSize: '1.2rem', borderRadius: '8px' }}
          >
            Manage Hotels
          </Button>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Button 
            component={Link} 
            to="/manage-customers" 
            variant="contained" 
            color="success" 
            fullWidth 
            sx={{ padding: '16px', fontSize: '1.2rem', borderRadius: '8px' }}
          >
            Customer Management
          </Button>
        </Grid>
      </Grid>

      <Box textAlign="center" sx={{ mt: 5 }}>
        <Typography variant="body1" sx={{ color: '#777' }}>
          © {new Date().getFullYear()} Your Company Name. All rights reserved.
        </Typography>
      </Box>
    </Container>
  );
};

export default Home;
