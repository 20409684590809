// ReconciliationReport.tsx

import React, { useEffect, useState } from 'react';
import { Container, Typography, Paper, Grid, List, ListItem, Divider } from '@mui/material';
import { reconcileBills } from '../../utils/reconciliation';

const ReconciliationReport: React.FC = () => {
  const [report, setReport] = useState<any>(null);

  useEffect(() => {
    const fetchReconciliation = async () => {
      try {
        const data = await reconcileBills();
        setReport(data);
      } catch (error) {
        console.error('Error fetching reconciliation data', error);
      }
    };

    fetchReconciliation();
  }, []);

  if (!report) return <Typography variant="h6" align="center">Loading...</Typography>;

  return (
    <Container maxWidth="md" sx={{ mt: 4 }}>
      <Typography variant="h4" gutterBottom align="center">
        Reconciliation Report
      </Typography>

      {/* Regular Bills Section */}
      <Paper elevation={3} sx={{ p: 4, mb: 4 }}>
        <Typography variant="h5" gutterBottom>
          Regular Bills
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Typography variant="h6">Total Regular Bills</Typography>
            <Typography variant="h4" color="primary">
              Rs.{report.totalBills.toFixed(2)}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="h6">Total Payments</Typography>
            <Typography variant="h4" color="primary">
              Rs.{report.totalBillPayments.toFixed(2)}
            </Typography>
          </Grid>
        </Grid>
        <Typography variant="h5" gutterBottom>
          Discrepancies
        </Typography>
        {report.discrepanciesBills.length > 0 ? (
          <List>
            {report.discrepanciesBills.map((discrepancy: any) => (
              <React.Fragment key={discrepancy._id}>
                <ListItem>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={4}>
                      <Typography variant="body1">
                        <strong>Bill ID:</strong> {discrepancy._id}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Typography variant="body1">
                        <strong>Expected:</strong> Rs.{discrepancy.grandTotal.toFixed(2)}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Typography variant="body1">
                        <strong>Paid:</strong> Rs.{discrepancy.paymentAmount.toFixed(2)}
                      </Typography>
                    </Grid>
                  </Grid>
                </ListItem>
                <Divider />
              </React.Fragment>
            ))}
          </List>
        ) : (
          <Typography variant="body1" color="textSecondary">
            No discrepancies found for regular bills.
          </Typography>
        )}
      </Paper>

      {/* Room Bills Section */}
      <Paper elevation={3} sx={{ p: 4 }}>
        <Typography variant="h5" gutterBottom>
          Room Bills
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Typography variant="h6">Total Room Bills</Typography>
            <Typography variant="h4" color="primary">
              Rs.{report.totalRoomBills.toFixed(2)}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="h6">Total Payments</Typography>
            <Typography variant="h4" color="primary">
              Rs.{report.totalRoomPayments.toFixed(2)}
            </Typography>
          </Grid>
        </Grid>
        <Typography variant="h5" gutterBottom>
          Discrepancies
        </Typography>
        {report.discrepanciesRoomBills.length > 0 ? (
          <List>
            {report.discrepanciesRoomBills.map((discrepancy: any) => (
              <React.Fragment key={discrepancy._id}>
                <ListItem>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={4}>
                      <Typography variant="body1">
                        <strong>Room Bill ID:</strong> {discrepancy._id}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Typography variant="body1">
                        <strong>Expected:</strong> Rs.{discrepancy.totalAmount.toFixed(2)}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Typography variant="body1">
                        <strong>Paid:</strong> Rs.{discrepancy.paymentAmount.toFixed(2)}
                      </Typography>
                    </Grid>
                  </Grid>
                </ListItem>
                <Divider />
              </React.Fragment>
            ))}
          </List>
        ) : (
          <Typography variant="body1" color="textSecondary">
            No discrepancies found for room bills.
          </Typography>
        )}
      </Paper>
    </Container>
  );
};

export default ReconciliationReport;
