import React, { useState, useEffect } from 'react';
import { Box, Button, TextField, Select, MenuItem, Grid, Typography } from '@mui/material';
import { createTemplate, getAllTemplates, updateTemplate, deleteTemplate } from '../../services/templateService';
import SnackbarComponent from '../Shared/SnackbarComponent';
import ConfirmationDialog from '../Shared/ConfirmationDialog'; // Import the ConfirmationDialog

const TemplateManagement: React.FC = () => {
  const [templates, setTemplates] = useState<any[]>([]);
  const [selectedTemplate, setSelectedTemplate] = useState<any | null>(null);
  const [name, setName] = useState('');
  const [type, setType] = useState('room');
  const [templateContent, setTemplateContent] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error'>('success');

  const [dialogOpen, setDialogOpen] = useState(false); // State for dialog visibility
  const [templateToDelete, setTemplateToDelete] = useState<string | null>(null); // State to track template to delete
  const [isUpdateConfirmation, setIsUpdateConfirmation] = useState(false); // Track if confirmation is for update
  const [isCreateConfirmation, setIsCreateConfirmation] = useState(false); // Track if confirmation is for create

  useEffect(() => {
    fetchTemplates();
  }, []);

  const fetchTemplates = async () => {
    try {
      const fetchedTemplates = await getAllTemplates();
      setTemplates(fetchedTemplates);
    } catch (error) {
      console.error('Error fetching templates:', error);
    }
  };

  const handleSaveTemplate = async () => {
    if (selectedTemplate) {
      // If updating, show confirmation dialog for update
      setIsUpdateConfirmation(true);
      setDialogOpen(true);
    } else {
      // Show confirmation dialog for creating
      setIsCreateConfirmation(true);
      setDialogOpen(true);
    }
  };

  const confirmCreateTemplate = async () => {
    try {
      await createTemplate({ name, type, template: templateContent });
      setSnackbarMessage('Template created successfully');
      setSnackbarSeverity('success');
      fetchTemplates();
      resetForm();
    } catch (error) {
      setSnackbarMessage('Failed to create template');
      setSnackbarSeverity('error');
    } finally {
      setSnackbarOpen(true);
      setDialogOpen(false); // Close dialog after confirmation
      setIsCreateConfirmation(false); // Reset the create confirmation flag
    }
  };

  const confirmUpdateTemplate = async () => {
    try {
      await updateTemplate(selectedTemplate._id, { name, type, template: templateContent });
      setSnackbarMessage('Template updated successfully');
      setSnackbarSeverity('success');
      fetchTemplates();
      resetForm();
    } catch (error) {
      setSnackbarMessage('Failed to update template');
      setSnackbarSeverity('error');
    } finally {
      setSnackbarOpen(true);
      setDialogOpen(false); // Close dialog after confirmation
      setIsUpdateConfirmation(false); // Reset the update confirmation flag
    }
  };

  const confirmDeleteTemplate = async () => {
    if (templateToDelete) {
      try {
        await deleteTemplate(templateToDelete);
        setSnackbarMessage('Template deleted successfully');
        setSnackbarSeverity('success');
        fetchTemplates();
      } catch (error) {
        setSnackbarMessage('Failed to delete template');
        setSnackbarSeverity('error');
      } finally {
        setSnackbarOpen(true);
        setDialogOpen(false); // Close the dialog
        setTemplateToDelete(null); // Reset template to delete
      }
    }
  };

  const handleDeleteTemplate = (templateId: string) => {
    setTemplateToDelete(templateId); // Set the template ID to be deleted
    setIsUpdateConfirmation(false); // Ensure we're not handling update confirmation
    setIsCreateConfirmation(false); // Ensure we're not handling create confirmation
    setDialogOpen(true); // Open the confirmation dialog
  };

  const handleTemplateSelect = (template: any) => {
    setSelectedTemplate(template);
    setName(template.name);
    setType(template.type);
    setTemplateContent(template.template);
  };

  const resetForm = () => {
    setSelectedTemplate(null);
    setName('');
    setType('room');
    setTemplateContent('');
  };

  return (
    <Box p={3}>
      <Typography variant="h5" gutterBottom>Template Management</Typography>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            label="Template Name"
            fullWidth
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </Grid>

        <Grid item xs={12}>
          <Select
            label="Template Type"
            value={type}
            onChange={(e) => setType(e.target.value as string)}
            fullWidth
          >
            <MenuItem value="room">Room</MenuItem>
            <MenuItem value="restaurant">Restaurant</MenuItem>
          </Select>
        </Grid>

        <Grid item xs={12}>
          <TextField
            label="Template Content"
            multiline
            rows={6}
            fullWidth
            value={templateContent}
            onChange={(e) => setTemplateContent(e.target.value)}
          />
        </Grid>

        <Grid item xs={12}>
          <Button variant="contained" color="primary" onClick={handleSaveTemplate}>
            {selectedTemplate ? 'Update Template' : 'Create Template'}
          </Button>

          {selectedTemplate && (
            <Button
              variant="contained"
              color="secondary"
              onClick={resetForm}
              style={{ marginLeft: '10px' }}
            >
              Cancel
            </Button>
          )}
        </Grid>
      </Grid>

      <Typography variant="h6" gutterBottom style={{ marginTop: '20px' }}>
        Existing Templates
      </Typography>

      <Grid container spacing={2}>
        {templates.map((template) => (
          <Grid item xs={12} key={template._id}>
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <Typography variant="body1">{template.name} ({template.type})</Typography>
              <Box>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => handleTemplateSelect(template)}
                  style={{ marginRight: '10px' }}
                >
                  Edit
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => handleDeleteTemplate(template._id)} // Trigger delete dialog
                >
                  Delete
                </Button>
              </Box>
            </Box>
          </Grid>
        ))}
      </Grid>

      <SnackbarComponent
        open={snackbarOpen}
        message={snackbarMessage}
        severity={snackbarSeverity}
        onClose={() => setSnackbarOpen(false)}
      />

      {/* Confirmation Dialog */}
      <ConfirmationDialog
        open={dialogOpen}
        title={
          isUpdateConfirmation
            ? 'Confirm Update'
            : isCreateConfirmation
            ? 'Confirm Create'
            : 'Confirm Delete'
        }
        message={
          isUpdateConfirmation
            ? 'Are you sure you want to update this template?'
            : isCreateConfirmation
            ? 'Are you sure you want to create this template?'
            : 'Are you sure you want to delete this template?'
        }
        onConfirm={
          isUpdateConfirmation
            ? confirmUpdateTemplate
            : isCreateConfirmation
            ? confirmCreateTemplate
            : confirmDeleteTemplate
        }
        onCancel={() => setDialogOpen(false)} // Cancel action
      />
    </Box>
  );
};

export default TemplateManagement;
