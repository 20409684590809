import axios from 'axios';


const BASE_URL = process.env.REACT_APP_BASE_URL;

// const BASE_URL = "http://localhost:3000";

const loginService = async (mobileNumber: string, otp: string) => {
  try {
    const response = await axios.post(BASE_URL + '/api/v1/admin/login', { mobileNumber, otp });
    return response.data;
  } catch (error) {
    throw new Error('Login failed');
  }
};

export default loginService;
