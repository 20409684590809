import React, { useState, useEffect } from 'react';
import {
  Container,
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Box,
  MenuItem,
} from '@mui/material';
import { Add, Edit, Delete } from '@mui/icons-material';
import { getAllTaxes, createTax, updateTax, deleteTax } from '../../services/taxService';
import { ProductCategory, TaxType } from '../../enums/TaxEnums';

const TaxManagement: React.FC = () => {
  const [taxes, setTaxes] = useState<any[]>([]);
  const [showModal, setShowModal] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [currentTax, setCurrentTax] = useState<any>(null);
  const [taxName, setTaxName] = useState('');
  const [rate, setRate] = useState('');
  const [taxType, setTaxType] = useState<TaxType>(TaxType.SERVICE_TAX);
  const [productCategory, setProductCategory] = useState<ProductCategory | ''>('');

  // Fetch taxes on component load
  useEffect(() => {
    const fetchTaxes = async () => {
      try {
        const response = await getAllTaxes();
        setTaxes(response);
      } catch (error) {
        console.error('Error fetching taxes', error);
      }
    };
    fetchTaxes();
  }, []);

  const handleOpenModal = (tax?: any) => {
    if (tax) {
      setEditMode(true);
      setCurrentTax(tax);
      setTaxName(tax.name);
      setRate(tax.rate);
      setTaxType(tax.type);
      setProductCategory(tax.productCategory || '');
    } else {
      setEditMode(false);
      setCurrentTax(null);
      setTaxName('');
      setRate('');
      setTaxType(TaxType.SERVICE_TAX);
      setProductCategory('');
    }
    setShowModal(true);
  };

  const handleCloseModal = () => setShowModal(false);

  const handleSave = async (event: React.FormEvent) => {
    event.preventDefault();

    const taxData = {
      name: taxName,
      rate: parseFloat(rate),
      type: taxType,
      productCategory: productCategory || undefined,
    };

    try {
      if (editMode && currentTax) {
        await updateTax(currentTax._id, taxData);
      } else {
        await createTax(taxData);
      }
      setShowModal(false);
      const response = await getAllTaxes();
      setTaxes(response);
    } catch (error) {
      console.error('Error saving tax', error);
    }
  };

  const handleDelete = async (id: string) => {
    if (window.confirm('Are you sure you want to delete this tax?')) {
      try {
        await deleteTax(id);
        const response = await getAllTaxes();
        setTaxes(response);
      } catch (error) {
        console.error('Error deleting tax', error);
      }
    }
  };

  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        Manage Taxes
      </Typography>
      <Button
        variant="contained"
        color="primary"
        startIcon={<Add />}
        onClick={() => handleOpenModal()}
      >
        Add Tax
      </Button>

      <TableContainer component={Paper} sx={{ mt: 3 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Tax Name</TableCell>
              <TableCell>Rate (%)</TableCell>
              <TableCell>Tax Type</TableCell>
              <TableCell>Product Category</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {taxes.map((tax) => (
              <TableRow key={tax._id}>
                <TableCell>{tax.name}</TableCell>
                <TableCell>{tax.rate}</TableCell>
                <TableCell>{tax.type}</TableCell>
                <TableCell>{tax.productCategory || 'N/A'}</TableCell>
                <TableCell>
                  <IconButton
                    color="primary"
                    onClick={() => handleOpenModal(tax)}
                  >
                    <Edit />
                  </IconButton>
                  <IconButton
                    color="secondary"
                    onClick={() => handleDelete(tax._id)}
                  >
                    <Delete />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Modal for Adding/Editing Tax */}
      <Dialog open={showModal} onClose={handleCloseModal} fullWidth maxWidth="sm">
        <DialogTitle>{editMode ? 'Edit Tax' : 'Add Tax'}</DialogTitle>
        <DialogContent>
          <Box component="form" onSubmit={handleSave} noValidate>
            <TextField
              margin="normal"
              fullWidth
              label="Tax Name"
              value={taxName}
              onChange={(e) => setTaxName(e.target.value)}
              required
            />
            <TextField
              margin="normal"
              fullWidth
              label="Rate (%)"
              type="number"
              value={rate}
              onChange={(e) => setRate(e.target.value)}
              required
            />
            <TextField
              margin="normal"
              fullWidth
              label="Tax Type"
              select
              value={taxType}
              onChange={(e) => setTaxType(e.target.value as TaxType)}
              required
            >
              {Object.values(TaxType).map((type) => (
                <MenuItem key={type} value={type}>
                  {type}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              margin="normal"
              fullWidth
              label="Product Category"
              select
              value={productCategory}
              onChange={(e) => setProductCategory(e.target.value as ProductCategory)}
            >
              {Object.values(ProductCategory).map((category) => (
                <MenuItem key={category} value={category}>
                  {category}
                </MenuItem>
              ))}
            </TextField>
            <DialogActions>
              <Button onClick={handleCloseModal} color="secondary">
                Cancel
              </Button>
              <Button type="submit" color="primary">
                {editMode ? 'Save Changes' : 'Add Tax'}
              </Button>
            </DialogActions>
          </Box>
        </DialogContent>
      </Dialog>
    </Container>
  );
};

export default TaxManagement;
