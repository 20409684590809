import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Container, TextField, Button, Typography, Box, Divider } from "@mui/material";
import loginService from "../../services/loginService";
import { getAllHotels } from "../../services/hotelService";
import { setDataToLocalStorage, setHotelIdToLocalStorage } from "../../utils/localStorageUtils";

const Login: React.FC = () => {
  const [mobileNumber, setMobileNumber] = useState("");
  const [otp, setOtp] = useState("");
  const navigate = useNavigate();

  const handleLogin = async () => {
    try {
      const response = await loginService(mobileNumber, otp);
      const data = response;
      localStorage.clear();
      localStorage.setItem("userType", "user");
      localStorage.setItem("token", data.token);
      localStorage.setItem("user", JSON.stringify(data.user));

      const hotelList = await getAllHotels();
      if(hotelList.length>0){
        const hotelId = hotelList[0]._id;
        const isProduction = hotelList[0].isProduction?hotelList[0].isProduction:true;
        setHotelIdToLocalStorage(hotelId)
        setDataToLocalStorage('isProduction', isProduction)
      }

      navigate("/");

    } catch (error) {
      console.error("Login failed:", error);
    }
  };

  // const handleSignup = async () => {
  //   try {
  //     const response = await loginService(mobileNumber, otp);
  //     const data = response;
  //     localStorage.clear();
  //     localStorage.setItem("userType", "admin");
  //     localStorage.setItem("token", data.token);
  //     localStorage.setItem("user", JSON.stringify(data.user));

  //     navigate("/bills");
  //   } catch (error) {
  //     console.error("Signup failed:", error);
  //   }
  // };

  return (
    <Container maxWidth="sm" sx={{ mt: 8, boxShadow: 3, borderRadius: 2, p: 4, backgroundColor: "#fff" }}>
      {/* <Box mb={4}>
        <Typography variant="h4" align="center" gutterBottom>
          Signup
        </Typography>
        <TextField
          fullWidth
          margin="normal"
          label="Enter Name"
          variant="outlined"
        />
        <TextField
          fullWidth
          margin="normal"
          label="Enter Mobile"
          type="number"
          variant="outlined"
          value={mobileNumber}
          onChange={(e) => setMobileNumber(e.target.value)}
        />
        <TextField
          fullWidth
          margin="normal"
          label="Enter Password"
          type="password"
          variant="outlined"
        />
        <TextField
          fullWidth
          margin="normal"
          label="Enter OTP"
          variant="outlined"
          value={otp}
          onChange={(e) => setOtp(e.target.value)}
        />
        <Button
          fullWidth
          variant="contained"
          color="primary"
          sx={{ mt: 2 }}
          onClick={handleSignup}
        >
          Signup
        </Button>
      </Box> */}

      {/* <Divider sx={{ mb: 4 }} /> */}

      <Box>
        <Typography variant="h4" align="center" gutterBottom>
          Login
        </Typography>
        <TextField
          fullWidth
          margin="normal"
          label="Enter Mobile"
          type="number"
          variant="outlined"
          value={mobileNumber}
          onChange={(e) => setMobileNumber(e.target.value)}
        />
        <Button
          fullWidth
          variant="outlined"
          color="primary"
          sx={{ mt: 2 }}
          onClick={handleLogin}
        >
          Send OTP
        </Button>
        <TextField
          fullWidth
          margin="normal"
          label="Enter OTP"
          variant="outlined"
          value={otp}
          onChange={(e) => setOtp(e.target.value)}
        />
        <Button
          fullWidth
          variant="contained"
          color="primary"
          sx={{ mt: 2 }}
          onClick={handleLogin}
        >
          Login
        </Button>
      </Box>
    </Container>
  );
};

export default Login;
