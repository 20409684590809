import React, { useState, useEffect } from 'react';
import {
  Container,
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Tabs,
  Tab,
  Box,
  Paper,
  IconButton,
} from '@mui/material';
import { Add, Edit, Delete } from '@mui/icons-material';
import { getMenuItems, createMenuItem, deleteMenuItem, updateMenuItem } from '../../services/menuService';

enum MenuCategory {
  Appetizer = 'Appetizer',
  MainCourse = 'Main Course',
  Dessert = 'Dessert',
  Beverage = 'Beverage',
  Continental = 'Continental',
  Suffer = 'Suffer',
  Brunch = 'Brunch',
  Southern = 'Southern',
  Chinese = 'Chinese',
}

const MenuManagementPage: React.FC = () => {
  const [menuItems, setMenuItems] = useState<any[]>([]);
  const [showModal, setShowModal] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [currentItem, setCurrentItem] = useState<any>(null);
  const [name, setName] = useState('');
  const [price, setPrice] = useState(0);
  const [description, setDescription] = useState('');
  const [category, setCategory] = useState<MenuCategory>(MenuCategory.Appetizer);
  const [selectedTab, setSelectedTab] = useState(0);
  const hotelId = localStorage.getItem("selectedHotel");

  useEffect(() => {
    const fetchMenuItems = async () => {
      try {
        const response = await getMenuItems();
        setMenuItems(response);
      } catch (error) {
        console.error('Error fetching menu items', error);
      }
    };
    fetchMenuItems();
  }, []);

  const handleOpenModal = (item?: any) => {
    if (item) {
      setEditMode(true);
      setCurrentItem(item);
      setName(item.name);
      setPrice(item.price);
      setDescription(item.description);
      setCategory(item.category);
    } else {
      setEditMode(false);
      setCurrentItem(null);
      setName('');
      setPrice(0);
      setDescription('');
      setCategory(MenuCategory.Appetizer);
    }
    setShowModal(true);
  };

  const handleCloseModal = () => setShowModal(false);

  const handleSave = async (event: React.FormEvent) => {
    event.preventDefault();

    const menuItemData = {
      hotelId,
      name,
      price,
      category,
      description,
    };

    try {
      if (editMode) {
        await updateMenuItem(currentItem._id, menuItemData);
      } else {
        await createMenuItem(menuItemData);
      }
      setShowModal(false);
      const response = await getMenuItems();
      setMenuItems(response);
    } catch (error) {
      console.error('Error saving menu item', error);
    }
  };

  const handleDelete = async (id: string) => {
    if (window.confirm('Are you sure you want to delete this item?')) {
      try {
        await deleteMenuItem(id);
        const response = await getMenuItems();
        setMenuItems(response);
      } catch (error) {
        console.error('Error deleting menu item', error);
      }
    }
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  const getUniqueCategories = () => {
    const categories = menuItems.map(item => item.category);
    return Array.from(new Set(categories));  // Get unique categories
  };

  const categories = getUniqueCategories();

  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        Manage Menu Items
      </Typography>
      <Button
        variant="contained"
        color="primary"
        startIcon={<Add />}
        onClick={() => handleOpenModal()}
      >
        Add Menu Item
      </Button>

      {/* Tabs for Categories */}
      <Box sx={{ mt: 3 }}>
        <Tabs value={selectedTab} onChange={handleTabChange} indicatorColor="primary" textColor="primary">
          {categories.map((category, index) => (
            <Tab key={category} label={category} />
          ))}
        </Tabs>

        {/* Render Menu Items based on selected tab */}
        {categories.map((category, index) => (
          <Box
            key={category}
            role="tabpanel"
            hidden={selectedTab !== index}
            id={`tabpanel-${index}`}
            aria-labelledby={`tab-${index}`}
            sx={{ mt: 2 }}
          >
            {selectedTab === index && (
              <Box>
                {menuItems
                  .filter(item => item.category === category)
                  .map((item, itemIndex) => (
                    <Paper key={item._id} sx={{ mb: 2, p: 2 }}>
                      <Box display="flex" justifyContent="space-between" alignItems="center">
                        <Box>
                          <Typography variant="subtitle1">{itemIndex + 1}. {item.name}</Typography>
                          <Typography variant="body2">₹{item.price.toFixed(2)}</Typography>
                          <Typography variant="body2">{item.description}</Typography>
                        </Box>
                        <Box>
                          <IconButton color="primary" onClick={() => handleOpenModal(item)}>
                            <Edit />
                          </IconButton>
                          <IconButton color="secondary" onClick={() => handleDelete(item._id)}>
                            <Delete />
                          </IconButton>
                        </Box>
                      </Box>
                    </Paper>
                  ))}
              </Box>
            )}
          </Box>
        ))}
      </Box>

      {/* Modal for Adding/Editing Menu Item */}
      <Dialog open={showModal} onClose={handleCloseModal} fullWidth maxWidth="sm">
        <DialogTitle>{editMode ? 'Edit Menu Item' : 'Add Menu Item'}</DialogTitle>
        <DialogContent>
          <Box component="form" onSubmit={handleSave} noValidate>
            <TextField
              margin="normal"
              fullWidth
              label="Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
            <TextField
              margin="normal"
              fullWidth
              label="Price"
              type="number"
              value={price}
              onChange={(e) => setPrice(parseFloat(e.target.value))}
              required
            />
            <FormControl fullWidth margin="normal" required>
              <InputLabel>Category</InputLabel>
              <Select
                value={category}
                onChange={(e) => setCategory(e.target.value as MenuCategory)}
                label="Category"
              >
                {Object.values(MenuCategory).map((cat) => (
                  <MenuItem key={cat} value={cat}>
                    {cat}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              margin="normal"
              fullWidth
              label="Description"
              multiline
              rows={4}
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
            <DialogActions>
              <Button onClick={handleCloseModal} color="secondary">
                Cancel
              </Button>
              <Button type="submit" color="primary">
                {editMode ? 'Save Changes' : 'Add Menu Item'}
              </Button>
            </DialogActions>
          </Box>
        </DialogContent>
      </Dialog>
    </Container>
  );
};

export default MenuManagementPage;
