import React, { useEffect, useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Box,
  Divider,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@mui/material';
import { createPayment, getPaymentsByMobile } from '../../services/paymentService';
import { getBillsByMobileNumber } from '../../services/billService';
import { getRoomBillsByMobileNumber } from '../../services/roomBillingService';

interface PaymentLedgerProps {
  mobileNumber: string; // Pass the mobile number of the customer
}

const PaymentLedger: React.FC<PaymentLedgerProps> = ({ mobileNumber }) => {
  const [payments, setPayments] = useState<any[]>([]);
  const [normalBills, setNormalBills] = useState<any[]>([]);
  const [roomBills, setRoomBills] = useState<any[]>([]);
  const [amount, setAmount] = useState<number>(0);
  const [referenceNumber, setReferenceNumber] = useState<string>('');
  const [method, setMethod] = useState<string>(''); // e.g., credit card, cash
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false); // State for input dialog

  useEffect(() => {
    const fetchPayments = async () => {
      const paymentResponse = await getPaymentsByMobile(mobileNumber); // Fetch payments by mobile number
      setPayments(paymentResponse);
    };

    const fetchNormalBills = async () => {
      const billResponse = await getBillsByMobileNumber(mobileNumber);
      setNormalBills(billResponse);
    };

    const fetchRoomBills = async () => {
      const roomBillResponse = await getRoomBillsByMobileNumber(mobileNumber); // Fetch room bills by mobile number
      setRoomBills(roomBillResponse);
    };

    if (mobileNumber) {
      fetchPayments();
      fetchNormalBills();
      fetchRoomBills();
    }
  }, [mobileNumber]);

  const totalPayments = payments.reduce((acc, payment) => acc + payment.amount, 0);
  const totalNormalBills = normalBills.reduce((acc, bill) => acc + bill.totalAmount, 0); // Assuming `totalAmount` is the field in normal bill
  const totalRoomBills = roomBills.reduce((acc, bill) => acc + bill.totalAmount, 0); // Assuming `totalAmount` is the field in room bill

  const handleOpenDialog = () => {
    setIsDialogOpen(true); // Open the dialog for payment input
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false); // Close the dialog without saving
  };

  const handleSavePayment = async () => {
    const paymentData = { amount, referenceNumber, method, mobileNumber };
    await createPayment(paymentData);
    // Refresh the payment list after saving
    const paymentResponse = await getPaymentsByMobile(mobileNumber);
    setPayments(paymentResponse);
    // Reset fields after payment
    setAmount(0);
    setReferenceNumber('');
    setMethod('');
    handleCloseDialog(); // Close the dialog after saving
  };

  return (
    <div>
      <Typography variant="h5" color="primary" sx={{ fontWeight: 'bold' }}>Payment Ledger</Typography>
      <Divider sx={{ mb: 2 }} />

      <Box sx={{ mb: 2 }}>
        <Typography variant="h6" color="success.main">Total Credits: ₹{totalPayments.toFixed(2)}</Typography>
        <Typography variant="h6" color="error.main">Total Normal Debits: ₹{totalNormalBills.toFixed(2)}</Typography>
        <Typography variant="h6" color="error.main">Total Room Debits: ₹{totalRoomBills.toFixed(2)}</Typography>
        <Typography variant="h6" color="primary">Balance: ₹{(totalPayments - totalNormalBills - totalRoomBills).toFixed(2)}</Typography>
      </Box>

      <TableContainer component={Paper} elevation={3}>
        <Table>
          <TableHead sx={{ backgroundColor: '#f5f5f5' }}>
            <TableRow>
              <TableCell sx={{ fontWeight: 'bold' }}>Type</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>Amount</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>Reference Number</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>Method</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>Date</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {payments.map((payment) => (
              <TableRow key={payment._id} sx={{ backgroundColor: '#e0f7fa' }}>
                <TableCell>Credit</TableCell> {/* For payments */}
                <TableCell>{payment.amount.toFixed(2)}</TableCell>
                <TableCell>{payment.referenceNumber}</TableCell>
                <TableCell>{payment.method}</TableCell>
                <TableCell>{new Date(payment.createdAt).toLocaleDateString()}</TableCell>
              </TableRow>
            ))}
            {normalBills.map((bill) => (
              <TableRow key={bill._id} sx={{ backgroundColor: '#ffe0b2' }}>
                <TableCell>Debit (Normal Bill)</TableCell>
                <TableCell>{bill.totalAmount.toFixed(2)}</TableCell>
                <TableCell>{bill.referenceNumber || 'N/A'}</TableCell>
                <TableCell>{'N/A'}</TableCell>
                <TableCell>{new Date(bill.createdAt).toLocaleDateString()}</TableCell>
              </TableRow>
            ))}
            {roomBills.map((bill) => (
              <TableRow key={bill._id} sx={{ backgroundColor: '#ffe0b2' }}>
                <TableCell>Debit (Room Bill)</TableCell>
                <TableCell>{bill.totalAmount.toFixed(2)}</TableCell>
                <TableCell>{bill.referenceNumber || 'N/A'}</TableCell>
                <TableCell>{'N/A'}</TableCell>
                <TableCell>{new Date(bill.createdAt).toLocaleDateString()}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Box sx={{ mt: 3 }}>
        <Button variant="contained" color="primary" onClick={handleOpenDialog}>
          Add Payment
        </Button>
      </Box>

      {/* Payment Input Dialog */}
      <Dialog open={isDialogOpen} onClose={handleCloseDialog}>
        <DialogTitle>Add Payment</DialogTitle>
        <DialogContent>
          <TextField
            label="Amount"
            type="number"
            value={amount}
            onChange={(e) => setAmount(parseFloat(e.target.value))}
            fullWidth
            margin="normal"
            required
          />
          <TextField
            label="Reference Number"
            value={referenceNumber}
            onChange={(e) => setReferenceNumber(e.target.value)}
            fullWidth
            margin="normal"
            required
          />
          <TextField
            label="Method"
            value={method}
            onChange={(e) => setMethod(e.target.value)}
            fullWidth
            margin="normal"
            required
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="secondary">Cancel</Button>
          <Button onClick={handleSavePayment} color="primary">Save</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default PaymentLedger;
