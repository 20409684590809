import React, { useEffect, useState } from 'react';
import { Container, Typography, Tabs, Tab, Box } from '@mui/material';
import RoomList from '../components/Rooms/RoomList';
import RoomForm from '../components/Rooms/RoomForm';

const RoomPage: React.FC = () => {
  const [value, setValue] = useState<string>('list');


  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  return (
    <Container>
      <Box>
        <Typography variant="h4" gutterBottom>
          Manage Rooms
        </Typography>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="room and billing tabs"
          sx={{ mb: 2 }}
        >
          <Tab label="Room List" value="list" />
          <Tab label="Add Room" value="form" />
        </Tabs>
        <Box>
          {value === 'list' && <RoomList />}
          {value === 'form' && <RoomForm />}
        </Box>
      </Box>
    </Container>
  );
};

export default RoomPage;
