import { getHotelIdFromLocalStorage } from '../utils/localStorageUtils';
import api from './api';

// Get all room billings for the current hotel
export const getRoomBillings = async (page: number = 1, count: number = 10, filterData: any = {}) => {
  const hotelId = getHotelIdFromLocalStorage();

  try {
    const response = await api.get('/room-billings', {
      params: {
        hotelId,
        page,
        count,
        ...filterData, // Spread filterData to include additional filters
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching room billings', error);
    throw error; // Re-throw error to be handled by the calling code
  }
};


// Get all room billings for the current hotel
export const getAllRoomBillings = async (filterData: any = {}) => {
  const hotelId = getHotelIdFromLocalStorage();

  try {
    const response = await api.get('/room-billings/getAllRoomBills', {
      params: {
        hotelId,
        ...filterData, // Spread filterData to include additional filters
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching room billings', error);
    throw error; // Re-throw error to be handled by the calling code
  }
};

// Get a specific room billing by ID for the current hotel
export const getRoomBillingById = async (id: string) => {
  const hotelId = getHotelIdFromLocalStorage();

  try {
    const response = await api.get(`/room-billings/${id}`, {
      params: { hotelId },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching room billing details', error);
    throw error;
  }
};

// Create a new room billing for the current hotel
export const createRoomBilling = async (data: any) => {
  const hotelId = getHotelIdFromLocalStorage();

  try {
    const response = await api.post('/room-billings', { ...data, hotelId });
    return response.data;
  } catch (error) {
    console.error('Error creating room billing', error);
    throw error;
  }
};

// Update an existing room billing by ID for the current hotel
export const updateRoomBilling = async (id: string, data: any) => {
  const hotelId = getHotelIdFromLocalStorage();

  try {
    const response = await api.put(`/room-billings/${id}`, { ...data, hotelId });
    return response.data;
  } catch (error) {
    console.error('Error updating room billing', error);
    throw error;
  }
};

// Delete a room billing by ID for the current hotel
export const deleteRoomBilling = async (id: string) => {
  const hotelId = getHotelIdFromLocalStorage();

  try {
    const response = await api.delete(`/room-billings/${id}`, {
      params: { hotelId },
    });
    return response.data;
  } catch (error) {
    console.error('Error deleting room billing', error);
    throw error;
  }
};


// Generate PDF for a specific room billing by ID
export const generateRoomBillingPdf = async (id: string) => {
    
    try {
      const response = await api.get(`/room-billings/${id}/pdf`, {
        responseType: 'blob', // Set response type to blob for PDF files
      });
      return response.data;
    } catch (error) {
      console.error('Error generating room billing PDF', error);
      throw error;
    }
  };


  export const generateRoomBillingPdfFromHtml = async (id: string) => {
    try {
      const response = await api.get(`/room-billings/${id}/pdfFromHtml`, {
        responseType: 'blob', // PDF as blob
      });
      return response.data;
    } catch (error) {
      console.error('Error generating room billing PDF', error);
      throw error;
    }
  };