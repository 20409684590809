import React from 'react';
import './Tooltip.css'; // Import the CSS file

interface TooltipProps {
  children: React.ReactNode;
  visible: boolean;
  position: { top: number; left: number };
}

const Tooltip: React.FC<TooltipProps> = ({ children, visible, position }) => {
  return (
    <div
      className={`tooltip ${visible ? 'visible' : ''}`}
      style={{ top: position.top, left: position.left }}
    >
      {children}
    </div>
  );
};

export default Tooltip;
