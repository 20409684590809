import { getHotelIdFromLocalStorage } from '../utils/localStorageUtils';
import api from './api';

// Create a new supplier
export const createSupplier = async (data: any) => {
  const hotelId = getHotelIdFromLocalStorage();

  if (!hotelId) throw new Error('No hotel selected');

  try {
    const response = await api.post('/suppliers', { ...data, hotelId });
    return response.data;
  } catch (error) {
    console.error('Error creating supplier', error);
    throw error;
  }
};

// Fetch all suppliers for a specific hotel
export const getAllSuppliers = async () => {
  const hotelId = getHotelIdFromLocalStorage();

  if (!hotelId) throw new Error('No hotel selected');

  try {
    const response = await api.get(`/suppliers?hotelId=${hotelId}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching suppliers', error);
    throw error;
  }
};

// Get a supplier by ID
export const getSupplierById = async (id: string) => {
  try {
    const response = await api.get(`/suppliers/${id}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching supplier details', error);
    throw error;
  }
};

// Update a supplier by ID
export const updateSupplier = async (id: string, data: any) => {
  try {
    const response = await api.put(`/suppliers/${id}`, data);
    return response.data;
  } catch (error) {
    console.error('Error updating supplier', error);
    throw error;
  }
};

// Delete a supplier by ID
export const deleteSupplier = async (id: string) => {
  try {
    const response = await api.delete(`/suppliers/${id}`);
    return response.data;
  } catch (error) {
    console.error('Error deleting supplier', error);
    throw error;
  }
};
