import React, { ReactNode } from 'react';
import { Box, CssBaseline, Toolbar } from '@mui/material';
import Header from './Header';
import SideMenu from './SideMenu';
import Footer from './Footer';

interface MainLayoutProps {
  children: ReactNode;
}

const drawerWidth = 240; // Width of the sidebar

const MainLayout: React.FC<MainLayoutProps> = ({ children }) => {
  return (
    <Box sx={{ display: 'flex', minHeight: '100vh', flexDirection: 'column' }}>
      <CssBaseline />
      <Header />
      <Box sx={{ display: 'flex', flexGrow: 1 }}>
        <SideMenu />
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            bgcolor: 'background.default',
            p: 3,
            // ml: `${drawerWidth}px`, // Main content margin to account for sidebar
            display: 'flex',
            flexDirection: 'column',
            minHeight: '100vh',
          }}
        >
          <Toolbar />
          <Box sx={{ flexGrow: 1 }}>{children}</Box> {/* Content goes here */}
        </Box>
      </Box>
      <Box sx={{ ml: `${drawerWidth}px` }}> {/* Make sure footer spans under sidebar */}
        <Footer />
      </Box>
    </Box>
  );
};

export default MainLayout;
