import { getHotelIdFromLocalStorage } from '../utils/localStorageUtils';
import api from './api';

// Create a new reservation for a specific hotel
export const createReservation = async (reservationData: any) => {
  const hotelId = getHotelIdFromLocalStorage();
  if (!hotelId) throw new Error('No hotel selected');

  try {
    const response = await api.post('/reservations', { ...reservationData, hotelId });
    return response.data;
  } catch (error) {
    console.error('Error creating reservation', error);
    throw error;
  }
};

// Check room availability for a specific hotel
export const checkRoomAvailability = async (availabilityData: any) => {
  const hotelId = getHotelIdFromLocalStorage();
  if (!hotelId) throw new Error('No hotel selected');

  try {
    const response = await api.post('/reservations/check-availability', { ...availabilityData, hotelId });
    return response.data;
  } catch (error) {
    console.error('Error checking room availability', error);
    throw error;
  }
};

// Get all reservations for a specific hotel
export const getReservations = async (page: number = 1, count: number = 10, filterData: any = {}) => {
  const hotelId = getHotelIdFromLocalStorage();
  if (!hotelId) throw new Error('No hotel selected');

  try {
    const response = await api.get('/reservations', {
      params: {
        hotelId,
        page,
        count,
        ...filterData, // Include any additional filter data
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching reservations', error);
    throw error;
  }
};

// Get a specific reservation by ID
export const getReservationById = async (id: string) => {
  const hotelId = getHotelIdFromLocalStorage();
  if (!hotelId) throw new Error('No hotel selected');

  try {
    const response = await api.get(`/reservations/${id}`, {
      params: { hotelId },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching reservation details', error);
    throw error;
  }
};

// Cancel a reservation for a specific hotel
export const cancelReservation = async (id: string) => {
  const hotelId = getHotelIdFromLocalStorage();
  if (!hotelId) throw new Error('No hotel selected');

  try {
    const response = await api.put(`/reservations/${id}/cancel`, { hotelId });
    return response.data;
  } catch (error) {
    console.error('Error cancelling reservation', error);
    throw error;
  }
};

// Search for reservations by customer name or other criteria
export const searchReservations = async (query: string, filterData: any = {}) => {
  const hotelId = getHotelIdFromLocalStorage();
  if (!hotelId) throw new Error('No hotel selected');

  try {
    const response = await api.get('/reservations/search', {
      params: {
        q: query,
        hotelId,
        ...filterData, // Include any additional filter data
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error searching reservations', error);
    throw error;
  }
};
