export const getHotelIdFromLocalStorage = () => {
    const selectedHotelId = localStorage.getItem('selectedHotel');
    return selectedHotelId || ''; // Return an empty string if no hotel ID is found
  };


  export const setHotelIdToLocalStorage = (hotelId:string) => {
    localStorage.setItem('selectedHotel', hotelId);
  };



  export const getDataFromLocalStorage = (key:string) => {
    const selectedHotelId = localStorage.getItem(key);
    return selectedHotelId || ''; // Return an empty string if no hotel ID is found
  };


  export const setDataToLocalStorage = (key:string, hotelId:string) => {
    localStorage.setItem(key, hotelId);
  };

