import React, { useState } from 'react';
import { Drawer, List, ListItem, ListItemIcon, ListItemText, Toolbar, Collapse } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import {
  Home as HomeIcon,
  Restaurant as RestaurantIcon,
  MenuBook as MenuBookIcon,
  People as PeopleIcon,
  Receipt as ReceiptIcon,
  AttachMoney as AttachMoneyIcon,
  Summarize as SummarizeIcon,
  ExitToApp as ExitToAppIcon,
  AdminPanelSettings as AdminPanelSettingsIcon,
  Business as BusinessIcon,
  Person as PersonIcon,
  AccountBalance as AccountBalanceIcon,
  FileCopy as FileCopyIcon,
  Store as StoreIcon,
  ExpandLess,
  ExpandMore,
  AssignmentInd as AssignmentIndIcon,
  MonetizationOn as MonetizationOnIcon,
  TableRestaurant as TableRestaurantIcon,
} from '@mui/icons-material';

const drawerWidth = 240;

const SideMenu: React.FC = () => {
  const location = useLocation();
  const [openSection, setOpenSection] = useState<string | null>(null);

  const handleToggle = (section: string) => {
    setOpenSection(openSection === section ? null : section);
  };

  const renderListItem = (to: string, text: string, icon: React.ReactNode) => (
    <ListItem
      button
      component={Link}
      to={to}
      selected={location.pathname === to}
      sx={{
        pl: 4,
        '&.Mui-selected': { backgroundColor: '#1565c0', color: '#fff' },
        '&:hover': {
          backgroundColor: '#1e88e5', // Lighten on hover
          color: '#fff',
        },
      }}
    >
      <ListItemIcon>{icon}</ListItemIcon>
      <ListItemText primary={text} primaryTypographyProps={{ fontWeight: 'medium' }} />
    </ListItem>
  );

  return (
    <Drawer
      variant="permanent"
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: drawerWidth,
          boxSizing: 'border-box',
          backgroundColor: '#2C3E50',
          color: '#fff',
        },
      }}
    >
      <Toolbar />
      <List>
        {/* Home */}
        {renderListItem("/", "Dashboard", <HomeIcon sx={{ color: '#fff' }} />)}

        {/* Restaurant Section */}
        {renderListItem("/bills", "Dining Services", <RestaurantIcon sx={{ color: '#fff' }} />)}

        {/* Guest & Room Operations */}
        <ListItem button onClick={() => handleToggle('guestRoomOperations')}>
          <ListItemIcon>
            <PeopleIcon sx={{ color: '#fff' }} />
          </ListItemIcon>
          <ListItemText primary="Guest & Room Operations" />
          {openSection === 'guestRoomOperations' ? <ExpandLess sx={{ color: '#fff' }} /> : <ExpandMore sx={{ color: '#fff' }} />}
        </ListItem>
        <Collapse in={openSection === 'guestRoomOperations'} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {renderListItem("/guest-registration", "Client Check-In", <AssignmentIndIcon sx={{ color: '#fff' }} />)}
            {renderListItem("/checkedin-customers", "Active Stays", <PeopleIcon sx={{ color: '#fff' }} />)}
            {renderListItem("/checkedout-customers", "Completed Stays", <ExitToAppIcon sx={{ color: '#fff' }} />)}
            {renderListItem("/manage-reservations", "Reservation Control", <ReceiptIcon sx={{ color: '#fff' }} />)}
            {renderListItem("/room-availability", "Room Availability", <ReceiptIcon sx={{ color: '#fff' }} />)}
            {renderListItem("/room-billings", "Room Billing List", <ReceiptIcon sx={{ color: '#fff' }} />)}
            {renderListItem("/customer-billings", "Customer Billing", <ReceiptIcon sx={{ color: '#fff' }} />)}
          </List>
        </Collapse>

        {/* Finance Section */}
        <ListItem button onClick={() => handleToggle('finance')}>
          <ListItemIcon>
            <AttachMoneyIcon sx={{ color: '#fff' }} />
          </ListItemIcon>
          <ListItemText primary="Financial Management" />
          {openSection === 'finance' ? <ExpandLess sx={{ color: '#fff' }} /> : <ExpandMore sx={{ color: '#fff' }} />}
        </ListItem>
        <Collapse in={openSection === 'finance'} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {renderListItem("/gst-report", "GST Reporting", <SummarizeIcon sx={{ color: '#fff' }} />)}
            {renderListItem("/manage-expenses", "Expense Management", <MonetizationOnIcon sx={{ color: '#fff' }} />)}
            {renderListItem("/manage-inventory", "Inventory Management", <MonetizationOnIcon sx={{ color: '#fff' }} />)}
            {renderListItem("/manage-suppliers", "Supplier Management", <MonetizationOnIcon sx={{ color: '#fff' }} />)}
            {renderListItem("/upload-purchases", "Upload Purchases", <MonetizationOnIcon sx={{ color: '#fff' }} />)}
          </List>
        </Collapse>

        {/* Management Section */}
        <ListItem button onClick={() => handleToggle('management')}>
          <ListItemIcon>
            <AdminPanelSettingsIcon sx={{ color: '#fff' }} />
          </ListItemIcon>
          <ListItemText primary="Admin & Settings" />
          {openSection === 'management' ? <ExpandLess sx={{ color: '#fff' }} /> : <ExpandMore sx={{ color: '#fff' }} />}
        </ListItem>
        <Collapse in={openSection === 'management'} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {renderListItem("/menu-management", "Menu Management", <MenuBookIcon sx={{ color: '#fff' }} />)}
            {renderListItem("/rooms", "Room Setup", <BusinessIcon sx={{ color: '#fff' }} />)}
            {renderListItem("/manage-tables", "Table Management", <TableRestaurantIcon sx={{ color: '#fff' }} />)}
            {renderListItem("/manage-customers", "Client Directory", <PersonIcon sx={{ color: '#fff' }} />)}
            {renderListItem("/manage-admins", "Admin Roles", <AdminPanelSettingsIcon sx={{ color: '#fff' }} />)}
            {renderListItem("/manage-taxes", "Tax Settings", <AccountBalanceIcon sx={{ color: '#fff' }} />)}
            {renderListItem("/manage-templates", "Template Manager", <FileCopyIcon sx={{ color: '#fff' }} />)}
            {renderListItem("/manage-hotels", "Hotel Configurations", <StoreIcon sx={{ color: '#fff' }} />)}
          </List>
        </Collapse>
      </List>
    </Drawer>
  );
};

export default SideMenu;
