import { getHotelIdFromLocalStorage } from "../utils/localStorageUtils";
import api from "./api";

// Get all bills for the current hotel with pagination and filtering
export const getAllPayments = async (filterData: any = {}) => {
    const hotelId = getHotelIdFromLocalStorage();
    console.log({hotelId})
  
    try {
      const response = await api.get('/payments', {
        params: {
          hotelId,
          ...filterData, // Spread filterData to include additional filters
        },
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching payments', error);
      throw error; // Re-throw error to be handled by the calling code
    }
  };


  export const createPayment = async (paymentData: any) => {
    const hotelId = getHotelIdFromLocalStorage();
    console.log({ hotelId });

    try {
        const response = await api.post('/payments', {
            ...paymentData,
            hotelId,
        });

        // Check for successful response
        if (response.status === 201) {
            return response.data; // Payment created successfully
        } else {
            console.error(`Unexpected response status: ${response.status}`);
            throw new Error('Failed to create payment');
        }
    } catch (error) {
        console.error('Error creating payment', error);
        throw error; // Re-throw error to be handled by the calling code
    }
};


// Get payments associated with a specific bill
export const getPaymentsByBillId = async (billId: string) => {
    try {
        const response = await api.get('/payments/getPaymentsByReference', {
            params: {
                billId,
            },
        });

        // Ensure the response data is properly formatted
        if (response.status === 200) {
            return response.data; // Return payments related to the bill
        } else {
            console.error(`Unexpected response status: ${response.status}`);
            throw new Error('Unexpected response status');
        }
    } catch (error) {
        console.error('Error fetching payments by bill ID', error);
        throw error; // Re-throw error to be handled by the calling code
    }
};