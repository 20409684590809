import { getHotelIdFromLocalStorage } from '../utils/localStorageUtils';
import api from './api';

// Get all rooms for a specific hotel
export const getRooms = async () => {
  const hotelId = getHotelIdFromLocalStorage();
  
  try {
    const response = await api.get('/rooms', { params: { hotelId } });
    return response.data;
  } catch (error) {
    console.error('Error fetching rooms', error);
    throw error;
  }
};

// Get a specific room by ID for a specific hotel
export const getRoomById = async (id: string) => {
  const hotelId = getHotelIdFromLocalStorage();
  
  try {
    const response = await api.get(`/rooms/${id}`, { params: { hotelId } });
    return response.data;
  } catch (error) {
    console.error('Error fetching room by ID', error);
    throw error;
  }
};

// Create a new room for a specific hotel
export const createRoom = async (data: any) => {
  const hotelId = getHotelIdFromLocalStorage();
  
  try {
    const response = await api.post('/rooms', { ...data, hotelId });
    return response.data;
  } catch (error) {
    console.error('Error creating room', error);
    throw error;
  }
};

// Update an existing room for a specific hotel
export const updateRoom = async (id: string, data: any) => {
  const hotelId = getHotelIdFromLocalStorage();
  
  try {
    const response = await api.put(`/rooms/${id}`, { ...data, hotelId });
    return response.data;
  } catch (error) {
    console.error('Error updating room', error);
    throw error;
  }
};

// Delete a room for a specific hotel
export const deleteRoom = async (id: string) => {
  const hotelId = getHotelIdFromLocalStorage();
  
  try {
    const response = await api.delete(`/rooms/${id}`, { params: { hotelId } });
    return response.data;
  } catch (error) {
    console.error('Error deleting room', error);
    throw error;
  }
};
