import React, { useState, useEffect } from "react";
import {
  Container,
  Typography,
  Paper,
  CircularProgress,
  Box,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Snackbar,
  Alert,
} from "@mui/material";
import { checkOutCustomer, getAllCheckins } from "../../services/customerService";

const CheckedInCustomers: React.FC = () => {
  const [customers, setCustomers] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [selectedCustomer, setSelectedCustomer] = useState<any>(null);
  const [checkOutDate, setCheckOutDate] = useState<string>("");
  const [checkOutTime, setCheckOutTime] = useState<string>("");
  const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
  const [snackbarMessage, setSnackbarMessage] = useState<string>("");
  const [snackbarSeverity, setSnackbarSeverity] = useState<"success" | "error">("success");

  const getDefaultCheckoutDate = () => new Date().toISOString().split("T")[0];
  const getDefaultCheckoutTime = () => new Date().toTimeString().split(":").slice(0, 2).join(":");


  useEffect(() => {
    const fetchCheckedInCustomers = async () => {
      try {
        const data = await getAllCheckins();
        setCustomers(data);
      } catch (error) {
        console.error("Error fetching checked-in customers", error);
        setSnackbarMessage("Error fetching customers");
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
      } finally {
        setLoading(false);
      }
    };

    fetchCheckedInCustomers();
  }, []);

  const handleOpenDialog = (customer: any) => {
    setSelectedCustomer(customer);
    setCheckOutDate(getDefaultCheckoutDate());
    setCheckOutTime(getDefaultCheckoutTime());
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedCustomer(null);
    setCheckOutDate("");
    setCheckOutTime("");
  };

  const handleCheckout = async () => {
    if (!selectedCustomer) return;

    try {
      const response = await checkOutCustomer(
        selectedCustomer._id,
        checkOutDate,
        checkOutTime
      );

      if (response) {
        setSnackbarMessage(`${response.message}`);
        setSnackbarSeverity("success");
        setSnackbarOpen(true);
        handleCloseDialog();
        // Refresh the list of checked-in customers
        const data = await getAllCheckins();
        setCustomers(data);
      } else {
        setSnackbarMessage(`Error: ${response.message}`);
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
      }
    } catch (error) {
      console.error("Error checking out customer", error);
      setSnackbarMessage("An error occurred while checking out the customer.");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Container maxWidth="md" sx={{ mt: 4 }}>
      <Typography variant="h4" gutterBottom align="center">
        Checked-In Customers
      </Typography>
      <Paper sx={{ p: 2 }}>
        <List>
          {customers.map((customer) => (
            <ListItem key={customer._id} divider>
              <ListItemText
                primary={customer.customerName}
                secondary={`Room: ${customer.roomId?.name || 'N/A'} | Check-In: ${new Date(customer.checkInDate).toLocaleDateString()} at ${customer.checkInTime}`}
              />
              <ListItemSecondaryAction>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => handleOpenDialog(customer)}
                  sx={{ textTransform: 'none' }}
                >
                  Check Out
                </Button>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      </Paper>

      {/* Checkout Dialog */}
      <Dialog open={openDialog} onClose={handleCloseDialog} maxWidth="sm" fullWidth>
        <DialogTitle>Check Out</DialogTitle>
        <DialogContent>
          <TextField
            label="Check-Out Date"
            type="date"
            fullWidth
            value={checkOutDate}
            onChange={(e) => setCheckOutDate(e.target.value)}
            InputLabelProps={{ shrink: true }}
            required
            margin="normal"
          />
          <TextField
            label="Check-Out Time"
            type="time"
            fullWidth
            value={checkOutTime}
            onChange={(e) => setCheckOutTime(e.target.value)}
            InputLabelProps={{ shrink: true }}
            required
            margin="normal"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleCheckout} color="secondary">
            Check Out
          </Button>
        </DialogActions>
      </Dialog>

      {/* Snackbar for feedback */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default CheckedInCustomers;
