import React, { useEffect, useState } from "react";
import {
  Container,
  Typography,
  TextField,
  Button,
  Grid,
  MenuItem,
  Checkbox,
  FormControl,
  InputLabel,
  Select,
  FormHelperText,
  Box,
  ListItemText,
} from "@mui/material";
import { IdTypes } from "../../enums/IdTypes";
import { checkInGuest, getCustomerByMobileNumber } from "../../services/customerService";
import { getRooms } from "../../services/roomService";
import SnackbarComponent from "../Shared/SnackbarComponent";

const GuestRegistrationForm: React.FC = () => {
  const [guestName, setGuestName] = useState("");
  const [address, setAddress] = useState("");
  const [mobileNo, setMobileNo] = useState("");
  const [idType, setIdType] = useState<IdTypes | "">("");
  const [idNumber, setIdNumber] = useState("");
  const [comingFrom, setComingFrom] = useState("");
  const [goingTo, setGoingTo] = useState("");
  const [noOfPersons, setNoOfPersons] = useState<number>(0);
  const [noOfMales, setNoOfMales] = useState<number>(0);
  const [noOfFemales, setNoOfFemales] = useState<number>(0);
  const [noOfChildren, setNoOfChildren] = useState<number>(0);
  
  // Set default check-in date and time
    const getDefaultCheckinDate = () => new Date().toISOString().split("T")[0];
    const getDefaultCheckinTime = () => new Date().toTimeString().split(":").slice(0, 2).join(":");

    const [checkinDate, setCheckinDate] = useState<string>(getDefaultCheckinDate());
    const [checkinTime, setCheckinTime] = useState<string>(getDefaultCheckinTime());
// const [checkinDate, setCheckinDate] = useState<string>("");
//   const [checkinTime, setCheckinTime] = useState<string>("");
  const [checkoutDate, setCheckoutDate] = useState<string>("");
  const [checkoutTime, setCheckoutTime] = useState<string>("");

  const [rooms, setRooms] = useState<any[]>([]);
  const [selectedRooms, setSelectedRooms] = useState<string[]>([]);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState<"success" | "error">("success");

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  useEffect(() => {
    const fetchRooms = async () => {
      try {
        const roomsData = await getRooms();
        setRooms(roomsData);
      } catch (error) {
        console.error("Error fetching rooms:", error);
      }
    };

    fetchRooms();
  }, []);

  const handleMobileNoBlur = async () => {
    if (mobileNo.length === 10) {
      try {
        const customerData = await getCustomerByMobileNumber(mobileNo);
        if (customerData) {
          setGuestName(customerData.customerName || "");
          setAddress(customerData.address || "");
          setIdType(customerData.idType || "");
          setIdNumber(customerData.idNumber || "");
          setComingFrom(customerData.comingFrom || "");
          setGoingTo(customerData.goingTo || "");
          setNoOfPersons(customerData.noOfPersons || 0);
          setNoOfMales(customerData.noOfMales || 0);
          setNoOfFemales(customerData.noOfFemales || 0);
          setNoOfChildren(customerData.noOfChildren || 0);
        }
      } catch (error) {
        console.error("Error fetching customer details:", error);
      }
    }
  };

  const handleRoomSelection = (event: React.ChangeEvent<HTMLInputElement>) => {
    const roomId = event.target.value;
    setSelectedRooms((prevSelectedRooms) =>
      prevSelectedRooms.includes(roomId)
        ? prevSelectedRooms.filter((id) => id !== roomId)
        : [...prevSelectedRooms, roomId]
    );
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    if (!guestName || !mobileNo || !idType || !idNumber || !checkinDate || !checkinTime) {
      setSnackbarMessage("Please fill in all required fields.");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
      return;
    }

    try {
      const response = await checkInGuest({
        customerName: guestName,
        address,
        mobileNumber: mobileNo,
        idType,
        idNumber,
        checkInDate: checkinDate,
        checkInTime: checkinTime,
        checkOutDate: checkoutDate || null,
        checkOutTime: checkoutTime || null,
        numberOfPersons: noOfPersons,
        numberOfMales: noOfMales,
        numberOfFemales: noOfFemales,
        numberOfChildren: noOfChildren,
        roomIds: selectedRooms,
      });
      

      if (response) {
        setSnackbarMessage("Guest registered successfully!");
        setSnackbarSeverity("success");
        setSnackbarOpen(true);
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      setSnackbarMessage("An error occurred while registering the guest.");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  };

  return (
    <Container maxWidth="sm" sx={{ mt: 4 }}>
      <Typography variant="h4" gutterBottom>
        Guest Registration/Check-In
      </Typography>
      <Box component="form" onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          {/* Room Selection Dropdown */}
          <Grid item xs={12}>
            <FormControl fullWidth required>
              <InputLabel>Room Selection</InputLabel>
              <Select
                multiple
                value={selectedRooms}
                onChange={(event) => setSelectedRooms(event.target.value as string[])}
                renderValue={(selected) =>
                  selected
                    .map((roomId) => {
                      const room = rooms.find((r) => r._id === roomId);
                      return room ? `${room.name} - ${room.type} (₹${room.price})` : "";
                    })
                    .join(", ")
                }
              >
                {rooms.map((room) => (
                  <MenuItem key={room._id} value={room._id}>
                    <Checkbox checked={selectedRooms.includes(room._id)} />
                    <ListItemText primary={`${room.name} - ${room.type} (₹${room.price})`} />
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>Select one or more rooms</FormHelperText>
            </FormControl>
          </Grid>
          
          {/* Rest of the form fields */}
          <Grid item xs={12}>
            <TextField
              label="Mobile No"
              fullWidth
              value={mobileNo}
              onChange={(e) => setMobileNo(e.target.value)}
              required
              type="tel"
              inputProps={{ maxLength: 10 }}
              onBlur={handleMobileNoBlur}
              error={mobileNo.length !== 10}
              helperText={mobileNo.length !== 10 ? "Mobile number must be 10 digits" : ""}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              label="Guest Name"
              fullWidth
              value={guestName}
              onChange={(e) => setGuestName(e.target.value)}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Address"
              fullWidth
              value={address}
              onChange={(e) => setAddress(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth required>
              <InputLabel>ID Type</InputLabel>
              <Select
                value={idType}
                onChange={(e) => setIdType(e.target.value as IdTypes)}
              >
                {Object.values(IdTypes).map((type) => (
                  <MenuItem key={type} value={type}>
                    {type}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>Select a valid ID type</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="ID Number"
              fullWidth
              value={idNumber}
              onChange={(e) => setIdNumber(e.target.value)}
              required
            />
            <FormHelperText>Enter Valid ID Number</FormHelperText>
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Coming From"
              fullWidth
              value={comingFrom}
              onChange={(e) => setComingFrom(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Going To"
              fullWidth
              value={goingTo}
              onChange={(e) => setGoingTo(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              label="No of Persons"
              type="number"
              fullWidth
              value={noOfPersons}
              onChange={(e) => setNoOfPersons(Math.max(0, parseInt(e.target.value, 10)))}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              label="No of Males"
              type="number"
              fullWidth
              value={noOfMales}
              onChange={(e) => setNoOfMales(Math.max(0, parseInt(e.target.value, 10)))}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              label="No of Females"
              type="number"
              fullWidth
              value={noOfFemales}
              onChange={(e) => setNoOfFemales(Math.max(0, parseInt(e.target.value, 10)))}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              label="No of Children"
              type="number"
              fullWidth
              value={noOfChildren}
              onChange={(e) => setNoOfChildren(Math.max(0, parseInt(e.target.value, 10)))}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              label="Check-in Date"
              type="date"
              fullWidth
              value={checkinDate}
              onChange={(e) => setCheckinDate(e.target.value)}
              InputLabelProps={{ shrink: true }}
              required
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              label="Check-in Time"
              type="time"
              fullWidth
              value={checkinTime}
              onChange={(e) => setCheckinTime(e.target.value)}
              InputLabelProps={{ shrink: true }}
              required
            />
          </Grid>
          {/* <Grid item xs={12} md={6}>
            <TextField
              label="Check-out Date"
              type="date"
              fullWidth
              value={checkoutDate}
              onChange={(e) => setCheckoutDate(e.target.value)}
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              label="Check-out Time"
              type="time"
              fullWidth
              value={checkoutTime}
              onChange={(e) => setCheckoutTime(e.target.value)}
              InputLabelProps={{ shrink: true }}
            />
          </Grid> */}
        </Grid>
        <Box sx={{ mt: 3 }}>
          <Button type="submit" variant="contained" color="primary">
            Register Guest
          </Button>
        </Box>
      </Box>
      
      <SnackbarComponent
        open={snackbarOpen}
        message={snackbarMessage}
        severity={snackbarSeverity}
        onClose={handleSnackbarClose}
      />
    </Container>
  );
};

export default GuestRegistrationForm;
