import React from 'react';
import { Box, Container, Grid, Typography, Link } from '@mui/material';

const Footer: React.FC = () => {
  return (
    <Box
      component="footer"
      sx={{
        backgroundColor: '#1976d2',
        color: '#fff',
        p: 2,
        textAlign: 'center',
        position: 'relative',
        bottom: 0,
        width: '100%',
        borderTop: '1px solid #e0e0e0',
      }}
    >
      <Container maxWidth="lg">
        <Grid container spacing={3}>
          {/* Company Info */}
          <Grid item xs={12} sm={4}>
            <Typography variant="h6" fontWeight="bold">
              Designed and Developed by-Chandrakant Chaturvedi
            </Typography>
            <Typography variant="body2">
                123 Innovation Drive
            </Typography>
            <Typography variant="body2">
                BKC Mumbai
            </Typography>
            <Typography variant="body2">
                Mumbai City
                India
            </Typography>
            <Typography variant="body2">Phone: (+91) 8433437965</Typography>
            <Typography variant="body2">Email: ckcchaturvedi@gmail.com</Typography>
          </Grid>

          {/* Useful Links */}
          <Grid item xs={12} sm={4}>
            <Typography variant="h6" fontWeight="bold">
              Useful Links
            </Typography>
            <Link href="/" color="inherit" underline="hover">
              Home
            </Link>
            <br />
            <Link href="/about" color="inherit" underline="hover">
              About Us
            </Link>
            <br />
            <Link href="/contact" color="inherit" underline="hover">
              Contact
            </Link>
            <br />
            <Link href="/privacy-policy" color="inherit" underline="hover">
              Privacy Policy
            </Link>
          </Grid>

          {/* Social Media Links */}
          <Grid item xs={12} sm={4}>
            <Typography variant="h6" fontWeight="bold">
              Follow Us
            </Typography>
            <Link href="https://facebook.com" color="inherit" underline="hover">
              Facebook
            </Link>
            <br />
            <Link href="https://twitter.com" color="inherit" underline="hover">
              Twitter
            </Link>
            <br />
            <Link href="https://instagram.com" color="inherit" underline="hover">
              Instagram
            </Link>
          </Grid>
        </Grid>

        <Box
          sx={{
            borderTop: '1px solid rgba(255, 255, 255, 0.2)',
            marginTop: '20px',
            paddingTop: '10px',
            textAlign: 'center',
          }}
        >
          <Typography variant="body2">
            © {new Date().getFullYear()} . All rights reserved.
          </Typography>
        </Box>
      </Container>
    </Box>
  );
};

export default Footer;
