import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import BillPage from './pages/BillPage';
import HotelPage from './pages/HotelPage';
import Login from './components/Login/Login';
import PrivateRoute from './components/privateRoute';
import './index.css';
import RoomPage from './pages/RoomPage';
import Layout from './components/Common/Layout';
import RoomBillingPage from './pages/RoomBillingPage';
import MenuManagementPage from './components/menu/MenuManagementPage';
import MainLayout from './components/Common/MainLayout';
import ReconciliationReport from './components/Reconcile/Reconciliation';
import AdminManagement from './components/Admins/AdminManagement';
import CustomerManagement from './components/Customers/ManageCustomers';
import GuestRegistrationForm from './components/Customers/GuestRegistrationForm';
import CheckedInCustomers from './components/Customers/CheckedInCustomers';
import ManageEmployee from './components/Employees/ManageEmployee';

import { createTheme, ThemeProvider } from '@mui/material/styles';
import TaxManagement from './components/Taxes/TaxManagement';
import TemplateManagement from './components/Templates/TemplateManagement';
import HotelManagement from './components/Hotel/HotelManagement';

const theme = createTheme({
  typography: {
    h4: {
      fontWeight: 'bold',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          margin: '8px 0',
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          marginBottom: '16px',
        },
      },
    },
  },
});

function App() {
  return (
<ThemeProvider theme={theme}>
      <MainLayout>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/login" element={<Login />} />
              <Route path="/manage-hotels" element={<PrivateRoute element={<HotelManagement />} />} />
              <Route path="/bills" element={<PrivateRoute element={<BillPage />} />} />
              {/* <Route path="/hotels" element={<PrivateRoute element={<HotelPage />} />} /> */}
              <Route path="/menu-management" element={<PrivateRoute element={<MenuManagementPage />} />} />


              <Route path="/rooms" element={<PrivateRoute element={<RoomPage />} />} />
              <Route path="/room-billings" element={<PrivateRoute element={<RoomBillingPage />} />} />

              <Route path="/guest-registration" element={<PrivateRoute element={<GuestRegistrationForm />} />} />
              <Route path="/checkedin-customers" element={<PrivateRoute element={<CheckedInCustomers />} />} />

              <Route path="/reconcile" element={<PrivateRoute element={<ReconciliationReport />} />} />

              <Route path="/manage-admins" element={<PrivateRoute element={<AdminManagement />} />} />

              <Route path="/manage-employees" element={<PrivateRoute element={<ManageEmployee />} />} />

              <Route path="/manage-customers" element={<PrivateRoute element={<CustomerManagement />} />} />

              <Route path="/manage-taxes" element={<PrivateRoute element={<TaxManagement />} />} />

              <Route path="/manage-templates" element={<PrivateRoute element={<TemplateManagement />} />} />
            </Routes>
            </MainLayout></ThemeProvider>
  );
}

export default App;
