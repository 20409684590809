import React, { useEffect, useState } from 'react';
import {
  Container,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  Box,
} from '@mui/material';
import { getAllCheckedOuts } from '../../services/customerService'; // Assuming an API service exists

interface Customer {
  _id: string;           // MongoDB document ID
  customerName: string;  // Customer's name
  roomId: string;        // Room ID
  checkOutDate: string;  // Checkout date
  // Assuming totalBill is calculated somehow; you might need to adjust this based on your logic.
  totalBill: number;     // Total bill for the stay
}

const CheckoutCustomers: React.FC = () => {
  const [customers, setCustomers] = useState<Customer[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchCustomers = async () => {
      try {
        const response = await getAllCheckedOuts(); // API call
        // Transform response data if necessary
        const formattedCustomers = response.map((customer: any) => ({
          _id: customer._id,
          customerName: customer.customerName,
          roomId: customer.roomId, // Use this to display room number if needed
          checkOutDate: customer.checkOutDate,
          totalBill: 0, // Replace this with your logic to calculate the total bill
        }));
        setCustomers(formattedCustomers);
      } catch (error) {
        console.error('Error fetching checked-out customers', error);
      } finally {
        setLoading(false);
      }
    };
    fetchCustomers();
  }, []);

  return (
    <Container maxWidth="lg" sx={{ mt: 4 }}>
      <Typography variant="h4" component="h1" gutterBottom sx={{ color: '#2C3E50', fontWeight: 'bold' }}>
        Checked-Out Customers
      </Typography>
      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '300px' }}>
          <CircularProgress />
        </Box>
      ) : (
        <TableContainer component={Paper} sx={{ backgroundColor: '#ecf0f1', borderRadius: 2, mt: 2 }}>
          <Table>
            <TableHead>
              <TableRow sx={{ backgroundColor: '#34495e' }}>
                <TableCell sx={{ color: '#fff', fontWeight: 'bold' }}>Customer Name</TableCell>
                <TableCell sx={{ color: '#fff', fontWeight: 'bold' }}>Room ID</TableCell>
                <TableCell sx={{ color: '#fff', fontWeight: 'bold' }}>Checkout Date</TableCell>
                <TableCell sx={{ color: '#fff', fontWeight: 'bold' }}>Total Bill</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {customers.map((customer) => (
                <TableRow key={customer._id}>
                  <TableCell>{customer.customerName}</TableCell>
                  <TableCell>{customer.roomId}</TableCell>
                  <TableCell>{new Date(customer.checkOutDate).toLocaleDateString()}</TableCell>
                  <TableCell>₹ {customer.totalBill.toFixed(2)}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Container>
  );
};

export default CheckoutCustomers;
