// Enums for Room Categories and Room Types
export enum RoomCategory {
    Economy = 'Economy',
    Deluxe = 'Deluxe',
    Suite = 'Suite',
    Family = 'Family',
    King = 'King',
    Queen = 'Queen',
    Executive = 'Executive',
    Presidential = 'Presidential',
    Penthouse = 'Penthouse',
    Superior = 'Superior',
    JuniorSuite = 'Junior Suite',
    ClubRoom = 'Club Room',
    Villa = 'Villa',
  }
  
  
export enum RoomType {
    Single = 'Single',
    Double = 'Double',
    Triple = 'Triple',
    Quad = 'Quad',
  }