import React, { useEffect, useState } from 'react';
import {
  Container,
  Typography,
  Button,
  Box,
  Grid,
  Divider,
} from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import { getCustomerById } from '../../services/customerService';
import PaymentLedger from '../Payments/PaymentLedger';

const CustomerProfile: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [customerDetails, setCustomerDetails] = useState<any>(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCustomerDetails = async () => {
      try {
        const response = await getCustomerById(id || "");
        setCustomerDetails(response);
      } catch (error) {
        console.error('Error fetching customer details', error);
      }
    };

    fetchCustomerDetails();
  }, [id]);

  const handleBack = () => {
    navigate('/manage-customers');
  };

  if (!customerDetails) {
    return <Typography>Loading...</Typography>;
  }

  return (
    <Container sx={{ mt: 4 }}>
      <Button onClick={handleBack} variant="contained" color="primary">
        Back
      </Button>
      <Typography variant="h4" gutterBottom>
        Customer Profile: {customerDetails.customerName}
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Typography variant="h6">Mobile Number: {customerDetails.mobileNumber}</Typography>
          <Typography variant="h6">Address: {customerDetails.address}</Typography>
          <Typography variant="h6">ID Type: {customerDetails.idType}</Typography>
          <Typography variant="h6">ID Number: {customerDetails.idNumber}</Typography>
        </Grid>
      </Grid>
      <Divider sx={{ mt: 3, mb: 3 }} />
      {/* Payment Ledger section */}
      <PaymentLedger mobileNumber={customerDetails.mobileNumber} />
    </Container>
  );
};

export default CustomerProfile;
