import React, { useState, useEffect } from 'react';
import {
  AppBar,
  Toolbar,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
  IconButton,
  Avatar,
  Box,
  Button,
  Menu,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Snackbar,
  Alert,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { getAllHotels } from '../../services/hotelService';
import { useNavigate } from 'react-router-dom';
import { setDataToLocalStorage } from '../../utils/localStorageUtils';
import { changePasswordService } from '../../services/loginService';

const Header: React.FC = () => {
  const [hotels, setHotels] = useState<any[]>([]);
  const [selectedHotel, setSelectedHotel] = useState<string | null>(null);
  const [isProduction, setIsProduction] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [openPasswordDialog, setOpenPasswordDialog] = useState<boolean>(false);
  const [oldPassword, setOldPassword] = useState<string>('');
  const [newPassword, setNewPassword] = useState<string>('');
  const [retypeNewPassword, setRetypeNewPassword] = useState<string>(''); // Added field for retyping password
  const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error'>('success');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchHotels = async () => {
      try {
        const response = await getAllHotels();
        setHotels(response);
      } catch (error) {
        console.error('Error fetching hotels', error);
      }
    };

    fetchHotels();
  }, []);

  useEffect(() => {
    const savedHotel = localStorage.getItem('selectedHotel');
    if (savedHotel) {
      setSelectedHotel(savedHotel);
      const selectedHotelObj = hotels.find(hotel => hotel._id === savedHotel);
      if (selectedHotelObj) {
        setIsProduction(selectedHotelObj.isProduction || false);
      }
    }
  }, [hotels]);

  const handleHotelChange = (event: SelectChangeEvent<string>) => {
    const hotelId = event.target.value;
    setSelectedHotel(hotelId);
    const selectedHotelObj = hotels.find(hotel => hotel._id === hotelId);
    if (selectedHotelObj) {
      setIsProduction(selectedHotelObj.isProduction || false);
      setDataToLocalStorage('isProduction', selectedHotelObj.isProduction ? selectedHotelObj.isProduction : true);
    }
    localStorage.setItem('selectedHotel', hotelId);
  };

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    localStorage.clear();
    navigate('/login');
  };

  const handleOpenPasswordDialog = () => {
    setOpenPasswordDialog(true);
    handleMenuClose(); // Close the menu
  };

  const handleClosePasswordDialog = () => {
    setOpenPasswordDialog(false);
  };

  const handleChangePassword = async () => {
    if (newPassword !== retypeNewPassword) {
      setSnackbarSeverity('error');
      setSnackbarMessage('Passwords do not match');
      setSnackbarOpen(true);
      return;
    }

    try {
      await changePasswordService(oldPassword, newPassword); // Call the change password service
      setSnackbarSeverity('success');
      setSnackbarMessage('Password changed successfully!');
      handleClosePasswordDialog(); // Close the dialog
    } catch (error) {
      setSnackbarSeverity('error');
      setSnackbarMessage('Failed to change password.');
    } finally {
      setSnackbarOpen(true);
    }
  };

  return (
    <AppBar
      position="fixed"
      sx={{
        zIndex: (theme) => theme.zIndex.drawer + 1,
        bgcolor: isProduction ? '#388E3C' : '#B71C1C',
        boxShadow: 'none',
      }}
    >
      <Toolbar>
        <IconButton edge="start" color="inherit" aria-label="menu" sx={{ mr: 2 }}>
          <MenuIcon />
        </IconButton>
        <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}>
          Personalised Billing
        </Typography>
        <FormControl variant="outlined" sx={{ minWidth: 150, mr: 2 }}>
          <InputLabel htmlFor="hotel-select">Select Hotel</InputLabel>
          <Select
            id="hotel-select"
            value={selectedHotel || ''}
            onChange={handleHotelChange}
            label="Select Hotel"
            sx={{ backgroundColor: 'white', borderRadius: '4px' }}
          >
            {hotels.map((hotel) => (
              <MenuItem key={hotel._id} value={hotel._id}>
                {hotel.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <IconButton color="inherit" onClick={handleMenuOpen}>
            <Avatar alt="User Avatar" src="/path/to/avatar.jpg" />
          </IconButton>
          <Typography variant="body1" sx={{ ml: 1, mr: 2 }} onClick={handleMenuOpen} style={{ cursor: 'pointer' }}>
            Admin
          </Typography>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
            PaperProps={{
              style: {
                width: '200px',
              },
            }}
          >
            <MenuItem onClick={handleOpenPasswordDialog}>Change Password</MenuItem>
            <MenuItem onClick={handleLogout}>Logout</MenuItem>
          </Menu>
        </Box>
      </Toolbar>

      <Dialog open={openPasswordDialog} onClose={handleClosePasswordDialog}>
        <DialogTitle>Change Password</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Old Password"
            type="password"
            fullWidth
            value={oldPassword}
            onChange={(e) => setOldPassword(e.target.value)}
            required
          />
          <TextField
            margin="dense"
            label="New Password"
            type="password"
            fullWidth
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            required
          />
          <TextField
            margin="dense"
            label="Retype New Password"
            type="password"
            fullWidth
            value={retypeNewPassword}
            onChange={(e) => setRetypeNewPassword(e.target.value)}
            required
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClosePasswordDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleChangePassword} color="primary">
            Change Password
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={() => setSnackbarOpen(false)}>
        <Alert onClose={() => setSnackbarOpen(false)} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </AppBar>
  );
};

export default Header;
