import React from 'react';
import { Link } from 'react-router-dom';
import { Typography, Button, Container, Box } from '@mui/material';

const Home: React.FC = () => {
  return (
    <Container>
      <Box>
        <Typography variant="h3" gutterBottom>
          Welcome to Hotel Billing App
        </Typography>
        <Box mt={2}>
          <Button 
            component={Link} 
            to="/bills" 
            variant="contained" 
            color="primary" 
            sx={{ mr: 2 }}
          >
            Bills
          </Button>
          <Button 
            component={Link} 
            to="/manage-hotels" 
            variant="contained" 
            color="secondary"
          >
            Hotels
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default Home;
