import React, { useState, useEffect } from "react";
import {
  Container,
  TextField,
  Button,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Grid,
  MenuItem,
  InputAdornment,
} from "@mui/material";
import { Add, Remove } from "@mui/icons-material";
import Autocomplete from "@mui/material/Autocomplete";
import { getTaxesForProductCategory } from "../../services/taxService";
import { getCustomerByMobileNumber } from "../../services/customerService";
import { getMenuItems } from "../../services/menuService";
import { getRooms } from "../../services/roomService";
import { createRoomBilling } from "../../services/roomBillingService";
import ConfirmationDialog from "../Shared/ConfirmationDialog";

const CustomerBill: React.FC = () => {
  const [customerName, setCustomerName] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [address, setAddress] = useState("");
  const [menuItems, setMenuItems] = useState<any[]>([]);
  const [rooms, setRooms] = useState<any[]>([]);
  const [foodTaxes, setFoodTaxes] = useState<any[]>([]);
  const [roomTaxes, setRoomTaxes] = useState<any[]>([]);
  const [totalDiscount, setTotalDiscount] = useState(0);
  const [invoiceItems, setInvoiceItems] = useState<any[]>([
    { item: null, type: "FOOD", quantity: 1, appliedTaxes: [] },
  ]);
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
  const [numAdults, setNumAdults] = useState(1);
  const [numChildren, setNumChildren] = useState(0);
  const [nationality, setNationality] = useState("INDIAN");
  const [checkinDate, setCheckinDate] = useState("");
  const [checkinTime, setCheckinTime] = useState("");
  const [checkoutDate, setCheckoutDate] = useState("");
  const [checkoutTime, setCheckoutTime] = useState("");

  useEffect(() => {
    const fetchMenuItems = async () => {
      try {
        const response = await getMenuItems();
        setMenuItems(response);
      } catch (error) {
        console.error("Error fetching menu items:", error);
      }
    };

    const fetchRooms = async () => {
      try {
        const response = await getRooms();
        setRooms(response);
      } catch (error) {
        console.error("Error fetching rooms:", error);
      }
    };

    const fetchTaxes = async () => {
      try {
        const foodResponse = await getTaxesForProductCategory("FOOD");
        setFoodTaxes(foodResponse);
        const roomResponse = await getTaxesForProductCategory("ACCOMMODATION");
        setRoomTaxes(roomResponse);
      } catch (error) {
        console.error("Error fetching taxes:", error);
      }
    };

    fetchMenuItems();
    fetchRooms();
    fetchTaxes();
  }, []);

  const handleCustomerSearch = async (mobile: string) => {
    try {
      const response = await getCustomerByMobileNumber(mobile);
      if (response) {
        setCustomerName(response.customerName || "");
        setAddress(response.address || "");
      }
    } catch (error) {
      console.error("Error fetching customer data", error);
    }
  };

  const handleAddItem = () => {
    setInvoiceItems([...invoiceItems, { item: null, type: "FOOD", quantity: 1, appliedTaxes: [] }]);
  };

  const handleRemoveItem = (index: number) => {
    setInvoiceItems(invoiceItems.filter((_, i) => i !== index));
  };

  const handleItemChange = (index: number, item: any, type: "FOOD" | "ROOM") => {
    const newItems = [...invoiceItems];
    newItems[index] = { item, type, quantity: 1, appliedTaxes: [] };

    if (item) {
      const applicableTaxes = type === "FOOD" ? foodTaxes : roomTaxes;
      newItems[index].appliedTaxes = applicableTaxes.map((tax) => ({
        taxType: tax.type,
        rate: tax.rate,
        calculatedAmount: 0,
        editableRate: tax.rate,
      }));
    }
    setInvoiceItems(newItems);
    recalculateItemTaxes(index, 1, totalDiscount, item, newItems);
  };

  const handleQuantityChange = (index: number, quantity: number) => {
    if (quantity < 1) quantity = 1;
    const newItems = [...invoiceItems];
    newItems[index].quantity = quantity;
    setInvoiceItems(newItems);
    recalculateItemTaxes(index, quantity, totalDiscount, newItems[index].item, newItems);
  };

  const handleDiscountChange = (discount: number) => {
    if (isNaN(discount) || discount < 0) discount = 0;
    setTotalDiscount(discount);
    const newItems = [...invoiceItems];
    newItems.forEach((item, index) => {
      recalculateItemTaxes(index, item.quantity, discount, item.item, newItems);
    });
  };

  const handleTaxRateChange = (itemIndex: number, taxType: string, newRate: number) => {
    const newItems = [...invoiceItems];
    const tax = newItems[itemIndex].appliedTaxes.find((t: any) => t.taxType === taxType);
    if (tax) {
      tax.editableRate = newRate;
      recalculateItemTaxes(itemIndex, newItems[itemIndex].quantity, totalDiscount, newItems[itemIndex].item, newItems);
    }
  };

  const recalculateItemTaxes = (
    index: number,
    quantity: number,
    discount: number,
    item: any,
    itemsArray: any[]
  ) => {
    if (item) {
      const discountedPricePerUnit = item.price * (1 - discount / 100);
      const totalPrice = discountedPricePerUnit * quantity;

      itemsArray[index].appliedTaxes.forEach((tax: any) => {
        tax.calculatedAmount = (totalPrice * tax.editableRate) / 100;
      });
    }
    setInvoiceItems(itemsArray);
  };

  const calculateTotals = () => {
    let totalDiscountAmount = 0;
    let totalTax = 0;
    let grandTotal = 0;
    let totalSgst = 0;
    let totalCgst = 0;

    invoiceItems.forEach((item) => {
      if (item.item) {
        const itemTotal = item.item.price * item.quantity;
        const itemDiscount = (itemTotal * totalDiscount) / 100;
        const itemNetPrice = itemTotal - itemDiscount;

        totalDiscountAmount += itemDiscount;
        item.appliedTaxes.forEach((tax: any) => {
          if (tax.taxType === "SGST") totalSgst += tax.calculatedAmount;
          if (tax.taxType === "CGST") totalCgst += tax.calculatedAmount;
          totalTax += tax.calculatedAmount;
        });
        grandTotal += itemNetPrice + item.appliedTaxes.reduce((acc: any, tax: any) => acc + tax.calculatedAmount, 0);
      }
    });

    return {
      totalDiscountAmount,
      totalTax,
      totalSgst,
      totalCgst,
      grandTotal,
    };
  };

  const { totalDiscountAmount, totalTax, totalSgst, totalCgst, grandTotal } = calculateTotals();

  const openConfirmationDialog = () => {
    setConfirmationDialogOpen(true);
  };

  const closeConfirmationDialog = () => {
    setConfirmationDialogOpen(false);
  };

  const confirmInvoiceSubmission = async () => {
    closeConfirmationDialog();
    const invoiceData = {
      customerName,
      mobileNumber,
      customerAddress: address,
      numAdults,
      numChildren,
      nationality,
      checkinDate,
      checkinTime,
      checkoutDate,
      checkoutTime,
      items: invoiceItems.map((item) => {
        const itemTotal = item.item.price * item.quantity;
        const itemDiscount = (itemTotal * totalDiscount) / 100;
        const itemNetPrice = itemTotal - itemDiscount;

        return {
          name: item.item.name,
          type: item.type,
          quantity: item.quantity,
          price: item.item.price,
          taxes: item.appliedTaxes.map((tax: any) => ({
            name: tax.taxType,
            amount: tax.calculatedAmount,
            rate: tax.editableRate,
          })),
          taxableAmount: itemNetPrice,
        };
      }),
      discountAmount: totalDiscountAmount,
      discountPercent: totalDiscount,
      amount: invoiceItems.reduce((total, item) => total + item.item.price * item.quantity, 0),
      amountAfterDiscount: invoiceItems.reduce((total, item) => {
        const itemTotal = item.item.price * item.quantity;
        const itemDiscount = (itemTotal * totalDiscount) / 100;
        return total + (itemTotal - itemDiscount);
      }, 0),
      totalGST: totalTax,
      sgst: totalSgst,
      cgst: totalCgst,
      grandTotal,
    };

    try {
      await createRoomBilling(invoiceData);
      // Handle successful invoice creation (e.g., reset form, show success message)
    } catch (error) {
      console.error("Error creating bill:", error);
    }
  };

  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        Create Customer Bill
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Customer Mobile Number"
            variant="outlined"
            fullWidth
            value={mobileNumber}
            onChange={(e) => setMobileNumber(e.target.value)}
            onBlur={() => handleCustomerSearch(mobileNumber)}
            InputProps={{
              endAdornment: <InputAdornment position="end">📞</InputAdornment>,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Customer Name"
            variant="outlined"
            fullWidth
            value={customerName}
            onChange={(e) => setCustomerName(e.target.value)}
            InputProps={{
              endAdornment: <InputAdornment position="end">👤</InputAdornment>,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Address"
            variant="outlined"
            fullWidth
            value={address}
            onChange={(e) => setAddress(e.target.value)}
            InputProps={{
              endAdornment: <InputAdornment position="end">🏠</InputAdornment>,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            label="Number of Adults"
            type="number"
            variant="outlined"
            fullWidth
            value={numAdults}
            onChange={(e) => setNumAdults(parseInt(e.target.value))}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            label="Number of Children"
            type="number"
            variant="outlined"
            fullWidth
            value={numChildren}
            onChange={(e) => setNumChildren(parseInt(e.target.value))}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            label="Nationality"
            variant="outlined"
            fullWidth
            value={nationality}
            onChange={(e) => setNationality(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            label="Check-in Date"
            type="date"
            variant="outlined"
            fullWidth
            value={checkinDate}
            onChange={(e) => setCheckinDate(e.target.value)}
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            label="Check-in Time"
            type="time"
            variant="outlined"
            fullWidth
            value={checkinTime}
            onChange={(e) => setCheckinTime(e.target.value)}
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            label="Check-out Date"
            type="date"
            variant="outlined"
            fullWidth
            value={checkoutDate}
            onChange={(e) => setCheckoutDate(e.target.value)}
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            label="Check-out Time"
            type="time"
            variant="outlined"
            fullWidth
            value={checkoutTime}
            onChange={(e) => setCheckoutTime(e.target.value)}
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
      </Grid>

      <TableContainer component={Paper} sx={{ marginTop: 2 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Item</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Quantity</TableCell>
              <TableCell>Price</TableCell>
              <TableCell>Taxes</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {invoiceItems.map((item, index) => (
              <TableRow key={index} sx={{ "&:hover": { backgroundColor: "#f5f5f5" } }}>
                <TableCell>
                  <Autocomplete
                    options={item.type === "FOOD" ? menuItems : rooms}
                    getOptionLabel={(option) => option.name}
                    onChange={(event, newValue) => handleItemChange(index, newValue, item.type)}
                    renderInput={(params) => <TextField {...params} label="Select Item" variant="outlined" />}
                  />
                </TableCell>
                <TableCell>
                  <TextField
                    select
                    variant="outlined"
                    value={item.type}
                    onChange={(e) => {
                      const newType = e.target.value as "FOOD" | "ROOM";
                      handleItemChange(index, null, newType);
                    }}
                  >
                    <MenuItem value="FOOD">Food</MenuItem>
                    <MenuItem value="ROOM">Room</MenuItem>
                  </TextField>
                </TableCell>
                <TableCell>
                  <TextField
                    type="number"
                    variant="outlined"
                    value={item.quantity}
                    onChange={(e) => handleQuantityChange(index, parseInt(e.target.value))}
                    InputProps={{
                      inputProps: { min: 1 },
                      startAdornment: <InputAdornment position="start">x</InputAdornment>,
                    }}
                  />
                </TableCell>
                <TableCell>
                  {item.item ? item.item.price : 0}
                </TableCell>
                <TableCell>
                  {item.appliedTaxes.map((tax: any) => (
                    <div key={tax.taxType}>
                      {tax.taxType}: {tax.calculatedAmount}
                      <Button variant="outlined" onClick={() => handleTaxRateChange(index, tax.taxType, tax.editableRate)} size="small" sx={{ marginLeft: 1 }}>
                        Edit
                      </Button>
                    </div>
                  ))}
                </TableCell>
                <TableCell>
                  <IconButton onClick={() => handleRemoveItem(index)}>
                    <Remove />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Grid container spacing={3} sx={{ marginTop: 2 }}>
        <Grid item xs={12} sm={4}>
          <TextField
            label="Total Discount (%)"
            type="number"
            variant="outlined"
            value={totalDiscount}
            onChange={(e) => handleDiscountChange(parseFloat(e.target.value))}
            InputProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Button variant="contained" color="primary" onClick={handleAddItem} sx={{ height: '100%' }}>
            <Add /> Add Item
          </Button>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Button variant="contained" color="secondary" onClick={openConfirmationDialog} sx={{ height: '100%' }}>
            Generate Invoice
          </Button>
        </Grid>
      </Grid>

      <ConfirmationDialog
        open={confirmationDialogOpen}
        onCancel={closeConfirmationDialog}
        onConfirm={confirmInvoiceSubmission}
        title="Confirm Invoice Submission"
        message="Are you sure you want to submit this invoice?"
      />

      <TableContainer component={Paper} sx={{ marginTop: 2 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Total Discount Amount</TableCell>
              <TableCell>Total Tax</TableCell>
              <TableCell>Grand Total</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>{totalDiscountAmount}</TableCell>
              <TableCell>{totalTax}</TableCell>
              <TableCell>{grandTotal.toFixed(0)}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
};

export default CustomerBill;
