import { getHotelIdFromLocalStorage } from '../utils/localStorageUtils';
import api from './api';

// Get all customers for a specific hotel with pagination and filtering
export const getCustomers = async (page: number = 1, count: number = 10, filterData: any = {}) => {
  const hotelId = getHotelIdFromLocalStorage();
  if (!hotelId) throw new Error('No hotel selected');

  try {
    const response = await api.get('/customers', {
      params: {
        hotelId,
        page,
        count,
        ...filterData, // Include any additional filter data
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching customers', error);
    throw error; // Re-throw error to be handled by the calling code
  }
};

// Get a specific customer by ID for a specific hotel
export const getCustomerById = async (id: string) => {
  const hotelId = getHotelIdFromLocalStorage();
  if (!hotelId) throw new Error('No hotel selected');

  try {
    const response = await api.get(`/customers/${id}`, {
      params: { hotelId },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching customer details', error);
    throw error;
  }
};

export const getCustomerByMobileNumber = async (mobileNumber: string) => {

  const hotelId = getHotelIdFromLocalStorage();
    try {
      const response = await api.get(`/customers/getCustomerByMobileNumber`, {
        params: { mobileNumber, hotelId},
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching customer by mobile number:', error);
      throw error;
    }
  };

// Create a new customer for a specific hotel
export const createCustomer = async (data: any) => {
  const hotelId = getHotelIdFromLocalStorage();
  if (!hotelId) throw new Error('No hotel selected');

  try {
    const response = await api.post('/customers/create', { ...data, hotelId });
    return response.data;
  } catch (error) {
    console.error('Error creating customer', error);
    throw error;
  }
};


// Check-in a guest for a specific hotel
export const checkInGuest = async (data: any) => {
    const hotelId = getHotelIdFromLocalStorage();
    if (!hotelId) throw new Error('No hotel selected');
  
    try {
      const response = await api.post('/customers/checkin', { ...data, hotelId });
      return response.data;
    } catch (error) {
      console.error('Error checking in guest', error);
      throw error;
    }
  };

  export const checkOutCustomer = async (checkInId: string, checkOutDate: string, checkOutTime: string) => {
  const hotelId = getHotelIdFromLocalStorage();
  if (!hotelId) throw new Error('No hotel selected');

  try {
    const response = await api.post('/customers/checkout', { checkInId, checkOutDate, checkOutTime, hotelId });
    return response.data;
  } catch (error) {
    console.error('Error checking out customer', error);
    throw error;
  }
};


  export const getAllCheckins = async () => {
    const hotelId = getHotelIdFromLocalStorage();
  
    try {
      const response = await api.get('/customers/getAllCheckins',{
        params: { hotelId },
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching checked-in customers', error);
      throw error;
    }
  };

// Update an existing customer for a specific hotel
export const updateCustomer = async (id: string, data: any) => {
  const hotelId = getHotelIdFromLocalStorage();
  if (!hotelId) throw new Error('No hotel selected');

  try {
    const response = await api.put(`/customers/${id}`, { ...data, hotelId });
    return response.data;
  } catch (error) {
    console.error('Error updating customer', error);
    throw error;
  }
};

// Delete a customer for a specific hotel
export const deleteCustomer = async (id: string) => {
  const hotelId = getHotelIdFromLocalStorage();
  if (!hotelId) throw new Error('No hotel selected');

  try {
    const response = await api.delete(`/customers/${id}`, {
      params: { hotelId },
    });
    return response.data;
  } catch (error) {
    console.error('Error deleting customer', error);
    throw error;
  }
};

// Search for customers by name or other criteria for a specific hotel
export const searchCustomers = async (query: string, filterData: any = {}) => {
  const hotelId = getHotelIdFromLocalStorage();
  if (!hotelId) throw new Error('No hotel selected');

  try {
    const response = await api.get('/customers/search', {
      params: {
        q: query,
        hotelId,
        ...filterData, // Include any additional filter data
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error searching customers', error);
    throw error;
  }
};
