import React, { useEffect, useState, useRef } from 'react';
import {
  Container,
  Typography,
  Button,
  List,
  ListItem,
  Paper,
  Divider,
  Box,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Grid,
  MenuItem,
  Select,
  InputLabel,
  SelectChangeEvent,
} from '@mui/material';
import {generateRoomBillingPdfFromHtml, getRoomBillings } from '../../services/roomBillingService';
import { createPayment, getPaymentsByBillId } from '../../services/paymentService';
import CloseIcon from '@mui/icons-material/Close';
import DownloadIcon from '@mui/icons-material/Download';
import PrintIcon from '@mui/icons-material/Print';
import { PaymentMethods } from '../../enums/PaymentMethods';
import PaginationComponent from '../Shared/PaginationControl';

const PAGE_SIZE_OPTIONS = [10, 20, 50, 100];

const RoomBillingList: React.FC = () => {
  const [roomBillings, setRoomBillings] = useState<any[]>([]);
  const [selectedBilling, setSelectedBilling] = useState<any | null>(null);
  const [pdfData, setPdfData] = useState<Blob | null>(null);
  const [paymentDialogOpen, setPaymentDialogOpen] = useState<boolean>(false);
  const [paymentAmount, setPaymentAmount] = useState<number>(0);
  const [paymentMethod, setPaymentMethod] = useState<PaymentMethods>(PaymentMethods.CASH);
  const [paymentReference, setPaymentReference] = useState<string>('');
  const [payments, setPayments] = useState<any[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(PAGE_SIZE_OPTIONS[0]);
  const pdfCanvasRef = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    const fetchRoomBillings = async (page: number, size: number) => {
      try {
        const response = await getRoomBillings(page, size);
        setRoomBillings(response.roomBillings);
        setTotalPages(response.totalPages); // Update totalPages from response
      } catch (error) {
        console.error('Error fetching room billings:', error);
      }
    };
    fetchRoomBillings(currentPage, pageSize);
  }, [currentPage, pageSize]);

  const handleViewBilling = async (billingId: string) => {
    try {
      const billing = roomBillings.find((billing) => billing._id === billingId);
      setSelectedBilling(billing);

      const response = await generateRoomBillingPdfFromHtml(billingId);
      setPdfData(response);

      const paymentsResponse = await getPaymentsByBillId(billingId);
      setPayments(paymentsResponse);
    } catch (error) {
      console.error('Error fetching billing details or payments', error);
    }
  };

  const downloadPdf = () => {
    if (pdfData) {
      const url = window.URL.createObjectURL(pdfData);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'billing.pdf');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const printPdf = () => {
    if (pdfData) {
      const url = window.URL.createObjectURL(pdfData);
      window.open(url, '_blank');
      setTimeout(() => {
        window.URL.revokeObjectURL(url);
      }, 1000);
    }
  };

  const handleOpenPaymentDialog = () => {
    setPaymentDialogOpen(true);
  };

  const handleClosePaymentDialog = () => {
    setPaymentDialogOpen(false);
  };

  const handleAddPayment = async () => {
    try {
      if (selectedBilling) {
        await createPayment({
          billId: selectedBilling._id,
          amount: paymentAmount,
          method: paymentMethod,
          referenceNumber: paymentReference,
          mobileNumber: selectedBilling.mobileNumber,
        });
        const paymentsResponse = await getPaymentsByBillId(selectedBilling._id);
        setPayments(paymentsResponse);
        handleClosePaymentDialog();
      }
    } catch (error) {
      console.error('Error adding payment', error);
    }
  };

  const handlePageChange = (event: React.ChangeEvent<unknown>, page: number) => {
    setCurrentPage(page);
  };

  const handlePageSizeChange = (event: SelectChangeEvent<number>) => {
    setPageSize(event.target.value as number);
    setCurrentPage(1); // Reset to the first page when page size changes
  };

  return (
    <Box>
      <Grid container spacing={3}>
        {/* Room Billings List */}
        <Grid item xs={12} md={6}>
          <Paper elevation={3} sx={{ p: 3 }}>
            <Box sx={{ mb: 2, display: 'flex', alignItems: 'center' }}>
              <InputLabel id="page-size-label" sx={{ mr: 2 }}>
                Items per Page:
              </InputLabel>
              <Select
                labelId="page-size-label"
                value={pageSize}
                onChange={handlePageSizeChange}
                variant="outlined"
                sx={{ minWidth: 120 }}
              >
                {PAGE_SIZE_OPTIONS.map(size => (
                  <MenuItem key={size} value={size}>
                    {size}
                  </MenuItem>
                ))}
              </Select>
            </Box>
            <List>
              {roomBillings.map((billing) => (
                <React.Fragment key={billing._id}>
                  <ListItem>
                    <Box flex={1}>
                      <Typography variant="body1">
                        <strong>Bill Number:</strong> {billing.billNumber}
                      </Typography>
                      <Typography variant="body1">
                        <strong>Room:</strong> {billing.roomId} - Guest: {billing.guestName}
                      </Typography>
                      <Typography variant="body1">
                        <strong>Billing Date:</strong> {new Date(billing.billingDate).toLocaleDateString()}
                      </Typography>
                      <Typography variant="body1">
                        <strong>Total Amount:</strong> ₹{billing.totalAmount}
                      </Typography>
                    </Box>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => handleViewBilling(billing._id)}
                    >
                      View Details
                    </Button>
                  </ListItem>
                  <Divider />
                </React.Fragment>
              ))}
            </List>
            <PaginationComponent
              count={totalPages}
              page={currentPage}
              onPageChange={handlePageChange}
            />
          </Paper>
        </Grid>

        {/* Selected Billing Details */}
        {selectedBilling && (
          <Grid item xs={12} md={6}>
            <Paper elevation={3} sx={{ p: 3 }}>
              <Typography variant="h5" gutterBottom>
                Billing Details
              </Typography>
          <Typography variant="body1">
            <strong>Room:</strong> {selectedBilling.roomId}
          </Typography>
          <Typography variant="body1">
            <strong>Guest:</strong> {selectedBilling.guestName}
          </Typography>
          <Typography variant="body1">
            <strong>Adults:</strong> {selectedBilling.numAdults}
          </Typography>
          <Typography variant="body1">
            <strong>Children:</strong> {selectedBilling.numChildren}
          </Typography>
          <Typography variant="body1">
            <strong>Check-in:</strong> {new Date(selectedBilling.checkinDate).toLocaleDateString()} at {selectedBilling.checkinTime}
          </Typography>
          <Typography variant="body1">
            <strong>Check-out:</strong> {new Date(selectedBilling.checkoutDate).toLocaleDateString()} at {selectedBilling.checkoutTime}
          </Typography>
          <Typography variant="body1">
            <strong>Duration:</strong> {selectedBilling.numDays} days
          </Typography>
          <Typography variant="body1">
            <strong>Address:</strong> {selectedBilling.customerAddress}
          </Typography>
          <Typography variant="body1">
            <strong>Nationality:</strong> {selectedBilling.nationality}
          </Typography>

          <Typography variant="h6" sx={{ mt: 3 }}>
            Billing Summary
          </Typography>
          <Typography variant="body1">
            <strong>Price per day:</strong> ₹{selectedBilling.pricePerDay}
          </Typography>
          <Typography variant="body1">
            <strong>Discount:</strong> {selectedBilling.discountPercent}%
          </Typography>
          <Typography variant="body1">
            <strong>Discount Amount:</strong> ₹{selectedBilling.discountAmount}
          </Typography>
          <Typography variant="body1">
            <strong>Service Charge:</strong> {selectedBilling.serviceChargePercent}%
          </Typography>
          <Typography variant="body1">
            <strong>Service Charge Amount:</strong> ₹{selectedBilling.serviceChargeAmount}
          </Typography>
          <Typography variant="body1">
            <strong>SGST:</strong> ₹{selectedBilling.sgst}
          </Typography>
          <Typography variant="body1">
            <strong>CGST:</strong> ₹{selectedBilling.cgst}
          </Typography>
          <Typography variant="body1">
            <strong>Total Amount:</strong> ₹{selectedBilling.totalAmount}
          </Typography>

              <Divider sx={{ my: 3 }} />
              <Box sx={{ mt: 2, textAlign: 'center' }}>
                <Button
                  variant="outlined"
                  onClick={downloadPdf}
                  sx={{ mr: 2 }}
                  startIcon={<DownloadIcon />}
                >
                  Download PDF
                </Button>
                <Button
                  variant="outlined"
                  onClick={printPdf}
                  startIcon={<PrintIcon />}
                >
                  Print PDF
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={handleOpenPaymentDialog}
                  sx={{ mt: 2 }}
                >
                  Add Payment
                </Button>
              </Box>

              <Dialog open={paymentDialogOpen} onClose={handleClosePaymentDialog}>
                <DialogTitle>
                  Add Payment
                  <IconButton
                    edge="end"
                    color="inherit"
                    onClick={handleClosePaymentDialog}
                    aria-label="close"
                    sx={{ position: 'absolute', right: 8, top: 8 }}
                  >
                    <CloseIcon />
                  </IconButton>
                </DialogTitle>
                <DialogContent>
              <TextField
                autoFocus
                margin="dense"
                id="amount"
                label="Amount"
                type="number"
                fullWidth
                variant="outlined"
                value={paymentAmount}
                onChange={(e) => setPaymentAmount(Number(e.target.value))}
              />
              <TextField
                margin="dense"
                id="method"
                label="Payment Method"
                select
                fullWidth
                variant="outlined"
                value={paymentMethod}
                onChange={(e) => setPaymentMethod(e.target.value as PaymentMethods)}
                SelectProps={{
                  native: true,
                }}
              >
                {Object.values(PaymentMethods).map((method) => (
                  <option key={method} value={method}>
                    {method}
                  </option>
                ))}
              </TextField>
              <TextField
                margin="dense"
                id="reference"
                label="Payment Reference Number"
                type="text"
                fullWidth
                variant="outlined"
                value={paymentReference}
                onChange={(e) => setPaymentReference(e.target.value)}
              />
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleClosePaymentDialog}>Cancel</Button>
                  <Button onClick={handleAddPayment}>Add Payment</Button>
                </DialogActions>
              </Dialog>

              {/* Payments list */}
              {payments.length > 0 && (
                <Paper elevation={3} sx={{ mt: 4, p: 3 }}>
                  <Typography variant="h6" gutterBottom>
                    Payments
                  </Typography>
                  <List>
                    {payments.map((payment) => (
                      <ListItem key={payment._id}>
                        <Typography variant="body1">
                          Amount: ₹{payment.amount}, Method: {payment.method}, Reference: {payment.referenceNumber}, Date: {new Date(payment.createdAt).toLocaleDateString()}
                        </Typography>
                      </ListItem>
                    ))}
                  </List>
                </Paper>
              )}
            </Paper>
          </Grid>
        )}
      </Grid>
      </Box>
  );
};

export default RoomBillingList;
