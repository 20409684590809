import api from './api';

// Get all hotels
export const getAllHotels = async () => {
  try {
    const response = await api.get('/hotels');
    return response.data;
  } catch (error) {
    console.error('Error fetching all hotels', error);
    throw error; // Re-throw error to be handled by the calling code
  }
};

// Create a new hotel
export const createHotel = async (data: any) => {
  try {
    const response = await api.post('/hotels', data);
    return response.data;
  } catch (error) {
    console.error('Error creating hotel', error);
    throw error;
  }
};

// Update an existing hotel
export const updateHotel = async (id: string, data: any) => {
  try {
    const response = await api.put(`/hotels/${id}`, data);
    return response.data;
  } catch (error) {
    console.error(`Error updating hotel with ID ${id}`, error);
    throw error;
  }
};

// Delete a hotel by ID
export const deleteHotel = async (id: string) => {
  try {
    const response = await api.delete(`/hotels/${id}`);
    return response.data;
  } catch (error) {
    console.error(`Error deleting hotel with ID ${id}`, error);
    throw error;
  }
};
