// Utility function to decode a base64-encoded string
const decodeBase64 = (str: string): string => {
    // Replace '-' and '_' with '+' and '/' to decode base64url format
    const base64 = str.replace(/-/g, '+').replace(/_/g, '/');
    const decoded = atob(base64);
    return decoded;
};

// Utility function to parse the JWT payload
const parseJwtPayload = (token: string): any => {
    // JWT token is composed of three parts separated by dots
    const parts = token.split('.');
    if (parts.length !== 3) {
        throw new Error('Invalid JWT token');
    }

    // Decode the payload (second part of the token)
    const payload = decodeBase64(parts[1]);
    return JSON.parse(payload);
};

export const isAuthenticated = (): boolean => {
    // Retrieve the token from local storage
    const token = localStorage.getItem('token');

    if (token) {
        try {
            // Parse the JWT payload
            const payload = parseJwtPayload(token);
            
            // Check for the expiry time (exp claim)
            const expiryTime = payload.exp * 1000; // exp is in seconds, convert to milliseconds
            const currentTime = Date.now();

            // If current time is before the expiry time, the token is valid
            return currentTime < expiryTime;
        } catch (e) {
            console.error('Failed to parse token', e);
        }
    }

    // If token is not found or parsing failed, authentication fails
    return false;
};
