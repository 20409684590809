import { getHotelIdFromLocalStorage } from '../utils/localStorageUtils';
import api from './api';

// Get all menu items for a specific hotel with pagination and filtering
export const getMenuItems = async (page: number = 1, count: number = 10, filterData: any = {}) => {
  const hotelId = getHotelIdFromLocalStorage();
  if (!hotelId) throw new Error('No hotel selected');

  try {
    const response = await api.get('/menu', {
      params: {
        hotelId,
        page,
        count,
        ...filterData, // Include any additional filter data
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching menu items', error);
    throw error; // Re-throw error to be handled by the calling code
  }
};

// Get a specific menu item by ID for a specific hotel
export const getMenuItemById = async (id: string) => {
  const hotelId = getHotelIdFromLocalStorage();
  if (!hotelId) throw new Error('No hotel selected');

  try {
    const response = await api.get(`/menu/${id}`, {
      params: { hotelId },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching menu item details', error);
    throw error;
  }
};

// Create a new menu item for a specific hotel
export const createMenuItem = async (data: any) => {
  const hotelId = getHotelIdFromLocalStorage();
  if (!hotelId) throw new Error('No hotel selected');

  try {
    const response = await api.post('/menu', { ...data, hotelId });
    return response.data;
  } catch (error) {
    console.error('Error creating menu item', error);
    throw error;
  }
};

// Update an existing menu item for a specific hotel
export const updateMenuItem = async (id: string, data: any) => {
  const hotelId = getHotelIdFromLocalStorage();
  if (!hotelId) throw new Error('No hotel selected');

  try {
    const response = await api.put(`/menu/${id}`, { ...data, hotelId });
    return response.data;
  } catch (error) {
    console.error('Error updating menu item', error);
    throw error;
  }
};

// Delete a menu item for a specific hotel
export const deleteMenuItem = async (id: string) => {
  const hotelId = getHotelIdFromLocalStorage();
  if (!hotelId) throw new Error('No hotel selected');

  try {
    const response = await api.delete(`/menu/${id}`, {
      params: { hotelId },
    });
    return response.data;
  } catch (error) {
    console.error('Error deleting menu item', error);
    throw error;
  }
};

// Search for menu items by name or other criteria for a specific hotel
export const searchMenuItems = async (query: string, filterData: any = {}) => {
  const hotelId = getHotelIdFromLocalStorage();
  if (!hotelId) throw new Error('No hotel selected');

  try {
    const response = await api.get('/menu/search', {
      params: {
        q: query,
        hotelId,
        ...filterData, // Include any additional filter data
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error searching menu items', error);
    throw error;
  }
};
