import { getHotelIdFromLocalStorage } from '../utils/localStorageUtils';
import api from './api';

// Get all bills for the current hotel with pagination and filtering
export const getBills = async (page: number = 1, count: number = 10, filterData: any = {}) => {
  const hotelId = getHotelIdFromLocalStorage();
  console.log({hotelId})

  try {
    const response = await api.get('/bills', {
      params: {
        hotelId,
        page,
        count,
        ...filterData, // Spread filterData to include additional filters
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching bills', error);
    throw error; // Re-throw error to be handled by the calling code
  }
};

// Get all bills for the current hotel with pagination and filtering
export const getAllBills = async (filterData: any = {}) => {
  const hotelId = getHotelIdFromLocalStorage();
  console.log({hotelId})

  try {
    const response = await api.get('/bills/getAllBills', {
      params: {
        hotelId,
        ...filterData, // Spread filterData to include additional filters
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching bills', error);
    throw error; // Re-throw error to be handled by the calling code
  }
};

// Get a specific bill by ID for the current hotel
export const getBillById = async (id: string) => {
  const hotelId = getHotelIdFromLocalStorage();

  try {
    const response = await api.get(`/bills/${id}`, {
      params: { hotelId },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching bill details', error);
    throw error;
  }
};


// Fetch bills by mobile number for the current hotel
export const getBillsByMobileNumber = async (mobileNumber: string) => {
  const hotelId = getHotelIdFromLocalStorage();

  try {
    const response = await api.get('/bills/getBillsByMobileNumber', {
      params: { mobileNumber, hotelId },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching bills by mobile number', error);
    throw error;
  }
};

// Create a new bill for the current hotel
export const createBill = async (data: any) => {
  const hotelId = getHotelIdFromLocalStorage();

  try {
    const response = await api.post('/bills/create', { ...data, hotelId });
    return response.data;
  } catch (error) {
    console.error('Error creating bill', error);
    throw error;
  }
};

// Generate a PDF for a specific bill for the current hotel
export const generateBillPdf = async (id: string) => {
  const hotelId = getHotelIdFromLocalStorage();

  try {
    const response = await api.get(`/bills/${id}/pdf`, {
      responseType: 'blob',
      params: { hotelId },
    });
    return response.data;
  } catch (error) {
    console.error('Error generating PDF', error);
    throw error;
  }
};
