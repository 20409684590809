import React, { useEffect, useState } from 'react';
import {
  Container,
  Typography,
  Button,
  Paper,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Snackbar,
  Alert,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Chip,
  Checkbox,
  ListItemText,
  FormHelperText,
} from '@mui/material';
import {
  createReservation,
  getReservations,
  cancelReservation,
} from '../../services/reservationService';
import ConfirmationDialog from '../Shared/ConfirmationDialog';
import { getRooms } from '../../services/roomService';

const ManageReservations: React.FC = () => {
  const [reservations, setReservations] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [open, setOpen] = useState<boolean>(false);
  const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error'>('success');
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [confirmAction, setConfirmAction] = useState<(() => void) | null>(null);
  const [selectedReservationId, setSelectedReservationId] = useState<string | null>(null);

  // State for reservation form
  const [selectedRoomIds, setSelectedRoomIds] = useState<string[]>([]);
  const [customerName, setCustomerName] = useState<string>('');
  const [mobileNumber, setMobileNumber] = useState<string>(''); // Added mobile number state
  const [reservationStartDate, setReservationStartDate] = useState<string>(new Date().toISOString().slice(0, 10));
  const [reservationEndDate, setReservationEndDate] = useState<string>(new Date().toISOString().slice(0, 10));
  const [rooms, setRooms] = useState<any[]>([]);

  const fetchReservations = async () => {
    try {
      const data = await getReservations();
      setReservations(data);
    } catch (err) {
      console.error('Error fetching reservations:', err);
    } finally {
      setLoading(false);
    }
  };

  const fetchRooms = async () => {
    try {
      const data = await getRooms();
      setRooms(data);
    } catch (err) {
      console.error('Error fetching rooms:', err);
    }
  };

  useEffect(() => {
    fetchReservations();
    fetchRooms();
  }, []);

  const handleCreateReservation = async () => {
    setLoading(true);
    try {
      const reservationData = {
        roomIds: selectedRoomIds,
        customerName,
        mobileNumber, // Include mobile number in reservation data
        reservationStartDate,
        reservationEndDate,
      };
      await createReservation(reservationData);
      setSnackbarSeverity('success');
      setSnackbarMessage('Reservation Created Successfully');
      setOpen(false);
      fetchReservations(); // Refresh reservations after creation
    } catch (err) {
      console.error('Error creating reservation:', err);
      setSnackbarSeverity('error');
      setSnackbarMessage('Failed to create reservation');
    } finally {
      setLoading(false);
      setSnackbarOpen(true);
      // Reset form fields
      setSelectedRoomIds([]);
      setCustomerName('');
      setMobileNumber(''); // Reset mobile number
      setReservationStartDate(new Date().toISOString().slice(0, 10));
      setReservationEndDate(new Date().toISOString().slice(0, 10));
    }
  };

  const handleCancelReservation = async (id: string) => {
    setLoading(true);
    try {
      await cancelReservation(id);
      setReservations((prev) => prev.filter((res) => res._id !== id));
      setSnackbarSeverity('success');
      setSnackbarMessage('Reservation Cancelled Successfully');
    } catch (err) {
      console.error('Error cancelling reservation:', err);
      setSnackbarSeverity('error');
      setSnackbarMessage('Failed to cancel reservation');
    } finally {
      setLoading(false);
      setSnackbarOpen(true);
    }
  };

  const handleOpenDialog = () => {
    setOpen(true);
  };

  const handleOpenConfirmDialog = (action: () => void) => {
    setConfirmAction(() => action);
    setIsDialogOpen(true);
  };

  const confirmDialogAction = () => {
    if (confirmAction) {
      confirmAction();
    }
    setIsDialogOpen(false);
  };

  if (loading) return <CircularProgress />;

  return (
    <Container maxWidth="lg" sx={{ mt: 4 }}>
      <Typography variant="h4" component="h1" gutterBottom sx={{ color: '#2C3E50', fontWeight: 'bold' }}>
        Manage Reservations
      </Typography>

      <Button variant="contained" color="primary" onClick={handleOpenDialog} sx={{ mb: 2 }}>
        Add Reservation
      </Button>

      <TableContainer component={Paper} sx={{ borderRadius: 2 }}>
        <Table>
          <TableHead>
            <TableRow sx={{ backgroundColor: '#34495e' }}>
              <TableCell sx={{ color: '#fff', fontWeight: 'bold' }}>Room IDs</TableCell>
              <TableCell sx={{ color: '#fff', fontWeight: 'bold' }}>Customer Name</TableCell>
              <TableCell sx={{ color: '#fff', fontWeight: 'bold' }}>Mobile Number</TableCell> {/* Added Mobile Number column */}
              <TableCell sx={{ color: '#fff', fontWeight: 'bold' }}>Start Date</TableCell>
              <TableCell sx={{ color: '#fff', fontWeight: 'bold' }}>End Date</TableCell>
              <TableCell sx={{ color: '#fff', fontWeight: 'bold' }}>Status</TableCell>
              <TableCell sx={{ color: '#fff', fontWeight: 'bold' }}>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {reservations.map((reservation) => (
              <TableRow key={reservation._id}>
                <TableCell>{reservation.roomIds.join(', ')}</TableCell>
                <TableCell>{reservation.customerName}</TableCell>
                <TableCell>{reservation.mobileNumber}</TableCell> {/* Display Mobile Number */}
                <TableCell>{new Date(reservation.reservationStartDate).toLocaleDateString()}</TableCell>
                <TableCell>{new Date(reservation.reservationEndDate).toLocaleDateString()}</TableCell>
                <TableCell>{reservation.status}</TableCell>
                <TableCell>
                  <Button variant="outlined" color="error" onClick={() => handleOpenConfirmDialog(() => handleCancelReservation(reservation._id))}>
                    Cancel
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>Create Reservation</DialogTitle>
        <DialogContent>
          <FormControl fullWidth required>
            <InputLabel>Room IDs</InputLabel>
            <Select
              multiple
              value={selectedRoomIds}
              onChange={(e) => setSelectedRoomIds(e.target.value as string[])}
              renderValue={(selected) => selected.map(id => id).join(', ')}
            >
              {rooms.map((room) => (
                <MenuItem key={room._id} value={room._id}>
                  <Checkbox checked={selectedRoomIds.indexOf(room._id) > -1} />
                  <ListItemText primary={room.name} />
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>Select one or more rooms</FormHelperText>
          </FormControl>

          <TextField
            margin="dense"
            label="Customer Name"
            fullWidth
            value={customerName}
            onChange={(e) => setCustomerName(e.target.value)}
            required
          />
          <TextField
            margin="dense"
            label="Mobile Number" // Added Mobile Number input
            fullWidth
            value={mobileNumber}
            onChange={(e) => setMobileNumber(e.target.value)}
            required
          />
          <TextField
            margin="dense"
            label="Start Date"
            type="date"
            fullWidth
            value={reservationStartDate}
            onChange={(e) => setReservationStartDate(e.target.value)}
            required
          />
          <TextField
            margin="dense"
            label="End Date"
            type="date"
            fullWidth
            value={reservationEndDate}
            onChange={(e) => setReservationEndDate(e.target.value)}
            required
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={() => { handleCreateReservation(); setOpen(false); }} color="primary">
            Create Reservation
          </Button>
        </DialogActions>
      </Dialog>

      <ConfirmationDialog
        open={isDialogOpen}
        onCancel={() => setIsDialogOpen(false)}
        onConfirm={confirmDialogAction}
        title="Confirm Cancellation"
        message="Are you sure you want to cancel this reservation?"
      />

      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={() => setSnackbarOpen(false)}>
        <Alert onClose={() => setSnackbarOpen(false)} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default ManageReservations;
