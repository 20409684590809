import api from './api';

// Get all taxes
export const getAllTaxes = async () => {
  try {
    const response = await api.get('/taxes');
    return response.data;
  } catch (error) {
    console.error('Error fetching all taxes', error);
    throw error; // Re-throw error to be handled by the calling code
  }
};

// Create a new tax
export const createTax = async (data: {
  name: string;
  rate: number;
  type: string;
  productCategory?: string;
}) => {
  try {
    const response = await api.post('/taxes', data);
    return response.data;
  } catch (error) {
    console.error('Error creating tax', error);
    throw error;
  }
};

// Get a tax by ID
export const getTaxById = async (id: string) => {
  try {
    const response = await api.get(`/taxes/${id}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching tax by ID', error);
    throw error;
  }
};

// Update a tax by ID
export const updateTax = async (id: string, data: {
  name: string;
  rate: number;
  type: string;
  productCategory?: string;
}) => {
  try {
    const response = await api.put(`/taxes/${id}`, data);
    return response.data;
  } catch (error) {
    console.error('Error updating tax', error);
    throw error;
  }
};

// Delete a tax by ID
export const deleteTax = async (id: string) => {
  try {
    const response = await api.delete(`/taxes/${id}`);
    return response.data;
  } catch (error) {
    console.error('Error deleting tax', error);
    throw error;
  }
};

export const getTaxesForProductCategory = async (productCategory: string) => {
    try {
      const response = await api.get(`/taxes/by-category?productCategory=${productCategory}`);
      return response.data; // Assuming this returns an array of taxes
    } catch (error) {
      console.error('Error fetching taxes:', error);
      throw error;
    }
  };
