import React, { useState, useEffect } from "react";
import {
  Container,
  TextField,
  Button,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Grid,
  InputAdornment,
  Box,
} from "@mui/material";
import { Add, Remove } from "@mui/icons-material";
import Autocomplete from "@mui/material/Autocomplete";
import { getTaxesForProductCategory } from "../../services/taxService";
import { getCustomerByMobileNumber } from "../../services/customerService";
import { getMenuItems } from "../../services/menuService";
import { searchEmployees } from "../../services/employeeService";
import { createBill, generateBillPdf } from "../../services/billService";
import ConfirmationDialog from "../Shared/ConfirmationDialog";
import { getTables } from "../../services/tableService";

const TaxInvoice: React.FC = () => {
  const [customerName, setCustomerName] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [address, setAddress] = useState("");
  const [waiters, setWaiters] = useState<any[]>([]);
  const [tables, setTables] = useState<any[]>([]);
  const [selectedWaiter, setSelectedWaiter] = useState("");
  const [selectedTable, setSelectedTable] = useState("");
  const [menuItems, setMenuItems] = useState<any[]>([]);
  const [taxes, setTaxes] = useState<any[]>([]);
  const [totalDiscount, setTotalDiscount] = useState(0);  
  const [billId, setBillId] = useState<string | null>(null);
  const [pdfData, setPdfData] = useState<Blob | null>(null);
  const [invoiceItems, setInvoiceItems] = useState<any[]>([
    { item: null, quantity: 1, appliedTaxes: [] },
  ]);
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false); // State to control the dialog
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState<"success" | "error">("success");

  useEffect(() => {
    const fetchMenuItems = async () => {
      try {
        const response = await getMenuItems();
        setMenuItems(response);
      } catch (error) {
        console.error("Error fetching menu items:", error);
      }
    };

    const fetchTaxes = async () => {
      try {
        const response = await getTaxesForProductCategory("FOOD");
        setTaxes(response);
      } catch (error) {
        console.error("Error fetching taxes:", error);
      }
    };

    const fetchWaiters = async () => {
      try {
        const response = await searchEmployees("", { position: "Waiter" });
        setWaiters(response);
      } catch (error) {
        console.error("Error fetching waiters:", error);
      }
    };


    const fetchTables = async () => {
      try {
        const response = await getTables();
        setTables(response);
      } catch (error) {
        console.error("Error fetching waiters:", error);
      }
    };

    fetchMenuItems();
    fetchTaxes();
    fetchWaiters();
    fetchTables();
  }, []);

  const handleCustomerSearch = async (mobile: string) => {
    try {
      const response = await getCustomerByMobileNumber(mobile);
      if (response) {
        setCustomerName(response.customerName || "");
        setAddress(response.address || "");
      }
    } catch (error) {
      console.error("Error fetching customer data", error);
    }
  };

  const handleGeneratePdf = async () => {
    if (billId) {
      try {
        const response = await generateBillPdf(billId);
        setPdfData(response);
      } catch (error) {
        console.error("Error generating PDF", error);
      }
    } else {
      alert("Please create the invoice first.");
    }
  };

  const downloadPdf = () => {
    if (pdfData) {
      const url = window.URL.createObjectURL(pdfData);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "invoice.pdf");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const printPdf = () => {
    if (pdfData) {
      const url = window.URL.createObjectURL(pdfData);
      window.open(url, "_blank");
      setTimeout(() => {
        window.URL.revokeObjectURL(url);
      }, 1000);
    }
  };

  const handleAddItem = () => {
    setInvoiceItems([...invoiceItems, { item: null, quantity: 1, appliedTaxes: [] }]);
  };

  const handleRemoveItem = (index: number) => {
    setInvoiceItems(invoiceItems.filter((_, i) => i !== index));
  };

  const handleItemChange = (index: number, item: any) => {
    const newItems = [...invoiceItems];
    newItems[index].item = item;
    newItems[index].quantity = 1;
    newItems[index].appliedTaxes = item
      ? taxes.map((tax) => ({
          taxType: tax.type,
          rate: tax.rate,
          calculatedAmount: 0,
          editableRate: tax.rate,
        }))
      : [];
    setInvoiceItems(newItems);
    recalculateItemTaxes(index, 1, totalDiscount, item, newItems);
  };

  const handleQuantityChange = (index: number, quantity: number) => {
    if (quantity < 1) quantity = 1;
    const newItems = [...invoiceItems];
    newItems[index].quantity = quantity;
    setInvoiceItems(newItems);
    recalculateItemTaxes(index, quantity, totalDiscount, newItems[index].item, newItems);
  };

  const handleDiscountChange = (discount: number) => {
    if (isNaN(discount) || discount < 0) discount = 0;
    setTotalDiscount(discount);

    const newItems = [...invoiceItems];
    newItems.forEach((item, index) => {
      recalculateItemTaxes(index, item.quantity, discount, item.item, newItems);
    });
  };

  const handleTaxRateChange = (itemIndex: number, taxType: string, newRate: number) => {
    const newItems = [...invoiceItems];
    const tax = newItems[itemIndex].appliedTaxes.find((t: any) => t.taxType === taxType);
    if (tax) {
      tax.editableRate = newRate;
      recalculateItemTaxes(
        itemIndex,
        newItems[itemIndex].quantity,
        totalDiscount,
        newItems[itemIndex].item,
        newItems
      );
    }
  };

  const recalculateItemTaxes = (
    index: number,
    quantity: number,
    discount: number,
    item: any,
    itemsArray: any[]
  ) => {
    if (item) {
      const discountedPricePerUnit = item.price * (1 - discount / 100);
      const totalPrice = discountedPricePerUnit * quantity;

      itemsArray[index].appliedTaxes.forEach((tax: any) => {
        tax.calculatedAmount = (totalPrice * tax.editableRate) / 100;
      });
    }
    setInvoiceItems(itemsArray);
  };

  const calculateTotals = () => {
    let totalDiscountAmount = 0;
    let totalTax = 0;
    let grandTotal = 0;
    let totalSgst = 0;
    let totalCgst = 0;

    invoiceItems.forEach((item) => {
      if (item.item) {
        const itemTotal = item.item.price * item.quantity;
        const itemDiscount = (itemTotal * totalDiscount) / 100;
        const itemNetPrice = itemTotal - itemDiscount;

        totalDiscountAmount += itemDiscount;
        item.appliedTaxes.forEach((tax: any) => {
          if (tax.taxType === "SGST") totalSgst += tax.calculatedAmount;
          if (tax.taxType === "CGST") totalCgst += tax.calculatedAmount;
          totalTax += tax.calculatedAmount;
        });
        grandTotal += itemNetPrice + item.appliedTaxes.reduce((acc: any, tax: any) => acc + tax.calculatedAmount, 0);
      }
    });

    return {
      totalDiscountAmount,
      totalTax,
      totalSgst,
      totalCgst,
      grandTotal,
    };
  };

  const { totalDiscountAmount, totalTax, totalSgst, totalCgst, grandTotal } = calculateTotals();

  const openConfirmationDialog = () => {
    setConfirmationDialogOpen(true); // Open the dialog
  };

  const closeConfirmationDialog = () => {
    setConfirmationDialogOpen(false); // Close the dialog
  };

  const confirmInvoiceSubmission = async () => {
    closeConfirmationDialog(); // Close the dialog after confirmation
    const invoiceData = {
      customerName,
      mobileNumber,
      address,
      waiterId: selectedWaiter,
      tableId: selectedTable,
      items: invoiceItems.map((item) => {
          const itemTotal = item.item.price * item.quantity;
          const itemDiscount = (itemTotal * totalDiscount) / 100;
          const itemNetPrice = itemTotal - itemDiscount;
  
          return {
              name: item.item.name,
              type: "FOOD",
              quantity: item.quantity,
              price: item.item.price,
              // Map taxes correctly
              taxes: item.appliedTaxes.map((tax:any) => ({
                  name: tax.taxType, // Add name field
                  amount: tax.calculatedAmount, // Add amount field
                  rate: tax.editableRate, // Optional, if needed
              })),
              taxableAmount: itemNetPrice,
          };
      }),
      discountAmount: totalDiscountAmount,
      discountPercentage: totalDiscount,
      totalAmount: invoiceItems.reduce((total, item) => total + item.item.price * item.quantity, 0),
      amountAfterDiscount: invoiceItems.reduce((total, item) => {
          const itemTotal = item.item.price * item.quantity;
          const itemDiscount = (itemTotal * totalDiscount) / 100;
          return total + itemTotal - itemDiscount;
      }, 0),
      grandTotal: Math.round(grandTotal),
      tax: { sgst: totalSgst, cgst: totalCgst, totalTax },
    };

    try {
      const response = await createBill(invoiceData);
      setSnackbarMessage("Invoice submitted successfully!");
      setSnackbarSeverity("success");
      setSnackbarOpen(true);
      setBillId(response._id);

    } catch (error) {
      console.error("Error submitting invoice:", error);
      setSnackbarMessage("Error submitting invoice.");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  };

  return (
    <Container sx={{ mt: 4 }}>
      <Paper elevation={4} sx={{ p: 4, backgroundColor: "#f9f9f9", mb: 4 }}>
        <Typography variant="h4" align="center" gutterBottom sx={{ color: "#1976d2" }}>
          Tax Invoice
        </Typography>

        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Mobile Number"
              value={mobileNumber}
              onChange={(e) => setMobileNumber(e.target.value)}
              onBlur={() => handleCustomerSearch(mobileNumber)}
              fullWidth
              variant="outlined"
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              label="Customer Name"
              value={customerName}
              onChange={(e) => setCustomerName(e.target.value)}
              fullWidth
              variant="outlined"
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              label="Address"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
              fullWidth
              variant="outlined"
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <Autocomplete
              options={waiters}
              getOptionLabel={(option) => option.name}
              value={selectedWaiter}
              onChange={(event, newValue) => {
                setSelectedWaiter(newValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select Waiter"
                  variant="outlined"
                  required
                />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
          <Autocomplete
              options={tables}
              getOptionLabel={(option) => option.tableNumber}
              value={selectedTable}
              onChange={(event, newValue) => {
                setSelectedTable(newValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select Table"
                  variant="outlined"
                  required
                />
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              label="Global Discount (%)"
              value={totalDiscount}
              onChange={(e) => handleDiscountChange(parseFloat(e.target.value) || 0)}
              fullWidth
              variant="outlined"
              type="number"
              InputProps={{
                endAdornment: <InputAdornment position="end">%</InputAdornment>,
                inputProps: { min: 0, max: 100 },
              }}
            />
          </Grid>
        </Grid>
      </Paper>

      <TableContainer component={Paper}>
        <Table>
          <TableHead sx={{ backgroundColor: "#e0e0e0" }}>
            <TableRow>
              <TableCell sx={{ width: "25%" }}>Item</TableCell>
              <TableCell sx={{ width: "10%" }}>Quantity</TableCell>
              <TableCell sx={{ width: "10%" }}>Price</TableCell>
              <TableCell sx={{ width: "10%" }}>Discounted Price</TableCell>
              <TableCell sx={{ width: "10%" }}>SGST (%)</TableCell>
              <TableCell sx={{ width: "10%" }}>CGST (%)</TableCell>
              <TableCell sx={{ width: "10%" }}>Total GST</TableCell>
              <TableCell sx={{ width: "5%" }}>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {invoiceItems.map((invoiceItem, index) => (
              <TableRow key={index}>
                <TableCell>
                  <Autocomplete
                    options={menuItems}
                    getOptionLabel={(option) => option.name}
                    value={invoiceItem.item}
                    onChange={(e, newValue) => handleItemChange(index, newValue)}
                    renderInput={(params) => (
                      <TextField {...params} label="Select Item" variant="outlined" />
                    )}
                  />
                </TableCell>

                <TableCell>
                  <TextField
                    type="number"
                    value={invoiceItem.quantity}
                    onChange={(e) => handleQuantityChange(index, parseInt(e.target.value) || 1)}
                    variant="outlined"
                    inputProps={{ min: 1 }}
                    fullWidth
                  />
                </TableCell>

                <TableCell>
                  {invoiceItem.item ? invoiceItem.item.price.toFixed(2) : "0.00"}
                </TableCell>
                <TableCell>
                  {invoiceItem.item
                    ? (invoiceItem.item.price * (1 - totalDiscount / 100)).toFixed(2)
                    : "0.00"}
                </TableCell>

                <TableCell>
                  {invoiceItem.appliedTaxes
                    .filter((tax: any) => tax.taxType === "SGST")
                    .map((tax: any) => (
                      <TextField
                        key={tax.taxType}
                        type="number"
                        value={tax.editableRate}
                        onChange={(e) =>
                          handleTaxRateChange(index, tax.taxType, parseFloat(e.target.value) || 0)
                        }
                        variant="outlined"
                        inputProps={{ min: 0 }}
                        InputProps={{
                          endAdornment: <InputAdornment position="end">%</InputAdornment>,
                        }}
                        fullWidth
                        disabled
                      />
                    ))}
                </TableCell>

                <TableCell>
                  {invoiceItem.appliedTaxes
                    .filter((tax: any) => tax.taxType === "CGST")
                    .map((tax: any) => (
                      <TextField
                        key={tax.taxType}
                        type="number"
                        value={tax.editableRate}
                        onChange={(e) =>
                          handleTaxRateChange(index, tax.taxType, parseFloat(e.target.value) || 0)
                        }
                        variant="outlined"
                        inputProps={{ min: 0 }}
                        InputProps={{
                          endAdornment: <InputAdornment position="end">%</InputAdornment>,
                        }}
                        fullWidth
                        disabled
                      />
                    ))}
                </TableCell>

                <TableCell>
                  {invoiceItem.appliedTaxes
                    .reduce((acc: any, tax: any) => acc + tax.calculatedAmount, 0)
                    .toFixed(2)}
                </TableCell>

                <TableCell>
                  <IconButton onClick={() => handleRemoveItem(index)} color="error">
                    <Remove />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
            <TableRow>
              <TableCell colSpan={8} align="center">
                <Button onClick={handleAddItem} variant="contained" startIcon={<Add />}>
                  Add Item
                </Button>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      <Paper elevation={2} sx={{ mt: 4, p: 2, backgroundColor: "#f1f1f1" }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Typography variant="h6">Total Discount: ₹{totalDiscountAmount.toFixed(2)}</Typography>
            <Typography variant="h6">Total SGST: ₹{totalSgst.toFixed(2)}</Typography>
            <Typography variant="h6">Total CGST: ₹{totalCgst.toFixed(2)}</Typography>
            <Typography variant="h6">Total Tax: ₹{totalTax.toFixed(2)}</Typography>
          </Grid>
          <Grid item xs={12} sm={6} sx={{ textAlign: "right" }}>
            <Typography variant="h5" sx={{ color: "#1976d2" }}>
              Grand Total: ₹{grandTotal.toFixed(0)}
            </Typography>
          </Grid>
        </Grid>
      </Paper>

      <Box sx={{ display: "flex", justifyContent: "space-between", mt: 4 }}>
  <Button
    variant="contained"
    color="primary"
    onClick={openConfirmationDialog} // Open dialog on submit
  >
    Submit Invoice
  </Button>

  {billId && (
    <>
      <Button variant="contained" color="secondary" onClick={handleGeneratePdf}>
        Generate PDF
      </Button>
      {pdfData && (
        <>
          <Button variant="outlined" onClick={downloadPdf}>
            Download PDF
          </Button>
          <Button variant="outlined" onClick={printPdf}>
            Print PDF
          </Button>
        </>
      )}
    </>
  )}
</Box>


      {/* Confirmation dialog */}
      <ConfirmationDialog
        open={confirmationDialogOpen}
        onConfirm={confirmInvoiceSubmission} // Confirm action
        onCancel={closeConfirmationDialog} // Cancel action
        title="Confirm Invoice Submission"
        message="Are you sure you want to submit this invoice?"
      />
    </Container>
  );
};

export default TaxInvoice;
