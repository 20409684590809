import React, { useState, useEffect } from "react";
import {
  Container,
  TextField,
  Button,
  Typography,
  Box,
  Paper,
  Divider,
  Grid,
  Autocomplete,
  IconButton,
  InputAdornment,
} from "@mui/material";
import { Add, Remove } from "@mui/icons-material";
import { createBill, generateBillPdf } from "../../services/billService";
import { getMenuItems } from "../../services/menuService";
import { useNavigate } from "react-router-dom";
import { getHotelIdFromLocalStorage } from "../../utils/localStorageUtils";
import ConfirmationDialog from "../Shared/ConfirmationDialog";
import { searchEmployees } from "../../services/employeeService";
import SnackbarComponent from "../Shared/SnackbarComponent";

const BillForm: React.FC = () => {
  const [customerName, setCustomerName] = useState("");
  const [address, setAddress] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [items, setItems] = useState<any[]>([]);
  const [menuItems, setMenuItems] = useState<any[]>([]);
  const [discountPercentage, setDiscountPercentage] = useState<number>(0);
  const [billId, setBillId] = useState<string | null>(null);
  const [pdfData, setPdfData] = useState<Blob | null>(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [confirmAction, setConfirmAction] = useState<(() => void) | null>(null);
  const [mobileError, setMobileError] = useState<string>("");
  const [waiters, setWaiters] = useState<any[]>([]); // State for waiters
  const [selectedWaiter, setSelectedWaiter] = useState<any>(null);
  const [waiterError, setWaiterError] = useState<boolean>(false); // Error state for Waiter
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState<"success" | "error">("success");
  
  const navigate = useNavigate();

  const sgst = 2.5;
  const cgst = 2.5;

  useEffect(() => {
    const fetchMenuItems = async () => {
      try {
        const response = await getMenuItems();
        setMenuItems(response);
      } catch (error) {
        console.error("Error fetching menu items", error);
      }
    };

    fetchMenuItems();


    const fetchWaiters = async () => {
      try {
        const hotelId = getHotelIdFromLocalStorage();
        const response = await searchEmployees('', { position: "Waiter" });
        setWaiters(response);
      } catch (error) {
        console.error("Error fetching waiters", error);
      }
    };
    fetchWaiters();

  }, []);

  const handleAddItem = () => {
    setItems([...items, { menuItem: null, quantity: 0 }]);
  };

  const handleRemoveItem = (index: number) => {
    setItems(items.filter((_, i) => i !== index));
  };

  const handleMenuItemChange = (index: number, item: any) => {
    const newItems = [...items];
    newItems[index].menuItem = item;
    newItems[index].quantity = 0;
    setItems(newItems);
  };

  const handleQuantityChange = (index: number, quantity: number) => {
    const newItems = [...items];
    newItems[index].quantity = quantity;
    setItems(newItems);
  };

  const handleDiscountPercentageChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setDiscountPercentage(parseFloat(event.target.value) || 0);
  };

  const handleMobileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (/^\d{0,10}$/.test(value)) {
      setMobileNumber(value);
      if (value.length === 10) {
        setMobileError("");
      } else {
        setMobileError("Mobile number must be 10 digits");
      }
    } else {
      setMobileError("Mobile number must contain only digits");
    }
  };

  const calculateTotalAmount = () =>
    items.reduce(
      (total, item) => total + (item.menuItem?.price || 0) * item.quantity,
      0
    );

  const calculateDiscountAmount = (totalAmount: number) =>
    (totalAmount * discountPercentage) / 100;
  const calculateSGST = (amount: number) => (amount * sgst) / 100;
  const calculateCGST = (amount: number) => (amount * cgst) / 100;

  const saveBill = async () => {
    if (!selectedWaiter) {
      setWaiterError(true); // Set error if waiter is not selected
      return;
    }
    const totalAmount = calculateTotalAmount();
    const discountAmount = calculateDiscountAmount(totalAmount);
    const discountedAmount = totalAmount - discountAmount;
    const sgstAmount = calculateSGST(discountedAmount);
    const cgstAmount = calculateCGST(discountedAmount);

    const billData = {
      customerName,
      address,
      mobileNumber,
      waiterId:selectedWaiter._id,
      items: items.map((item) => ({
        name: item.menuItem?.name || "",
        quantity: item.quantity,
        price: item.menuItem?.price || 0,
      })),
      totalAmount: totalAmount,
      discountPercentage,
      discountAmount,
      sgst: sgstAmount,
      cgst: cgstAmount,
      hotelId: getHotelIdFromLocalStorage(),
    };

    try {
      const response = await createBill(billData);
      setSnackbarSeverity("success");
      setSnackbarMessage("Bill created successfully!");
      if(response){
        setBillId(response._id); 
      }
      navigate("/bills");
    } catch (error) {
      console.error("Error creating bill", error);
      setSnackbarSeverity("error");
      setSnackbarMessage("Error creating bill. Please try again.");
    } finally {
      setSnackbarOpen(true);
    }
  };

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    openConfirmationDialog(saveBill);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleGeneratePdf = async () => {
    if (billId) {
      try {
        const response = await generateBillPdf(billId);
        setPdfData(response);
        const url = window.URL.createObjectURL(new Blob([response.data]));
      } catch (error) {
        console.error("Error generating PDF", error);
      }
    } else {
      alert("Please create the bill first.");
    }
  };

  const downloadPdf = () => {
    if (pdfData) {
      const url = window.URL.createObjectURL(pdfData);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "bill.pdf");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const printPdf = () => {
    if (pdfData) {
      const url = window.URL.createObjectURL(pdfData);
      window.open(url, "_blank");
      setTimeout(() => {
        window.URL.revokeObjectURL(url);
      }, 1000);
    }
  };

  const openConfirmationDialog = (action: () => void) => {
    setConfirmAction(() => action);
    setIsDialogOpen(true);
  };

  const confirmDialogAction = () => {
    if (confirmAction) {
      confirmAction();
    }
    setIsDialogOpen(false);
  };

  const totalAmount = calculateTotalAmount();
  const discountAmount = calculateDiscountAmount(totalAmount);
  const discountedAmount = totalAmount - discountAmount;
  const sgstAmount = calculateSGST(discountedAmount);
  const cgstAmount = calculateCGST(discountedAmount);

  return (
    <Container maxWidth="md" sx={{ mt: 8 }}>
      <Paper elevation={4} sx={{ p: 4, borderRadius: 2 }}>
        <Typography variant="h4" align="center" gutterBottom>
          Create Bill
        </Typography>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <TextField
                label="Customer Name"
                fullWidth
                variant="outlined"
                value={customerName}
                onChange={(e) => setCustomerName(e.target.value)}
                required
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Address"
                fullWidth
                variant="outlined"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Mobile Number"
                fullWidth
                variant="outlined"
                value={mobileNumber}
                onChange={handleMobileChange}
                required
                error={Boolean(mobileError)}
                helperText={mobileError}
                inputProps={{ maxLength: 10 }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Discount Percentage"
                type="number"
                fullWidth
                variant="outlined"
                value={discountPercentage}
                onChange={handleDiscountPercentageChange}
                inputProps={{ min: 0 }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
            <Autocomplete
                options={waiters}
                getOptionLabel={(option) => option.name}
                value={selectedWaiter}
                onChange={(event, newValue) => {
                  setSelectedWaiter(newValue);
                  setWaiterError(!newValue); // Remove error when a waiter is selected
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select Waiter"
                    variant="outlined"
                    required
                    error={waiterError}
                    helperText={waiterError ? "Waiter selection is required" : ""}
                  />
                )}
              />
            </Grid>
          </Grid>
          <Divider sx={{ my: 4 }} />
          <Typography variant="h5" gutterBottom>
            Items
          </Typography>
          {items.map((item, index) => (
            <Grid container spacing={3} key={index} alignItems="center">
              <Grid item xs={6} sm={5}>
                <Autocomplete
                  options={menuItems}
                  getOptionLabel={(option) => option.name}
                  value={item.menuItem || null}
                  onChange={(event, newValue) =>
                    handleMenuItemChange(index, newValue)
                  }
                  renderInput={(params) => (
                    <TextField {...params} label="Menu Item" variant="outlined" />
                  )}
                />
              </Grid>
              <Grid item xs={3} sm={2}>
                <TextField
                  label="Quantity"
                  type="number"
                  fullWidth
                  variant="outlined"
                  value={item.quantity}
                  onChange={(e) => handleQuantityChange(index, parseInt(e.target.value, 10))}
                  inputProps={{ min: 0 }}
                />
              </Grid>
              <Grid item xs={3} sm={3}>
                <Typography variant="body1">
                  {item.menuItem?.price ? `₹${item.menuItem.price}` : ""}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={2}>
                <Button
                  variant="outlined"
                  color="error"
                  onClick={() => handleRemoveItem(index)}
                >
                  Remove
                </Button>
              </Grid>
            </Grid>
          ))}
          <Box sx={{ mt: 2 }}>
            <Button
              variant="outlined"
              onClick={handleAddItem}
              startIcon={<Add />}
            >
              Add Item
            </Button>
          </Box>
          <Divider sx={{ my: 4 }} />
          <Box sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}>
            <Typography variant="h6">Total Amount:</Typography>
            <Typography variant="h6">₹{totalAmount.toFixed(2)}</Typography>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}>
            <Typography variant="h6">Discount Amount:</Typography>
            <Typography variant="h6">₹{discountAmount.toFixed(2)}</Typography>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}>
            <Typography variant="h6">Discounted Amount:</Typography>
            <Typography variant="h6">₹{discountedAmount.toFixed(2)}</Typography>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}>
            <Typography variant="h6">SGST ({sgst}%):</Typography>
            <Typography variant="h6">₹{sgstAmount.toFixed(2)}</Typography>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}>
            <Typography variant="h6">CGST ({cgst}%):</Typography>
            <Typography variant="h6">₹{cgstAmount.toFixed(2)}</Typography>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}>
            <Typography variant="h5" color="primary">
              Grand Total:
            </Typography>
            <Typography variant="h5" color="primary">
              ₹{(discountedAmount + sgstAmount + cgstAmount).toFixed(0)}
            </Typography>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "space-between", mt: 4 }}>
            <Button type="submit" variant="contained" color="primary">
              Create Bill
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={handleGeneratePdf}
              disabled={!billId}
            >
              Generate PDF
            </Button>
          </Box>
          {pdfData && (
            <Box sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}>
              <Button variant="outlined" onClick={downloadPdf}>
                Download PDF
              </Button>
              <Button variant="outlined" onClick={printPdf}>
                Print PDF
              </Button>
            </Box>
          )}
        </form>
      </Paper>
      <ConfirmationDialog
        open={isDialogOpen}
        onCancel={() => setIsDialogOpen(false)}
        onConfirm={confirmDialogAction}
        title="Confirm Bill Creation"
        message="Are you sure you want to create this bill?"
      />
      <SnackbarComponent
        open={snackbarOpen}
        message={snackbarMessage}
        severity={snackbarSeverity}
        onClose={handleSnackbarClose}
      />
    </Container>
  );
};

export default BillForm;
