import api from './api';

// Fetch GST report based on date range
export const fetchGstReport = async (startDate: string, endDate: string) => {
  try {
    const response = await api.get('/gst/gst-report', {
      params: { startDate, endDate },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching GST report', error);
    throw error; // Re-throw error to be handled by the calling code
  }
};
