import React, { useEffect, useState } from 'react';
import {
  Container,
  Typography,
  Button,
  List,
  ListItem,
  Paper,
  Divider,
  Box,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Grid,
  MenuItem,
  Select,
  InputLabel,
  SelectChangeEvent,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { getBills, generateBillPdf } from '../../services/billService';
import { createPayment, getPaymentsByBillId } from '../../services/paymentService';
import { PaymentMethods } from '../../enums/PaymentMethods';
import PaginationComponent from '../Shared/PaginationControl';

const PAGE_SIZE_OPTIONS = [10, 20, 50, 100];

const BillList: React.FC = () => {
  const [bills, setBills] = useState<any[]>([]);
  const [selectedBill, setSelectedBill] = useState<any | null>(null);
  const [pdfData, setPdfData] = useState<Blob | null>(null);
  const [paymentDialogOpen, setPaymentDialogOpen] = useState<boolean>(false);
  const [paymentAmount, setPaymentAmount] = useState<number>(0);
  const [paymentMethod, setPaymentMethod] = useState<PaymentMethods>(PaymentMethods.CASH);
  const [referenceNumber, setReferenceNumber] = useState<string>('');
  const [payments, setPayments] = useState<any[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(PAGE_SIZE_OPTIONS[0]);

  useEffect(() => {
    const fetchBills = async (page: number, size: number) => {
      try {
        const response = await getBills(page, size);
        setBills(response.bills);
        setTotalPages(response.totalPages); // Update totalPages from response
      } catch (error) {
        console.error('Error fetching bills', error);
      }
    };
    fetchBills(currentPage, pageSize);
  }, [currentPage, pageSize]);

  const handleViewBill = async (billId: string) => {
    try {
      const bill = bills.find((bill) => bill._id === billId);
      setSelectedBill(bill);

      const response = await generateBillPdf(billId);
      setPdfData(response);

      const paymentsResponse = await getPaymentsByBillId(billId);
      setPayments(paymentsResponse);
    } catch (error) {
      console.error('Error generating PDF or fetching payments', error);
    }
  };

  const downloadPdf = () => {
    if (pdfData) {
      const url = window.URL.createObjectURL(pdfData);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'bill.pdf');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const printPdf = () => {
    if (pdfData) {
      const url = window.URL.createObjectURL(pdfData);
      window.open(url, '_blank');
      setTimeout(() => {
        window.URL.revokeObjectURL(url);
      }, 1000);
    }
  };

  const handleOpenPaymentDialog = () => {
    setPaymentDialogOpen(true);
  };

  const handleClosePaymentDialog = () => {
    setPaymentDialogOpen(false);
  };

  const handleAddPayment = async () => {
    try {
      if (selectedBill) {
        await createPayment({
          billId: selectedBill._id,
          amount: paymentAmount,
          method: paymentMethod,
          referenceNumber,
          mobileNumber: selectedBill.mobileNumber,
        });
        const paymentsResponse = await getPaymentsByBillId(selectedBill._id);
        setPayments(paymentsResponse);
        handleClosePaymentDialog();
        setPaymentAmount(0);
        setPaymentMethod(PaymentMethods.CASH);
        setReferenceNumber('');
      }
    } catch (error) {
      console.error('Error adding payment', error);
    }
  };

  const handlePageChange = (event: React.ChangeEvent<unknown>, page: number) => {
    setCurrentPage(page);
  };

  const handlePageSizeChange = (event: SelectChangeEvent<number>) => {
    setPageSize(event.target.value as number);
    setCurrentPage(1); // Reset to the first page when page size changes
  };

  return (
    <Box>
      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          <Paper elevation={3} sx={{ p: 2 }}>
            <Box sx={{ mb: 2, display: 'flex', alignItems: 'center' }}>
              <InputLabel id="page-size-label" sx={{ mr: 2 }}>
                Items per Page:
              </InputLabel>
              <Select
                labelId="page-size-label"
                value={pageSize}
                onChange={handlePageSizeChange}
                variant="outlined"
                sx={{ minWidth: 120 }}
              >
                {PAGE_SIZE_OPTIONS.map(size => (
                  <MenuItem key={size} value={size}>
                    {size}
                  </MenuItem>
                ))}
              </Select>
            </Box>
            <List>
              {bills.map((bill) => (
                <React.Fragment key={bill._id}>
                  <ListItem>
                    <Box flex={1}>
                      <Typography variant="body1">
                        {bill.customerName} - {bill.billNumber}
                      </Typography>
                      <Typography variant="body2" color="textSecondary">
                        Amount: ₹{bill.grandTotal} | Created At: {new Date(bill.createdAt).toLocaleDateString()}
                      </Typography>
                    </Box>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => handleViewBill(bill._id)}
                    >
                      View Details
                    </Button>
                  </ListItem>
                  <Divider />
                </React.Fragment>
              ))}
            </List>
            <PaginationComponent
              count={totalPages}
              page={currentPage}
              onPageChange={handlePageChange}
            />
          </Paper>
        </Grid>

        {selectedBill && (
          <Grid item xs={12} md={6}>
            <Paper elevation={3} sx={{ p: 2 }}>
              <Typography variant="h5" gutterBottom>
                Bill Details
              </Typography>
              <Typography variant="body1">
                <strong>Customer Name:</strong> {selectedBill.customerName}
              </Typography>
              <Typography variant="body1">
                <strong>Address:</strong> {selectedBill.address}
              </Typography>
              <Typography variant="body1">
                <strong>Mobile Number:</strong> {selectedBill.mobileNumber}
              </Typography>
              <Typography variant="h6" sx={{ mt: 3 }}>
                Items
              </Typography>
              <List>
                {selectedBill.items.map((item: any, index: number) => (
                  <ListItem key={index}>
                    <Typography variant="body1">
                      {item.name} - Quantity: {item.quantity}, Price: ₹{item.price}
                    </Typography>
                  </ListItem>
                ))}
              </List>

              <Divider sx={{ my: 3 }} />

              <Typography variant="body1">
                <strong>Discount Percentage:</strong> {selectedBill.discountPercentage}%
              </Typography>
              <Typography variant="body1">
                <strong>Discount Amount:</strong> ₹{selectedBill.discountAmount}
              </Typography>
              <Typography variant="body1">
                <strong>Amount After Discount:</strong> ₹{selectedBill.amountAfterDiscount}
              </Typography>
              <Typography variant="body1">
                <strong>Total Amount Before Tax:</strong> ₹{selectedBill.totalAmount}
              </Typography>
              <Typography variant="body1">
                <strong>Tax:</strong> SGST: ₹{selectedBill.tax.sgst}, CGST: ₹{selectedBill.tax.cgst}, Total Tax: ₹{selectedBill.tax.totalTax}
              </Typography>
              <Typography variant="body1">
                <strong>Grand Total:</strong> ₹{selectedBill.grandTotal}
              </Typography>

              <Divider sx={{ my: 3 }} />
              <Box sx={{ mt: 2, textAlign: 'center' }}>
                <Button variant="outlined" onClick={downloadPdf} sx={{ mr: 2 }}>
                  Download PDF
                </Button>
                <Button variant="outlined" onClick={printPdf}>
                  Print PDF
                </Button>
                <Button variant="contained" color="secondary" onClick={handleOpenPaymentDialog} sx={{ mt: 2 }}>
                  Add Payment
                </Button>
              </Box>

              <Dialog open={paymentDialogOpen} onClose={handleClosePaymentDialog}>
                <DialogTitle>
                  Add Payment
                  <IconButton
                    edge="end"
                    color="inherit"
                    onClick={handleClosePaymentDialog}
                    aria-label="close"
                    sx={{ position: 'absolute', right: 8, top: 8 }}
                  >
                    <CloseIcon />
                  </IconButton>
                </DialogTitle>
                <DialogContent>
                  <TextField
                    autoFocus
                    margin="dense"
                    id="amount"
                    label="Amount"
                    type="number"
                    fullWidth
                    variant="outlined"
                    value={paymentAmount}
                    onChange={(e) => setPaymentAmount(Number(e.target.value))}
                  />
                  <TextField
                    select
                    margin="dense"
                    id="method"
                    label="Payment Method"
                    fullWidth
                    variant="outlined"
                    value={paymentMethod}
                    onChange={(e) => setPaymentMethod(e.target.value as PaymentMethods)}
                    SelectProps={{
                      native: true,
                    }}
                  >
                    {Object.values(PaymentMethods).map((method) => (
                      <option key={method} value={method}>
                        {method}
                      </option>
                    ))}
                  </TextField>
                  <TextField
                    margin="dense"
                    id="referenceNumber"
                    label="Reference Number"
                    type="text"
                    fullWidth
                    variant="outlined"
                    value={referenceNumber}
                    onChange={(e) => setReferenceNumber(e.target.value)}
                  />
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleClosePaymentDialog}>Cancel</Button>
                  <Button onClick={handleAddPayment} color="primary" variant="contained">
                    Add Payment
                  </Button>
                </DialogActions>
              </Dialog>
              {payments.length > 0 && (
                <Paper elevation={3} sx={{ mt: 4, p: 3 }}>
                  <Typography variant="h6" gutterBottom>
                    Payments
                  </Typography>
                  <List>
                    {payments.map((payment) => (
                      <ListItem key={payment._id}>
                        <Typography variant="body1">
                          Amount: ₹{payment.amount}, Method: {payment.method}, Reference Number: {payment.referenceNumber}, Date: {new Date(payment.createdAt).toLocaleDateString()}
                        </Typography>
                      </ListItem>
                    ))}
                  </List>
                </Paper>
              )}
            </Paper>
          </Grid>
        )}
      </Grid>
      </Box>
  );
};

export default BillList;
