import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Container, TextField, Button, Typography, Box } from "@mui/material";
import { loginService } from "../../services/loginService";
import { getAllHotels } from "../../services/hotelService";
import { setDataToLocalStorage, setHotelIdToLocalStorage } from "../../utils/localStorageUtils";

const Login: React.FC = () => {
  const [mobileNumber, setMobileNumber] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  const handleLogin = async () => {
    try {
      const response = await loginService(mobileNumber, password); // Now using password
      const data = response;
      localStorage.clear();
      localStorage.setItem("userType", "user");
      localStorage.setItem("token", data.token);
      localStorage.setItem("user", JSON.stringify(data.user));

      const hotelList = await getAllHotels();
      if(hotelList.length>0){
        const hotelId = hotelList[0]._id;
        const isProduction = hotelList[0].isProduction ? hotelList[0].isProduction : true;
        setHotelIdToLocalStorage(hotelId);
        setDataToLocalStorage('isProduction', isProduction);
      }

      navigate("/");
    } catch (error) {
      console.error("Login failed:", error);
    }
  };

  return (
    <Container maxWidth="sm" sx={{ mt: 8, boxShadow: 3, borderRadius: 2, p: 4, backgroundColor: "#fff" }}>
      <Box>
        <Typography variant="h4" align="center" gutterBottom>
          Login
        </Typography>
        <TextField
          fullWidth
          margin="normal"
          label="Enter Mobile"
          type="number"
          variant="outlined"
          value={mobileNumber}
          onChange={(e) => setMobileNumber(e.target.value)}
        />
        <TextField
          fullWidth
          margin="normal"
          label="Enter Password"
          type="password"
          variant="outlined"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <Button
          fullWidth
          variant="contained"
          color="primary"
          sx={{ mt: 2 }}
          onClick={handleLogin}
        >
          Login
        </Button>
        <Typography align="center" sx={{ mt: 2 }}>
          Don't have an account? <Link to="/signup">Sign up</Link>
        </Typography>
      </Box>
    </Container>
  );
};

export default Login;
