import React, { useState } from 'react';
import {
  Container,
  Typography,
  TextField,
  Button,
  Box,
  Paper,
} from '@mui/material';
import { createRoom } from '../../services/roomService';
import { getHotelIdFromLocalStorage } from '../../utils/localStorageUtils';

const RoomForm: React.FC = () => {
  const [name, setName] = useState<string>('');
  const [type, setType] = useState<string>('');
  const [price, setPrice] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const hotelId = getHotelIdFromLocalStorage();

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    setLoading(true);
    try {
      const newRoom = { hotelId, name, type, price };
      await createRoom(newRoom);
      alert('Room added successfully');
      setName('');
      setType('');
      setPrice(0);
    } catch (error) {
      console.error('Error adding room:', error);
      alert('Failed to add room. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container maxWidth="sm">
      <Paper elevation={3} sx={{ padding: 4, marginTop: 4 }}>
        <Typography variant="h5" gutterBottom>
          Add New Room
        </Typography>
        <form onSubmit={handleSubmit}>
          <TextField
            label="Room Name"
            variant="outlined"
            fullWidth
            margin="normal"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
          <TextField
            label="Room Type"
            variant="outlined"
            fullWidth
            margin="normal"
            value={type}
            onChange={(e) => setType(e.target.value)}
            required
          />
          <TextField
            label="Price"
            variant="outlined"
            type="number"
            fullWidth
            margin="normal"
            value={price}
            onChange={(e) => setPrice(Number(e.target.value))}
            required
          />
          <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 2 }}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={loading}
            >
              {loading ? 'Adding...' : 'Add Room'}
            </Button>
          </Box>
        </form>
      </Paper>
    </Container>
  );
};

export default RoomForm;
