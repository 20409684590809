import React, { useState, useEffect } from 'react';
import {
  Container,
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Box,
  MenuItem,
} from '@mui/material';
import { Add, Edit, Delete } from '@mui/icons-material';
import { getRooms, createRoom, updateRoom, deleteRoom } from '../services/roomService';
import { RoomCategory, RoomType } from '../enums/RoomEnums';

const RoomManagement: React.FC = () => {
  const [rooms, setRooms] = useState<any[]>([]);
  const [showModal, setShowModal] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [currentRoom, setCurrentRoom] = useState<any>(null);
  const [roomName, setRoomName] = useState('');
  const [roomType, setRoomType] = useState<RoomType>(RoomType.Single);
  const [roomCategory, setRoomCategory] = useState<RoomCategory>(RoomCategory.Economy);
  const [amenities, setAmenities] = useState<string[]>([]);
  const [price, setPrice] = useState<number>(0);
  const [capacity, setCapacity] = useState<number>(0);
  const [floor, setFloor] = useState<number>(0);

  // Fetch rooms on component load
  useEffect(() => {
    const fetchRooms = async () => {
      try {
        const response = await getRooms();
        setRooms(response);
      } catch (error) {
        console.error('Error fetching rooms', error);
      }
    };
    fetchRooms();
  }, []);

  const handleOpenModal = (room?: any) => {
    if (room) {
      setEditMode(true);
      setCurrentRoom(room);
      setRoomName(room.name);
      setRoomType(room.type || RoomType.Single);
      setRoomCategory(room.category || RoomCategory.Economy);
      setAmenities(room.amenities || []);
      setPrice(room.price || 0);
      setCapacity(room.capacity || 0);
      setFloor(room.floor || 0);
    } else {
      setEditMode(false);
      setCurrentRoom(null);
      setRoomName('');
      setRoomType(RoomType.Single);
      setRoomCategory(RoomCategory.Economy);
      setAmenities([]);
      setPrice(0);
      setCapacity(0);
      setFloor(0);
    }
    setShowModal(true);
  };

  const handleCloseModal = () => setShowModal(false);

  const handleSave = async (event: React.FormEvent) => {
    event.preventDefault();

    const roomData = {
      name: roomName,
      type: roomType,
      price: price,
      category: roomCategory,
      amenities: amenities,
      capacity: capacity,
      floor: floor,
    };

    try {
      if (editMode && currentRoom) {
        await updateRoom(currentRoom._id, roomData);
      } else {
        await createRoom(roomData);
      }
      setShowModal(false);
      const response = await getRooms();
      setRooms(response);
    } catch (error) {
      console.error('Error saving room', error);
    }
  };

  const handleDelete = async (id: string) => {
    if (window.confirm('Are you sure you want to delete this room?')) {
      try {
        await deleteRoom(id);
        const response = await getRooms();
        setRooms(response);
      } catch (error) {
        console.error('Error deleting room', error);
      }
    }
  };

  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        Manage Rooms
      </Typography>
      <Button
        variant="contained"
        color="primary"
        startIcon={<Add />}
        onClick={() => handleOpenModal()}
      >
        Add Room
      </Button>

      <TableContainer component={Paper} sx={{ mt: 3 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Room Name</TableCell>
              <TableCell>Room Type</TableCell>
              <TableCell>Room Category</TableCell>
              <TableCell>Price</TableCell>
              <TableCell>Capacity</TableCell>
              <TableCell>Floor</TableCell>
              <TableCell>Amenities</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rooms.map((room) => (
              <TableRow key={room._id}>
                <TableCell>{room.name}</TableCell>
                <TableCell>{room.type}</TableCell>
                <TableCell>{room.category}</TableCell>
                <TableCell>{room.price}</TableCell>
                <TableCell>{room.capacity}</TableCell>
                <TableCell>{room.floor}</TableCell>
                <TableCell>{room.amenities.join(', ') || 'N/A'}</TableCell>
                <TableCell>
                  <IconButton color="primary" onClick={() => handleOpenModal(room)}>
                    <Edit />
                  </IconButton>
                  <IconButton color="secondary" onClick={() => handleDelete(room._id)}>
                    <Delete />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Modal for Adding/Editing Room */}
      <Dialog open={showModal} onClose={handleCloseModal} fullWidth maxWidth="sm">
        <DialogTitle>{editMode ? 'Edit Room' : 'Add Room'}</DialogTitle>
        <DialogContent>
          <Box component="form" onSubmit={handleSave} noValidate>
            <TextField
              margin="normal"
              fullWidth
              label="Room Name"
              value={roomName}
              onChange={(e) => setRoomName(e.target.value)}
              required
            />
            <TextField
              margin="normal"
              fullWidth
              label="Room Type"
              select
              value={roomType}
              onChange={(e) => setRoomType(e.target.value as RoomType)}
              required
            >
              {Object.values(RoomType).map((type) => (
                <MenuItem key={type} value={type}>
                  {type}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              margin="normal"
              fullWidth
              label="Room Category"
              select
              value={roomCategory}
              onChange={(e) => setRoomCategory(e.target.value as RoomCategory)}
              required
            >
              {Object.values(RoomCategory).map((category) => (
                <MenuItem key={category} value={category}>
                  {category}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              margin="normal"
              fullWidth
              label="Price"
              type="number"
              value={price}
              onChange={(e) => setPrice(parseFloat(e.target.value))}
              required
            />
            <TextField
              margin="normal"
              fullWidth
              label="Capacity"
              type="number"
              value={capacity}
              onChange={(e) => setCapacity(parseInt(e.target.value))}
              required
            />
            <TextField
              margin="normal"
              fullWidth
              label="Floor"
              type="number"
              value={floor}
              onChange={(e) => setFloor(parseInt(e.target.value))}
              required
            />
            <TextField
              margin="normal"
              fullWidth
              label="Amenities"
              value={amenities.join(', ')}
              onChange={(e) => setAmenities(e.target.value.split(',').map(amenity => amenity.trim()))}
            />
            <DialogActions>
              <Button onClick={handleCloseModal} color="secondary">
                Cancel
              </Button>
              <Button type="submit" color="primary">
                {editMode ? 'Save Changes' : 'Add Room'}
              </Button>
            </DialogActions>
          </Box>
        </DialogContent>
      </Dialog>
    </Container>
  );
};

export default RoomManagement;
