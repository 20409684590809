import React, { useState } from 'react';
import { Container, Typography, Tabs, Tab, Box } from '@mui/material';
import BillList from '../components/Bill/BillList';
import BillForm from '../components/Bill/BillForm';
import MenuManagementPage from '../components/menu/MenuManagementPage';
import TabComponent from '../components/TabComponent';

const BillPage: React.FC = () => {
  const [value, setValue] = useState<string>('list');

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  return (
      <Box>
        <Typography variant="h4" gutterBottom>
          Manage Restaurant
        </Typography>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="bill tabs"
          sx={{ mb: 2 }}
        >
          <Tab label="Bill List" value="list" />
          <Tab label="Add Bill" value="form" />
        </Tabs>
        <Box>
          <TabComponent value={value} index="list">
            <BillList />
          </TabComponent>
          <TabComponent value={value} index="form">
            <BillForm />
          </TabComponent>
        </Box>
      </Box>
  );
};

export default BillPage;
