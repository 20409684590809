import React, { useEffect, useState } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import { Box, Typography, TextField, CircularProgress, Paper, Grid } from '@mui/material';
import Tooltip from '../Shared/Tooltip';
import { getAllRoomAvailabilityBetweenDates } from '../../services/roomAvailabilityService';
import { getRooms } from '../../services/roomService';

type RoomAvailabilityMap = {
    roomId: string;
    isAvailable: boolean;
};

type RoomStatus = {
  [date: string]: {
    [roomId: string]: string; // Status of the room (available/unavailable)
  };
};

const getStatusColor = (status: string) => {
    switch (status) {
      case 'available':
        return 'green';
      case 'unavailable':
        return 'red';
      case 'partially booked':
        return 'yellow';
      default:
        return 'gray';
    }
};

const RoomStatusCalendar: React.FC = () => {
  const [roomStatus, setRoomStatus] = useState<RoomStatus>({});
  const [hoveredDate, setHoveredDate] = useState<string | null>(null);
  const [hoveredRoom, setHoveredRoom] = useState<string | null>(null);
  const [tooltipPosition, setTooltipPosition] = useState({ top: 0, left: 0 });
  const [roomIds, setRoomIds] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [startDate, setStartDate] = useState<string>('');
  const [endDate, setEndDate] = useState<string>('');

  const fetchRoomStatus = async () => {
    if (!startDate || !endDate) return;

    setLoading(true);
    setError(null);
    try {
      const rooms = await getRooms();
      const roomIds = rooms.map((room: any) => room._id);
      setRoomIds(roomIds);

      const roomStatusData = await getAllRoomAvailabilityBetweenDates(startDate, endDate);

      const transformedStatus: RoomStatus = {};
      roomStatusData.forEach(({ date, availability }: { date: string; availability: RoomAvailabilityMap[] }) => {
        transformedStatus[date] = {};
        availability.forEach(({ roomId, isAvailable }: RoomAvailabilityMap) => {
          transformedStatus[date][roomId] = isAvailable ? 'available' : 'unavailable';
        });
      });

      setRoomStatus(transformedStatus);
    } catch (error) {
      console.error('Error fetching room status or rooms', error);
      setError('Failed to fetch room status. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchRoomStatus();
  }, [startDate, endDate]);

  const handleDateHover = (date: string, room: string, eventElement: HTMLElement) => {
    setHoveredDate(date);
    setHoveredRoom(room);
    const rect = eventElement.getBoundingClientRect();
    setTooltipPosition({ top: rect.top - 40, left: rect.left + rect.width / 2 });
  };

  const events = Object.keys(roomStatus).flatMap(date =>
    Object.keys(roomStatus[date]).map(roomId => ({
      title: `Room: ${roomId} (${roomStatus[date][roomId]})`,
      start: date,
      allDay: true,
      extendedProps: { room: roomId, status: roomStatus[date][roomId] }
    }))
  );

  return (
    <Paper elevation={3} sx={{ padding: 4, margin: '20px auto', maxWidth: '1200px' }}>
      <Typography variant="h5" align="center" gutterBottom>
        Room Availability Calendar
      </Typography>

      <Grid container spacing={2} justifyContent="center" alignItems="center">
        <Grid item>
          <TextField
            label="Start Date"
            type="date"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
            sx={{ marginRight: 2 }}
          />
        </Grid>
        <Grid item>
          <TextField
            label="End Date"
            type="date"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
            sx={{ marginLeft: 2 }}
          />
        </Grid>
      </Grid>

      {loading && (
        <Box display="flex" justifyContent="center" alignItems="center" marginTop={4}>
          <CircularProgress />
        </Box>
      )}
      {error && <Typography color="error" align="center" sx={{ marginTop: 4 }}>{error}</Typography>}

      {!loading && !error && (
        <FullCalendar
          plugins={[dayGridPlugin, interactionPlugin]}
          initialView="dayGridMonth"
          events={events.map(event => ({
            ...event,
            color: getStatusColor(event.extendedProps.status),
          }))}
          eventMouseEnter={(info) => {
            const { room } = info.event.extendedProps;
            handleDateHover(info.event.startStr, room, info.el);
          }}
          eventMouseLeave={() => {
            setHoveredDate(null);
            setHoveredRoom(null);
          }}
          height="auto"
        />
      )}

      <Tooltip visible={!!hoveredDate && !!hoveredRoom} position={tooltipPosition}>
        Room: {hoveredRoom}, Status: {hoveredDate && hoveredRoom ? roomStatus[hoveredDate]?.[hoveredRoom] : 'N/A'}
      </Tooltip>
    </Paper>
  );
};

export default RoomStatusCalendar;
