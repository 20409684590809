import api from './api'; // Assuming api is already configured for your base URL

// Get all templates
export const getAllTemplates = async () => {
  try {
    const response = await api.get('/templates');
    return response.data;
  } catch (error) {
    console.error('Error fetching all templates', error);
    throw error;
  }
};

// Create a new template
export const createTemplate = async (data: {
  name: string;
  type: string;  // 'room' or 'restaurant'
  template: string; // The actual HTML template content
}) => {
  try {
    const response = await api.post('/templates', data);
    return response.data;
  } catch (error) {
    console.error('Error creating template', error);
    throw error;
  }
};

// Get a template by ID
export const getTemplateById = async (id: string) => {
  try {
    const response = await api.get(`/templates/${id}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching template by ID', error);
    throw error;
  }
};

// Update a template by ID
export const updateTemplate = async (id: string, data: {
  name: string;
  type: string;  // 'room' or 'restaurant'
  template: string; // The actual HTML template content
}) => {
  try {
    const response = await api.put(`/templates/${id}`, data);
    return response.data;
  } catch (error) {
    console.error('Error updating template', error);
    throw error;
  }
};

// Delete a template by ID
export const deleteTemplate = async (id: string) => {
  try {
    const response = await api.delete(`/templates/${id}`);
    return response.data;
  } catch (error) {
    console.error('Error deleting template', error);
    throw error;
  }
};

// Get templates by type (for filtering based on 'room' or 'restaurant')
export const getTemplatesByType = async (type: string) => {
  try {
    const response = await api.get(`/templates/by-type?type=${type}`);
    return response.data; // Assuming this returns an array of templates
  } catch (error) {
    console.error('Error fetching templates by type', error);
    throw error;
  }
};
