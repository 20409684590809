import React, { useState, useEffect } from 'react';
import {
  Container,
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Box,
  MenuItem,
  InputAdornment,
} from '@mui/material';
import { Add, Edit, Delete } from '@mui/icons-material';
import { createHotel, getAllHotels, updateHotel, deleteHotel } from '../../services/hotelService';
import { getAllTemplates } from '../../services/templateService';
import ConfirmationDialog from '../Shared/ConfirmationDialog';

const HotelManagement: React.FC = () => {
  const [hotels, setHotels] = useState<any[]>([]);
  const [templates, setTemplates] = useState<any[]>([]);
  const [showModal, setShowModal] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [currentHotel, setCurrentHotel] = useState<any>(null);
  const [name, setName] = useState('');
  const [address, setAddress] = useState('');
  const [mobile, setMobile] = useState('');
  const [additionalMobiles, setAdditionalMobiles] = useState<string[]>([]);
  const [email, setEmail] = useState('');
  const [restaurant, setRestaurant] = useState('');
  const [gstIn, setGstIn] = useState('');
  const [roomTemplate, setRoomTemplate] = useState('');
  const [restaurantTemplate, setRestaurantTemplate] = useState('');
  const [logoBase64, setLogoBase64] = useState<string | null>(null); // Store base64-encoded logo
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [hotelToDelete, setHotelToDelete] = useState<string | null>(null);

  useEffect(() => {
    const fetchHotels = async () => {
      try {
        const response = await getAllHotels();
        setHotels(response);
      } catch (error) {
        console.error('Error fetching hotels', error);
      }
    };
    fetchHotels();
  }, []);

  useEffect(() => {
    const fetchTemplates = async () => {
      try {
        const response = await getAllTemplates();
        setTemplates(response);
      } catch (error) {
        console.error('Error fetching templates', error);
      }
    };
    fetchTemplates();
  }, []);

  const handleOpenModal = (hotel?: any) => {
    if (hotel) {
      setEditMode(true);
      setCurrentHotel(hotel);
      setName(hotel.name);
      setAddress(hotel.address);
      setMobile(hotel.mobile);
      setAdditionalMobiles(hotel.additionalMobiles || []);
      setEmail(hotel.email || '');
      setRestaurant(hotel.restaurant || '');
      setGstIn(hotel.gstIn);
      setRoomTemplate(hotel.roomTemplate || '');
      setRestaurantTemplate(hotel.restaurantTemplate || '');
    } else {
      setEditMode(false);
      setCurrentHotel(null);
      setName('');
      setAddress('');
      setMobile('');
      setAdditionalMobiles([]);
      setEmail('');
      setRestaurant('');
      setGstIn('');
      setRoomTemplate('');
      setRestaurantTemplate('');
      setLogoBase64(null); // Clear logo base64
    }
    setShowModal(true);
  };

  const handleCloseModal = () => setShowModal(false);

  const handleSave = async (event: React.FormEvent) => {
    event.preventDefault();

    const hotelData = {
      name,
      address,
      mobile,
      additionalMobiles,
      email,
      restaurant,
      gstIn,
      roomTemplate,
      restaurantTemplate,
      logo: logoBase64, // Send base64-encoded logo
    };

    try {
      if (editMode && currentHotel) {
        await updateHotel(currentHotel._id, hotelData);
      } else {
        await createHotel(hotelData);
      }
      setShowModal(false);
      const response = await getAllHotels();
      setHotels(response);
    } catch (error) {
      console.error('Error saving hotel', error);
    }
  };

  const handleDelete = async () => {
    if (hotelToDelete) {
      try {
        await deleteHotel(hotelToDelete);
        const response = await getAllHotels();
        setHotels(response);
      } catch (error) {
        console.error('Error deleting hotel', error);
      } finally {
        setIsDeleteDialogOpen(false);
        setHotelToDelete(null);
      }
    }
  };

  const openDeleteDialog = (id: string) => {
    setHotelToDelete(id);
    setIsDeleteDialogOpen(true);
  };

  const closeDeleteDialog = () => {
    setIsDeleteDialogOpen(false);
    setHotelToDelete(null);
  };

  const handleLogoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setLogoBase64(reader.result as string); // Store base64 string in state
      };
      reader.readAsDataURL(file); // Convert file to base64 string
    }
  };

  const handleAddMobile = () => {
    setAdditionalMobiles([...additionalMobiles, '']);
  };

  const handleRemoveMobile = (index: number) => {
    setAdditionalMobiles(additionalMobiles.filter((_, i) => i !== index));
  };

  const handleMobileChange = (index: number, value: string) => {
    const updatedMobiles = [...additionalMobiles];
    updatedMobiles[index] = value;
    setAdditionalMobiles(updatedMobiles);
  };

  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        Manage Hotels
      </Typography>
      <Button variant="contained" color="primary" startIcon={<Add />} onClick={() => handleOpenModal()}>
        Add Hotel
      </Button>

      <TableContainer component={Paper} sx={{ mt: 3 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Hotel Name</TableCell>
              <TableCell>isProduction</TableCell>
              <TableCell>Address</TableCell>
              <TableCell>Mobile</TableCell>
              <TableCell>Additional Mobiles</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Restaurant</TableCell>
              <TableCell>GSTIN</TableCell>
              <TableCell>Room Template</TableCell>
              <TableCell>Restaurant Template</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {hotels.map((hotel) => (
              <TableRow key={hotel._id}>
                <TableCell>{hotel.name}</TableCell>
                <TableCell>{hotel.isProduction}</TableCell>
                <TableCell>{hotel.address}</TableCell>
                <TableCell>{hotel.mobile}</TableCell>
                <TableCell>{hotel.additionalMobiles?.join(', ') || 'N/A'}</TableCell>
                <TableCell>{hotel.email || 'N/A'}</TableCell>
                <TableCell>{hotel.restaurant || 'N/A'}</TableCell>
                <TableCell>{hotel.gstIn}</TableCell>
                <TableCell>{hotel.roomTemplate || 'N/A'}</TableCell>
                <TableCell>{hotel.restaurantTemplate || 'N/A'}</TableCell>
                <TableCell>
                  <IconButton color="primary" onClick={() => handleOpenModal(hotel)}>
                    <Edit />
                  </IconButton>
                  <IconButton color="secondary" onClick={() => openDeleteDialog(hotel._id)}>
                    <Delete />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Modal for Adding/Editing Hotel */}
      <Dialog open={showModal} onClose={handleCloseModal} fullWidth maxWidth="sm">
        <DialogTitle>{editMode ? 'Edit Hotel' : 'Add Hotel'}</DialogTitle>
        <DialogContent>
          <Box component="form" onSubmit={handleSave} noValidate>
            <TextField margin="normal" fullWidth label="Hotel Name" value={name} onChange={(e) => setName(e.target.value)} required />
            <TextField margin="normal" fullWidth label="Address" value={address} onChange={(e) => setAddress(e.target.value)} required />
            <TextField margin="normal" fullWidth label="Mobile" value={mobile} onChange={(e) => setMobile(e.target.value)} required />
            
            {additionalMobiles.map((mobile, index) => (
              <Box display="flex" alignItems="center" key={index}>
                <TextField
                  margin="normal"
                  fullWidth
                  label={`Additional Mobile ${index + 1}`}
                  value={mobile}
                  onChange={(e) => handleMobileChange(index, e.target.value)}
                />
                <IconButton onClick={() => handleRemoveMobile(index)}><Delete /></IconButton>
              </Box>
            ))}
            <Button onClick={handleAddMobile} color="primary">
              Add Additional Mobile
            </Button>
            
            <TextField margin="normal" fullWidth label="Email" value={email} onChange={(e) => setEmail(e.target.value)} required />
            <TextField margin="normal" fullWidth label="Restaurant" value={restaurant} onChange={(e) => setRestaurant(e.target.value)} required />
            <TextField margin="normal" fullWidth label="GSTIN" value={gstIn} onChange={(e) => setGstIn(e.target.value)} required />

            <TextField margin="normal" fullWidth label="Room Template" select value={roomTemplate} onChange={(e) => setRoomTemplate(e.target.value)}>
              {templates.filter(t => t.type === 'room').map((template) => (
                <MenuItem key={template._id} value={template._id}>
                  {template.name}
                </MenuItem>
              ))}
            </TextField>
            <TextField margin="normal" fullWidth label="Restaurant Template" select value={restaurantTemplate} onChange={(e) => setRestaurantTemplate(e.target.value)}>
              {templates.filter(t => t.type === 'restaurant').map((template) => (
                <MenuItem key={template._id} value={template._id}>
                  {template.name}
                </MenuItem>
              ))}
            </TextField>

            <TextField
              margin="normal"
              fullWidth
              type="file"
              label="Logo"
              InputLabelProps={{ shrink: true }}
              inputProps={{ accept: 'image/*' }}
              onChange={handleLogoChange}
            />

            <DialogActions>
              <Button onClick={handleCloseModal} color="secondary">
                Cancel
              </Button>
              <Button type="submit" color="primary">
                {editMode ? 'Save Changes' : 'Add Hotel'}
              </Button>
            </DialogActions>
          </Box>
        </DialogContent>
      </Dialog>

      {/* Confirmation Dialog for Delete */}
      <ConfirmationDialog open={isDeleteDialogOpen} onCancel={closeDeleteDialog} onConfirm={handleDelete} title="Confirm Deletion" message="Are you sure you want to delete this hotel?" />
    </Container>
  );
};

export default HotelManagement;
