import React, { useEffect, useState } from 'react';
import { Container, Typography, Tabs, Tab, Box } from '@mui/material';
import RoomBillingForm from '../components/Rooms/RoomBillingForm';
import RoomBillingList from '../components/Rooms/RoomBillingList';



const RoomBillingPage: React.FC = () => {
  const [value, setValue] = useState<string>('billing-list');
  

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  return (
      <Box>
        <Typography variant="h4" gutterBottom>
          Manage Rooms Billings
        </Typography>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="room and billing tabs"
          sx={{ mb: 2 }}
        >
          <Tab label="Room Billing List" value="billing-list" />
          <Tab label="Create Room Billing" value="billing-form" />
        </Tabs>
        <Box>
          {value === 'billing-list' && <RoomBillingList />}
          {value === 'billing-form' && <RoomBillingForm />}
        </Box>
      </Box>
  );
};

export default RoomBillingPage;
