import React, { useState, useEffect } from 'react';
import {
  Container,
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Box,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
} from '@mui/material';
import { Add, Edit, Delete } from '@mui/icons-material';
import { createEmployee, deleteEmployee, getEmployees, updateEmployee } from '../../services/employeeService';
import { getHotelIdFromLocalStorage } from '../../utils/localStorageUtils';
import { EmployeeDepartment } from '../../enums/EmployeeDepartment';
import { EmployeePosition } from '../../enums/EmployeePosition';

const ManageEmployee: React.FC = () => {
  const [employees, setEmployees] = useState<any[]>([]);
  const [showModal, setShowModal] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [currentEmployee, setCurrentEmployee] = useState<any>(null);
  const [employeeName, setEmployeeName] = useState('');
  const [role, setRole] = useState('');
  const [mobileNumber, setMobileNumber] = useState('');
  const [salary, setSalary] = useState('');
  const [position, setPosition] = useState<EmployeePosition | ''>('');  // Use enum
  const [department, setDepartment] = useState<EmployeeDepartment | ''>('');  // Use enum
  const hotelId = getHotelIdFromLocalStorage();

  useEffect(() => {
    const fetchEmployees = async () => {
      try {
        const response = await getEmployees();
        setEmployees(response);
      } catch (error) {
        console.error('Error fetching employees', error);
      }
    };
    fetchEmployees();
  }, [hotelId]);

  const handleOpenModal = (employee?: any) => {
    if (employee) {
      setEditMode(true);
      setCurrentEmployee(employee);
      setEmployeeName(employee.name);
      setRole(employee.role);
      setMobileNumber(employee.mobileNumber);
      setSalary(employee.salary);
      setPosition(employee.position);
      setDepartment(employee.department);
    } else {
      setEditMode(false);
      setCurrentEmployee(null);
      setEmployeeName('');
      setRole('');
      setMobileNumber('');
      setSalary('');
      setPosition('');
      setDepartment('');
    }
    setShowModal(true);
  };

  const handleCloseModal = () => setShowModal(false);

  const handleSave = async (event: React.FormEvent) => {
    event.preventDefault();

    const employeeData = {
      hotelId,
      name: employeeName,
      role,
      mobileNumber,
      salary,
      position,
      department,
    };

    try {
      if (editMode && currentEmployee) {
        await updateEmployee(currentEmployee._id, employeeData);
      } else {
        await createEmployee(employeeData);
      }
      setShowModal(false);
      const response = await getEmployees();
      setEmployees(response);
    } catch (error) {
      console.error('Error saving employee', error);
    }
  };

  const handleDelete = async (id: string) => {
    if (window.confirm('Are you sure you want to delete this employee?')) {
      try {
        await deleteEmployee(id);
        const response = await getEmployees();
        setEmployees(response);
      } catch (error) {
        console.error('Error deleting employee', error);
      }
    }
  };

  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        Manage Employees
      </Typography>
      <Button
        variant="contained"
        color="primary"
        startIcon={<Add />}
        onClick={() => handleOpenModal()}
      >
        Add Employee
      </Button>

      <TableContainer component={Paper} sx={{ mt: 3 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Role</TableCell>
              <TableCell>Mobile Number</TableCell>
              <TableCell>Salary</TableCell>
              <TableCell>Position</TableCell>
              <TableCell>Department</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {employees.map((employee) => (
              <TableRow key={employee._id}>
                <TableCell>{employee.name}</TableCell>
                <TableCell>{employee.role}</TableCell>
                <TableCell>{employee.mobileNumber}</TableCell>
                <TableCell>{employee.salary}</TableCell>
                <TableCell>{employee.position}</TableCell>
                <TableCell>{employee.department}</TableCell>
                <TableCell>
                  <IconButton
                    color="primary"
                    onClick={() => handleOpenModal(employee)}
                  >
                    <Edit />
                  </IconButton>
                  <IconButton
                    color="secondary"
                    onClick={() => handleDelete(employee._id)}
                  >
                    <Delete />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Modal for Adding/Editing Employee */}
      <Dialog open={showModal} onClose={handleCloseModal} fullWidth maxWidth="sm">
        <DialogTitle>{editMode ? 'Edit Employee' : 'Add Employee'}</DialogTitle>
        <DialogContent>
          <Box component="form" onSubmit={handleSave} noValidate>
            <TextField
              margin="normal"
              fullWidth
              label="Employee Name"
              value={employeeName}
              onChange={(e) => setEmployeeName(e.target.value)}
              required
            />
            <TextField
              margin="normal"
              fullWidth
              label="Role"
              value={role}
              onChange={(e) => setRole(e.target.value)}
              required
            />
            <TextField
              margin="normal"
              fullWidth
              label="Mobile Number"
              value={mobileNumber}
              onChange={(e) => setMobileNumber(e.target.value)}
              required
            />
            {/* <TextField
              margin="normal"
              fullWidth
              label="Salary"
              value={salary}
              onChange={(e) => setSalary(e.target.value)}
              required
            /> */}
            <FormControl fullWidth margin="normal">
              <InputLabel>Position</InputLabel>
              <Select
                value={position}
                onChange={(e) => setPosition(e.target.value as EmployeePosition)}
                required
              >
                {Object.values(EmployeePosition).map((pos) => (
                  <MenuItem key={pos} value={pos}>
                    {pos}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl fullWidth margin="normal">
              <InputLabel>Department</InputLabel>
              <Select
                value={department}
                onChange={(e) => setDepartment(e.target.value as EmployeeDepartment)}
                required
              >
                {Object.values(EmployeeDepartment).map((dept) => (
                  <MenuItem key={dept} value={dept}>
                    {dept}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <DialogActions>
              <Button onClick={handleCloseModal} color="secondary">
                Cancel
              </Button>
              <Button type="submit" color="primary">
                {editMode ? 'Save Changes' : 'Add Employee'}
              </Button>
            </DialogActions>
          </Box>
        </DialogContent>
      </Dialog>
    </Container>
  );
};

export default ManageEmployee;
